import React from "react";

import elevate1 from "./Media/elevate.png";
import elevate2 from "./Media/elevate2.png";
import elevate3 from "./Media/elevate3.png";

import gold from "./Media/gold.png";
import Platinum from "./Media/platinum.png";
import diamond from "./Media/diamond.png";

const content = [
  {
    id: 1,
    image: elevate1,
    status: "Gold",
    benefit1: "Extra 5% off all personal purchases for next 2 months",
    benefit2:
      "Extra 5% off for their friends' (referral) purchases for next 2 months",
    benefit3: "Extra 5% commission on all referrals for next 2 months",
    eligibility: "5-15 orders or $500 in sales",
    status_image: gold,
  },
  {
    id: 2,
    image: elevate2,
    status: "Platinum",
    benefit1: "Extra 15% off all personal purchases for next 2 months",
    benefit2:
      "Extra 15% off for their friends' (referral) purchases for next 2 months",
    benefit3: "10% commission on all referrals for next 2 months",
    eligibility: "Greater 25 or $1,500 in sales",
    status_image: Platinum,
  },
  {
    id: 3,
    image: elevate3,
    status: "Diamond",
    benefit1: "Extra 10% off all personal purchases for next 2 months",
    benefit2:
      "Extra 10% off for their friends' (referral) purchases for next 2 months",
    benefit3: "Extra 5% commission on all referrals for next 2 months",
    eligibility: "Between 15-25 order or $1,000 in sales",
    status_image: diamond,
  },
];

function AElevate() {
  return (
    <div className="ambassador-part">
      <p className="card-text">Deal of the month</p>
      <h1 className="prepare-main-title mb-[40px]">
        Elevate your Brand Ambassador Status
      </h1>
      <div className="share-images">
        {content.map((item, index) => (
          <div
            key={item.id}
            className={`a-share-image`}
            style={{
              marginTop: item.margin,
              height: item.height,
            }}
          >
            <img
              src={item.image}
              alt=""
              className="w-[80%] h-[40%] m-auto rounded-[12px]"
            />
            <div
              className="elevate-content-part font-['Open_Sans']"
              style={{
                boxShadow:
                  index === 1 ? "0px 0px 4px #ed3592" : "0px 0px 4px grey",
              }}
            >
              <h1 className=" text-[#ed3592] text-[22px]">
                {item.status} Status
              </h1>
              <p className="text-[14px] font-bold">{item.eligibility}</p>
              <p className="text-[13px]">{item.benefit1}</p>
              <p className="text-[13px]">{item.benefit2}</p>
              <p className="text-[13px]">{item.benefit3}</p>
            </div>
            <img
              src={item.status_image}
              alt=""
              className="h-[20%] mt-[-100px] mx-auto"
            />
          </div>
        ))}
      </div>
    </div>
  );
}

export default AElevate;
