import React, { useState } from "react";
import "./Popup.css";
import img1 from "./media/popup2.webp";
import img2 from "./media/Offers.png";
import img3 from "./media/Menu.png";
import img4 from "./media/Events.png";
import log from "./media/bgles.png";
import { Link } from "react-router-dom";
import SpinningWheel from "../SpinningWheel/SpinningWheel";
import wheel from "./../SpinningWheel/Wheel2.gif";
import { Wave } from "react-animated-text";
import TextTransition, { presets } from "react-text-transition";

function Popup() {
  const [showSpinner, setShowSpinner] = useState(false);

  const [index1, setIndex] = React.useState(0);

  React.useEffect(() => {
    const intervalId = setInterval(
      () => setIndex((index1) => index1 + 1),
      2000 // every 3 seconds
    );
    return () => clearInterval(intervalId);
  }, []);

  const handleSpinClick = () => {
    setShowSpinner(true);
  };

  return (
    <div className="pop">
      <SpinningWheel onClose={() => setShowSpinner(false)} />

      {/* {showSpinner ? (
        <SpinningWheel onClose={() => setShowSpinner(false)} />
      ) : (
        <>
          <div className="clearfix" onClose={() => setShowSpinner(false)}>
            <img src={img1} width="100%" className="imgr" />

            <h2 className="hhh">WELCOME TO ROLLOVER</h2>
            <p className="ppp" style={{ fontSize: "20px" }}>
              Explore&nbsp;
              <span>
                <TextTransition
                  springConfig={presets.wobbly}
                  className="font-['Open_sans'] !h-full !inline-block text-[#eb3592] "
                >
                  {TEXTS[index1 % TEXTS.length]}
                </TextTransition>{" "}
              </span>
            </p>
          </div>
          <div className="row w-full max-[768px]:!mb-[20px] m-0 ">
            <div className="column">
              <Link to="/Comp" className="pop-linkk">
                <img src={img3} alt="Snow" className="popimg" />
                <h5 className="poptxt">Menu</h5>
              </Link>
            </div>
            <div className="column">
              <Link to="/offers" className="pop-linkk">
                <img src={img2} alt="Forest" className="popimg" />
                <h5 className="poptxt">Offers</h5>
              </Link>
            </div>
            <div className="column">
              <Link to="/Events" className="pop-linkk">
                <img src={img4} alt="Mountains" className="popimg" />
                <h5 className="poptxt">Events</h5>
              </Link>
            </div>
          </div>
          <div className="spinner-wheel-btn">
            <button onClick={handleSpinClick} className="spinner-btn">
              <img src={wheel} alt="" className="spinner-wheel" />
            </button>
            <h1 className="spin-btn-text">
              <Wave
                text="Spin & Win"
                effect="jump"
                effectChange={0.1}
                effectDuration={1}
              />
            </h1>
          </div>
        </>
      )} */}
    </div>
  );
}

export default Popup;
