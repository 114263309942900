import { useState } from "react";
import "./Navbar.css";
import img1 from "./logonew1.png";
import { Spiral as Hamburger } from "hamburger-react";
import { Link } from "react-router-dom";
import TopNav from "./TopNav";
import { Wave } from "react-animated-text";
import person from "./person.gif";

export default function Navbar() {
  const [isNavExpanded, setIsNavExpanded] = useState(false);

  return (
    <div>
      <nav className="navigation">
        <div className="navigation-smallpart">
          <a href="/" className="brand-name">
            <img
              src={img1}
              className="w-[250px] max-[767px]:!ml-[-15px] ml-[10px] min-[1910px]:ml-[50px]"
            />
          </a>

          <button
            className="hamburger"
            onClick={() => {
              setIsNavExpanded(!isNavExpanded);
            }}
          >
            {" "}
            <Hamburger />
          </button>
        </div>
        <div
          className={
            isNavExpanded ? "navigation-menu expanded" : "navigation-menu"
          }
        >
          <ul className="samppp" style={{ fontFamily: "cursive" }}>
            <li>
              <Link to="/Comp" className="samppp">
                Menu
              </Link>
            </li>
            <li>
              <Link to="/Events" className="samppp">
                Events
              </Link>
            </li>
            <li>
              <Link to="/offers" className="samppp">
                Offers
              </Link>
            </li>
            <li>
              <Link to="/image" className="samppp">
                Our Story
              </Link>
            </li>
            <li>
              <Link to="/Blogcont" className="samppp">
                Blogs
              </Link>
            </li>
            {/* <li>
              <Link to="/Nutrition" className="samppp">
                Nutrition
              </Link>
            </li> */}

            <li>
              <Link to="/NewArrivals" className="samppp new-blink">
                <Wave
                  text="ARRIVALS"
                  effect="pop"
                  effectChange={1.3}
                  effectDuration={1}
                />
              </Link>
            </li>
            {/* <li className="!m-0">
              <Link to="/Ambassador" className="samppp">
                <img src={person} alt="" className="w-[40px] m-auto" />
              </Link>
            </li> */}
            <li>
              <Link to="/ContactUs" className="samppppppp click-but">
                <span>Book Event</span>
              </Link>
            </li>
          </ul>
        </div>
        {/*}
      <button onClick={<Notification />}>
        <BellIcon
          width="30"
          height="21"
          active={true}
          animate={true}
          color="white"
        />
      </button>
      */}
      </nav>
    </div>
  );
}
