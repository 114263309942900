import React from "react";
import { Link } from "react-router-dom";
import "./Ingredients.css";

function IngredientsComponent() {
  return (
    <div className="ingredients-component">
      <h1 className="ingredients-head1">28</h1>
      <h1 className="ingredients-head2">Ingredients</h1>
      <p className="store-content2">
        That's all we need <span className="for-real">for real</span>
      </p>
      <Link to="/Ingredients" className="ingredients-component-btn">
        View All
      </Link>
    </div>
  );
}

export default IngredientsComponent;
