import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./prepare.css";
import TextTransition, { presets } from "react-text-transition";
import { Wave } from "react-animated-text";

const TEXTS = [
  "discount",
  "item",
  "ambience",
  "service",
  "hygiene",
  "experience",
  "quality",
];

const Prepare = () => {
  const [index1, setIndex] = React.useState(0);

  React.useEffect(() => {
    const intervalId = setInterval(
      () => setIndex((index1) => index1 + 1),
      2000 // every 3 seconds
    );
    return () => clearInterval(intervalId);
  }, []);
  return (
    <div className="subscribe-part">
      <section className="container">
        <div className="prepare">
          <h3 className="prepare-title">
            <span className="prepare-main-title">EAT MORE SPEND LESS</span>
            <br />

            <span className="text-[36px]">
              With Our{" "}
              <span className="underline underline-[#eb3592]">
                Subscription Plan
              </span>
            </span>
          </h3>

          <p className="prepare-text">
            There's a best{" "}
            <TextTransition
              springConfig={presets.wobbly}
              className="font-['Open_sans'] !h-full !inline-block text-[#eb3592] "
            >
              {TEXTS[index1 % TEXTS.length]}
            </TextTransition>{" "}
            for you everyday @Rollover
          </p>
          <Link to="/Subscription" className="click-but subscribe-btn">
            <span>Subscribe Now</span>
            {/* <div className="liquid"></div> */}
          </Link>
        </div>
      </section>
    </div>
  );
};

export default Prepare;
