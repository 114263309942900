/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import { ProductsHeading } from "./productsElements.js";
import "./Menu.css";
import { Link } from "react-router-dom";

const Products = ({ heading, data }) => {
  return (
    <div>
      <div className="productss">
        <ProductsHeading>{heading}</ProductsHeading>
        <div className="">
          <div className="row">
            {data.map((product, index) => {
              return (
                <Link to={`/Comp/${product.id}`} className="col-md-4 col-lg-4 col-xl-4 itemboxwrap">
                  <div >
                    <button className="jjj">
                      {" "}
                      <div
                        className="w-clearfix circle-play _2"
                        data-ix="play-buttons"
                      >
                        <div class="box-wrapper" key={product.id}>
                          <img src={product.img} alt="rhcp" />
                          <div class="box-content">
                            <a class="buy">
                              <span>
                                <i class="fa fa-cart-plus">
                                  <img
                                    src={product.img1}
                                    alt=""
                                    className="vegnon"
                                  />{" "}
                                </i>
                              </span>
                            </a>
                            <div className="!text-left">
                              <div class="titlerr">{product.name}</div>

                              <div class="desc">{product.desc}</div>
                            </div>

                            <span class="price">{product.price}</span>
                          </div>
                        </div>
                      </div>
                    </button>
                  </div>
                </Link>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Products;
