import React from "react";
import { useState, useEffect } from "react";
import "./Image.css";
import Sidebar from "../Sidebar/Sidebar";
import vid3 from "./images/Vid3.mp4";
import vid4 from "./images/Vid4.mp4";
import mainbg from "./images/main-bg.mp4";
import one from "./images/One1.gif";
import two from "./images/two.gif";
import three from "./images/thee.gif";
import four from "./images/four.gif";
import five from "./images/five.gif";
import Fade from "react-reveal/Fade";
import { Tilt } from "react-tilt";

const offers = [
  {
    id: 1,
    image: one,
  },
  {
    id: 2,
    image: two,
  },
  {
    id: 3,
    image: three,
  },
  {
    id: 4,
    image: four,
  },
  {
    id: 5,
    image: five,
  },
];

function Parallax() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => setLoading(false), 2500);
  }, []);

  const defaultOptions = {
    reverse: false, // reverse the tilt direction
    max: 5, // max tilt rotation (degrees)
    perspective: 1000, // Transform perspective, the lower the more extreme the tilt gets.
    scale: 1, // 2 = 200%, 1.5 = 150%, etc..
    speed: 1000, // Speed of the enter/exit transition
    transition: true, // Set a transition on enter/exit.
    axis: null, // What axis should be disabled. Can be X or Y.
    reset: true, // If the tilt effect has to be reset on exit.
    easing: "cubic-bezier(.03,.98,.52,.99)", // Easing on enter/exit.
  };
  return (
    <>
      <div className="offer-part">
        <Sidebar />
        <div className="new-offers">
          {offers.map((item) => (
            <div key={item.id} className="offer-div">
              <Fade bottom>
                <Tilt options={defaultOptions}>
                  <img src={item.image} alt="" />
                </Tilt>
              </Fade>
            </div>
          ))}
        </div>
        {/* <div className="wglk">
          <div class="roww">
            <div class="columnn">
              <Fade left>
                <Tilt options={defaultOptions}>
                  <video
                    loop
                    muted
                    autoPlay
                    style={{ width: "100%", height: "70%" }}
                  >
                    <source src={vid3} type="video/mp4" />
                  </video>
                </Tilt>
              </Fade>
            </div>

            <div class="columnn">
              <Fade right>
                <Tilt options={defaultOptions}>
                  <video
                    loop
                    muted
                    autoPlay
                    playsInline
                    style={{ width: "100%", height: "70%" }}
                  >
                    <source src={vid4} type="video/mp4" />
                  </video>
                </Tilt>
              </Fade>
            </div>
          </div>
        </div> */}
      </div>
    </>
  );
}

export default Parallax;
