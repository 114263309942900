import React from "react";
import Outdoor from "../components/Events/Outdoor";
import Navbar from "../components/Navbar";

function OutdoorEvents() {
  return (
    <div>
      <Navbar />
      <Outdoor />
    </div>
  );
}

export default OutdoorEvents;
