import React from "react";
import "./AboutUs.css";
import img2 from "./media/Death by Chocolate 2.jpg";
import img3 from "./media/img3.JPG";

export const AboutUs = () => {
  return (
    <div className="about-us">
      <section className="a-section-one">
        <h1>Our Story</h1>
        <p>
          We are passionate about bringing the delicious and affordable flavors
          of Thailand to the world of ice cream and fast food. Our menu features
          a variety of mouth-watering ice cream rolls and creative fast food
          dishes that are sure to satisfy your cravings.
          <br />
        </p>
      </section>
      <section className="a-section-two">
        <div className="col-md-5">
          <img src={img2} alt="man-making-coffee" />
        </div>
        <div className=" col-md-6 md:ml-4">
          <h1>Hangout Spot..!!!</h1>
          <p>
            Looking for a fun and affordable hangout spot? Look no further than
            Roll Over Warangal..! Our cozy and inviting space is the perfect
            place to relax, unwind, and enjoy some delicious food and drinks.
            <br />
            <br /> We offer a wide selection of board games and other
            entertainment options, so you can challenge your friends and family
            to a friendly competition or just enjoy some quality time together.
            And when you need a break from the games, indulge in our signature
            ice cream rolls or try one of our creative fast food dishes.
            <br />
            <br />
            In addition to our delicious ice cream rolls, we also offer a range
            of affordable and tasty fast food options, including Momos,
            Sandwiches, Burgers, Fries, Shakes, and more. Come visit us and
            discover why Roll Over Warangal is the perfect hangout spot!
          </p>
        </div>
      </section>
      <section className="a-section-three">
        <div className="quality">
          <h1>Food Memory</h1>
          <p>
            Food and body memory are closely related. Food has the ability to
            trigger memories and emotions, and these memories and emotions can
            affect our bodies in various ways. For example, the smell of a
            certain food may bring back memories of a happy childhood
            experience, which can cause the body to relax and release feel-good
            hormones like serotonin.
          </p>
        </div>
        <div className="img">
          <img
            src={img3}
            alt="holding-coffee"
            className="holding-coffee-desktop"
          />
          <img
            src={img3}
            alt="holding-coffee"
            className="holding-coffee-tablet"
          />
        </div>
      </section>
      <br />
      <br />
    </div>
  );
};
