import CapsicumRed from "./Media/Capsicum_Red-removebg-preview.png";
import CapsicumYellow from "./Media/Capsicum_Yellow-removebg-preview.png";
import GreenCapsicum from "./Media/Green_Capsicum-removebg-preview.png";
import SweetCorn from "./Media/Sweet_Corn-removebg-preview.png";
import Tomato from "./Media/Tomato-removebg-preview.png";
import Onion from "./Media/Onion-removebg-preview.png";
import ChilliFlakes from "./Media/Chilli_Flakes-removebg-preview.png";
import OreganoSprinkler from "./Media/Oregano_Sprinkler-removebg-preview.png";
import Chicken from "./Media/Chicken-removebg-preview.png";
import Salt from "./Media/Salt-removebg-preview.png";
import Lemon from "./Media/Lemon-removebg-preview.png";
import MintLeaves from "./Media/Mint_Leaves-removebg-preview.png";
import Panner from "./Media/Paneer-removebg-preview.png";
import Milk from "./Media/Milk-removebg-preview.png";
import MangoCrush from "./Media/Mango_Crush-removebg-preview.png";
import BlackRaisin from "./Media/Black_Raisin.png";
import CashewNuts from "./Media/Cashew_Nuts-removebg-preview.png";
import AlmondNuts from "./Media/Almond Nuts.png";
import Oil from "./Media/Oil-removebg-preview.png";
import VanillaPremix from "./Media/Vanilla_Premix-removebg-preview.png";
import TrufflePaste from "./Media/Truffle_Paste-removebg-preview.png";
import ChocolatePremix from "./Media/Chocolate_Premix-removebg-preview.png";
import Kismis from "./Media/Kismis-removebg-preview.png";
import CoffeePowder from "./Media/Coffee_Powder-removebg-preview.png";
import PeanutButter from "./Media/Peanut_Butter-removebg-preview.png";
import CherriesFruit from "./Media/Cherries_Fruit-removebg-preview.png";
import ChilliPowder from "./Media/Chilli_Powder-removebg-preview.png";
import Honey from "./Media/Honey-removebg-preview.png";

export const IngredientItems = [
  {
    id: 0,
    image: CapsicumRed,
    namer: "Capsicum Red",
    desc1:
      "At our restaurant, we love using capsicum red, also known as red bell pepper, in many of our dishes. This vibrant and versatile vegetable adds a pop of colour and flavour to any dish, from pizza to salads and everything in between. Not only is it delicious, but it's also packed with nutrients. Capsicum red is high in vitamins A and C, as well as antioxidants that help protect the body against disease.",
    desc2:
      "One of our favourite ways to use Capsicum Red is on our pizzas. We love how the sweetness and crunch of the pepper balance out the richness of the cheese and savoury toppings. We also use it in our salads, where it adds both colour and flavour to the mix. We roast it to bring out its natural sweetness and then toss it with fresh greens, feta cheese, and a balsamic vinaigrette. Whether you're a fan of pizza or salads, you're sure to love the taste and health benefits of capsicum red in our dishes.",
  },
  {
    id: 1,
    image: CapsicumYellow,
    namer: "Capsicum Yellow",
    desc1:
      "Yellow capsicum, also known as yellow bell pepper, is a colourful and flavorful addition to any dish. At our restaurant, we hand slice all of our bell peppers each morning, including the vibrant yellow variety. With its sweet and slightly tangy flavour, yellow capsicum is a great addition to a variety of dishes, from stir-fries and salads to omelettes and fajitas.",
    desc2:
      "Not only does yellow capsicum add a pop of colour to your plate, but it's also packed with nutrients. Like all bell peppers, yellow capsicum is a great source of vitamins C and A, as well as fibre and potassium. It's also low in calories, making it a great option for those watching their weight. So next time you're looking to add some colour and nutrition to your meal, give yellow capsicum a try!",
  },
  {
    id: 2,
    image: GreenCapsicum,
    namer: "Green Capsicum",
    desc1:
      "Green capsicum, also known as green bell pepper, is a popular topping for pizzas due to its crisp texture and mild flavour. Not only does it add a pop of bright colour to your pizza, but it also provides a range of health benefits. Green capsicum is packed with vitamins and minerals, including vitamin C, vitamin K, vitamin E, potassium, and folate. It's also low in calories and high in fibre, making it a nutritious addition to any pizza.",
    desc2:
      "When it comes to using green capsicum on pizza, there are many delicious options. You can slice it thinly and use it as a topping on its own or pair it with other vegetables like onions, mushrooms, and tomatoes. It also pairs well with meats like pepperoni or sausage, adding a refreshing contrast to the savoury flavours. For a unique twist, try roasting the green capsicum before adding it to your pizza, which will bring out its natural sweetness and add a smoky flavour. No matter how you choose to use it, green capsicum is a versatile and healthy ingredient that will elevate any pizza.",
  },
  {
    id: 3,
    image: SweetCorn,
    namer: "Sweet Corn",
    desc1:
      "Sweet corn is an excellent ingredient to add a touch of sweetness and crunch to your pizza. We use carefully selected, fresh sweet corn that is roasted to bring out its natural sweetness. Our chefs love to use sweet corn as a versatile topping that pairs well with classic pizza ingredients like tomato sauce, mozzarella, and basil. It adds a unique texture and sweetness to every bite, making it a favourite among both kids and adults.",
    desc2:
      "Our sweet corn topping is perfect for those looking for a vegetarian pizza option or who simply want to add a touch of sweetness to their pizza. Experience the perfect blend of flavours and textures that will take your pizza game to the next level. Try it today and savour the delicious taste of our sweet corn pizza topping.",
  },
  {
    id: 4,
    image: Tomato,
    namer: "Tomato",
    desc1:
      "Tomatoes are a staple ingredient in many popular dishes, including pizza, sandwiches, and burgers. Whether sliced or diced, raw or cooked, tomatoes add a burst of juicy freshness to any meal. We use freshly sliced tomatoes as a key ingredient in our classic Margherita pizza, where the sweetness of the tomato perfectly complements the creaminess of the mozzarella cheese. In our sandwiches, we use freshly sliced tomatoes to add a refreshing crispness and a pop of bright colour. And in our burgers, we layer juicy, ripe tomato slices between the savoury beef patty and the soft bun, creating a mouthwatering explosion of flavours and textures.",
    desc2:
      "Fruit or vegetable? It depends on whom you ask. A botanist would say, Clearly it is the fruit of the plant Solanum lycopersicum - that's the tomato plant, you plebes—and therefore, in case you missed it the first time, it is a fruit. My dear fool, the chef would counter, the tomato is a culinary vegetable—a vital ingredient of savoury meals. So there you have it. Tomato tomahto.",
  },
  {
    id: 5,
    image: Onion,
    namer: "Onion",
    desc1:
      "Onions are a staple ingredient in many of our dishes, adding depth and flavour to our pizzas, burgers, and sandwiches. We hand slice and dice about 50 pounds of red onions every day for our fajita veggies, roasted chilli-corn salsa, tomatillo-green chilli salsa, guacamole, and fresh tomato salsa. That's a whole lot of onions! We also use yellow onions in the beginning stages of cooking our pinto beans, black beans, and sofritas. They're peeled, diced, and mixed with spices and aromatics, infusing the beans with a delicious savoury flavour.",
    desc2:
      "In our pizzas, we use caramelised onions as a topping, which brings out their natural sweetness and adds a hint of smokiness. In our burgers, we layer crispy fried onions to add crunch and texture to every bite. And in our sandwiches, we use pickled onions as a tangy and flavorful garnish. With so many ways to use onions, we're constantly exploring new and creative ways to incorporate them into our menu.",
  },
  {
    id: 6,
    image: ChilliFlakes,
    namer: "Chilli Flakes",
    desc1:
      "At our restaurant, we believe that adding a bit of heat to your pizza can really take it to the next level. That's why we use chilli flakes as a topping for those who like a little kick in their slice. Our chilli flakes are made from dried and crushed hot red peppers, carefully selected to ensure the perfect balance of heat and flavour.      ",
    desc2:
      "When you order a pizza with chilli flakes, you'll experience a burst of spicy heat with every bite. The flakes add a smoky depth of flavour and a subtle sweetness, complementing the other toppings on your pizza. And if you're feeling particularly daring, you can always add more chilli flakes to up the heat level. At our restaurant, we're proud to offer a wide range of toppings to suit every taste preference, and chilli flakes are just one way to customise your pizza to your liking.",
  },
  {
    id: 7,
    image: OreganoSprinkler,
    namer: "Oregano",
    desc1:
      "Oregano is the perfect herb to add some Mediterranean flavour to your pizza. We use a special oregano sprinkler to evenly distribute the herb onto each slice of pizza, ensuring that every bite is bursting with its savoury, earthy flavour. Oregano pairs perfectly with our tangy tomato sauce and gooey cheese, making it an essential ingredient for any pizza lover.      ",
    desc2:
      "But oregano isn't just for pizza. We also use it in our sandwiches and burgers to add an extra layer of deliciousness. It's especially delicious on our Italian-style subs, where it perfectly complements the cured meats and tangy vinaigrette. So whether you're enjoying a slice of pizza, biting into a sandwich, or taking a big, juicy bite of a burger, our Oregano Sprinkler is sure to take your meal to the next level.",
  },
  {
    id: 8,
    image: Chicken,
    namer: "Chicken",
    desc1:
      "We take pride in serving responsibly raised chicken at our restaurant, and we use a careful cooking process to ensure that it's both delicious and tender. First, we marinate the chicken in a blend of herbs, spices, and citrus juices for at least 24 hours to add flavour and help tenderise the meat. Then, we cook it sous vide, which involves heating it at a precise, low temperature to lock in moisture and ensure even cooking. Finally, we finish it on the grill to add a smoky flavour and crispy texture.      ",
    desc2:
      "Whether you're enjoying our chicken strips, our chicken cheese ball, our chicken drumsticks, or our chicken nuggets, you can be sure that it's been prepared with care and attention to detail. Our commitment to responsibly raised meat and our dedication to using the best possible ingredients ensure that every dish we serve is both delicious and healthy. So come and savour the taste of our flavorful and tender chicken dishes!",
  },
  {
    id: 9,
    image: Salt,
    namer: "Salt",
    desc1:
      "Just like the food you make at home, salt is in most of our menu items. We use kosher salt to season the food we prepare in our restaurants from scratch, like our french fries and fresh tomato salsa, and to season the food cooked in our central kitchens, including our chicken stips, toondoori, and black beans. As in many professional kitchens, we use kosher salt because its larger, coarser flakes allow for even seasoning. Then eat it (because it's delicious).      ",
    desc2:
      "Upon the observation that food was more easily swallowed when eaten with salt, the expression take something with a grain of salt was born. Except that instead of food, you swallow information. And instead of actual salt, you take the information with a healthy dose of scepticism. At Chipotle, we use far simpler expressions. So when we say to take something with a grain of salt, sprinkled atop a tortilla chip, and dipped in guacamole, that's exactly what we mean.",
  },
  {
    id: 10,
    image: Lemon,
    namer: "Lemon",
    desc1:
      "Lemons are a crucial ingredient in making a refreshing and tangy mojito. We use freshly squeezed lemon juice to give our mojitos a bright citrus flavour that complements the mint and rum perfectly. The acidity of the lemon juice balances out the sweetness of the simple syrup, creating a well-rounded and delicious cocktail.      ",
    desc2:
      "But it's not just the juice that makes lemon an important part of our mojito recipe. We also use the lemon peel to add a burst of flavour and aroma. The essential oils found in the lemon peel add a zesty and bright note that elevates the drink to the next level. By mingling the lemon peel with the mint leaves, we extract even more flavour and create a truly delicious and refreshing mocktail.",
  },
  {
    id: 11,
    image: MintLeaves,
    namer: "Mint Leaves",
    desc1:
      "Mint is the unsung hero of the mocktail world. Its cool, refreshing taste is perfect for concocting delicious drinks that'll satisfy your thirst on a hot summer day. From classic mojitos to modern twists on the mint julep, the versatility of mint can't be beat. We love to use fresh mint in our mocktails. Our Virgin Margaritas get a zesty twist with a sprig of fresh mint, and our Cucumber Limeade is taken to the next level with a generous handful of mint leaves. Whether you're mixing up a batch of mocktails for a party or just looking for a refreshing drink to enjoy on your own, don't overlook the power of mint.      ",
    desc2:
      "Mint is a fragrant herb that belongs to the genus Mentha, and there are over 25 different species of mint. It is a popular ingredient in many cuisines around the world and is known for its fresh, cooling flavour and aroma. Mint is also used for medicinal purposes, as it contains several essential oils that have anti-inflammatory, antibacterial, and antiviral properties. It is commonly used to alleviate digestive issues, such as bloating and nausea, and is also known to have a calming effect on the mind and body. Mint is easy to grow and can thrive in a variety of environments, making it a popular herb for home gardens. Whether it's in a refreshing drink, a savoury dish, or a fragrant oil, mint is a versatile and beloved herb that has been enjoyed for centuries.",
  },
  {
    id: 12,
    image: Panner,
    namer: "Panner",
    desc1:
      "Paneer, also known as Indian cottage cheese, is a versatile ingredient that adds a rich and creamy flavor to a variety of dishes. In our restaurant, we use paneer in both momos and pizza to give our customers a unique and delicious experience. In our momos, we stuff freshly made paneer with vegetables and spices, creating a perfect balance of flavors. In our pizza, we use paneer as a topping along with other ingredients like peppers and onions, giving it a distinct Indian twist. Whether you are a fan of traditional Indian flavors or just looking to try something new, our paneer momos and pizza are a must-try.      ",
    desc2:
      "Paneer is a popular dairy product in India and other parts of South Asia. It is made by curdling milk with an acidic agent, such as lemon juice or vinegar, and then pressing the curds into a solid block. Paneer is a good source of protein and calcium, making it a popular ingredient in vegetarian dishes. It is also low in sodium and high in phosphorus, which is beneficial for bone health. Paneer can be used in a variety of dishes, from curries and stews to snacks like pakoras and samosas. It is also a versatile ingredient in fusion cuisine, such as in paneer tikka pizzas and paneer momos.",
  },
  {
    id: 13,
    image: Milk,
    namer: "Milk",
    desc1:
      "Milk is a versatile ingredient used in many popular items on the menu, including shakes, premixes, and waffle cones. It is a rich source of nutrients such as calcium, protein, and vitamins, making it a valuable addition to any diet. The creamy, smooth texture of milk adds to the indulgent and satisfying experience of enjoying a delicious shake or waffle cone.      ",
    desc2:
      "Before fridges were a thing, making cheese was a way to preserve the extra milk on the farm - a duty that normally fell on farmers' wives. Their skills would then be passed down to their daughters. How's that for a cheesy family tradition?",
  },
  {
    id: 14,
    image: MangoCrush,
    namer: "Mango Crush",
    desc1:
      "At our restaurant, we use fresh mango crush to add a sweet and tangy flavour to our rolls, complementing the savoury filling. The crush is made by blending fresh, ripe mangoes into a smooth puree, which can then be added to the filling or served on the side as a dipping sauce. Mango crush also adds a vibrant pop of colour to our rolls, making them visually appealing and appetising. Whether you're a fan of sweet or savoury, our rolls with mango crush are sure to tantalise your taste buds and leave you wanting more.      ",
    desc2:
      "Mango Crush is a sweet and refreshing fruit syrup made from ripe mangoes that are crushed and blended with sugar and water. It's commonly used in restaurants to add a tropical flavour to dishes such as rolls and mocktails. Mangoes are rich in vitamins and minerals such as vitamin C and potassium, making mango crush a healthier alternative to other sweeteners. The fruity and tangy taste of mango crush is perfect for enhancing the flavour of various dishes and adding a delicious twist to traditional recipes.",
  },
  {
    id: 15,
    image: BlackRaisin,
    namer: "Black Raisin",
    desc1:
      "Black raisins, also known as dried blackcurrants, are a versatile ingredient that can be used in a variety of dishes to add natural sweetness and a chewy texture. In our restaurant, we love to use black raisins to garnish our savoury rolls, adding a touch of sweetness to the dish. We also love to use them in our premix for baked goods like muffins and cakes, and they make a great addition to our homemade waffle cones, adding a sweet contrast to the crispy texture. Not only do black raisins taste great, but they are also packed with nutrients, including fibre, vitamins, and antioxidants, making them a healthy and delicious addition to any menu. Whether you're looking to add a touch of sweetness to your dishes or enhance the nutritional value of your menu items, black raisins are a must-have ingredient in any restaurant kitchen.      ",
    desc2:
      "Black raisins, also known as dried black grapes, are a delicious and nutritious snack that is packed with essential vitamins, minerals, and antioxidants. These raisins are a natural source of iron, potassium, calcium, and dietary fibre, which makes them an excellent addition to any diet. They are also a great source of energy and can help boost your immune system. Additionally, black raisins are known for their anti-inflammatory and anti-bacterial properties, making them a popular ingredient in traditional medicine.",
  },
  {
    id: 16,
    image: CashewNuts,
    namer: "Cashew Nuts",
    desc1:
      "Cashew nuts are a delicious and versatile ingredient that can add a rich, creamy texture and nutty flavour to any dish. At our restaurant, we love to use cashew nuts in our savoury rolls, which are filled with a delicious mixture of spices, vegetables, and protein and topped with crunchy cashews for an extra burst of flavour and texture. We also love to use cashew nuts in our exotic sundaes, where they provide a perfect complement to the sweet and creamy ice cream and add a satisfying crunch to each bite. Whether you're looking for a sweet or savoury treat, cashew nuts are a must-have ingredient that can take any dish to the next level. So come on in and try some of our delicious rolls or sundaes.      ",
    desc2:
      "Cashew nuts are rich in healthy fats, protein, vitamins, and minerals. They are a good source of heart-healthy monounsaturated fats and may help lower blood pressure and cholesterol levels. Cashews are also a good source of antioxidants and may have anti-inflammatory properties. However, they should be consumed in moderation, as they are high in calories.",
  },
  {
    id: 17,
    image: AlmondNuts,
    namer: "Almond Nuts",
    desc1:
      "At our restaurant, we love to use almonds as a versatile ingredient in a variety of our desserts. We add them to our rolls, sundaes, and cones for added texture and crunch. We also use them as a garnish for many of our dishes, providing a beautiful visual contrast to our sweet treats. Almonds are not only delicious, but they are also a healthy option, rich in protein, fiber, and healthy fats. Whether you're looking for a simple, satisfying treat or an indulgent, exotic dessert, our almonds are the perfect addition to any dish.      ",
    desc2:
      "Almonds are high in vitamin E, healthy fats, and antioxidants. They may reduce the risk of heart disease and lower cholesterol.",
  },
  {
    id: 18,
    image: Oil,
    namer: "Oil",
    desc1:
      "At our restaurant, we use sunflower oil for its delicate flavour whenever a mild oil is called for, namely for our fried moms, munchies, and fajita veggies. Our sunflower oil is non-GMO.      ",
    desc2:
      "Sunflower oil is extracted from the seeds of the sunflower plant and is high in vitamin E and omega-6 fatty acids. It is a popular cooking oil due to its neutral taste, high smoke point, and versatility in cooking methods.And as they grow, their yet-to-bloom heads automatically tilt throughout the day to continually face the sun as it passes overhead. That behavior is called heliotropism, and it's pretty creepy if you think about it.",
  },
  {
    id: 19,
    image: VanillaPremix,
    namer: "Vanilla Premix",
    desc1:
      "At our restaurant, vanilla premix is the perfect addition to our rolls, giving them a rich and delicious flavour that is sure to delight your taste buds. Made with real vanilla extract, our premix is carefully crafted to ensure a consistent and high-quality product every time. Whether you're enjoying a warm roll for breakfast or as a dessert, our vanilla premix will take your experience to the next level. It's incredibly easy to use, making it a must-have ingredient for any home baker or professional chef looking to elevate their dishes.      ",
    desc2:
      "Vanilla is one of the most popular flavors in the world, and its use in baking and desserts dates back centuries. Vanilla extract, made from vanilla beans, is a common ingredient in many recipes, but vanilla premix offers a convenient and cost-effective alternative for busy restaurants and bakeries. Vanilla premixes typically contain a blend of vanilla extract and other natural or artificial flavors, combined with sugar, stabilizers, and other ingredients to create a consistent and versatile product. ",
  },
  {
    id: 20,
    image: TrufflePaste,
    namer: "Truffle Paste",
    desc1:
      "Truffle paste is a decadent ingredient that adds an earthy and intense flavour to various dishes. In our restaurant, we use it in shakes, rolls, and sandwiches to create a luxurious and unique dining experience for our customers. With its rich and distinctive taste, truffle paste elevates any dish it's added to and leaves a lasting impression on the palate.      ",
    desc2:
      "Truffle paste is a versatile ingredient that can add a rich and earthy flavour to a variety of dishes. At our restaurant, we use truffle paste to enhance the taste of our shakes, rolls, and sandwiches. Made from finely ground truffles and oil, truffle paste can add depth to any dish with its distinctive aroma and flavour.",
  },
  {
    id: 21,
    image: ChocolatePremix,
    namer: "Chocolate Premix",
    desc1:
      "At our restaurant, we use a premium chocolate premix for our rolls that is made from high-quality cocoa powder and other carefully selected ingredients. Our chocolate rolls are rich, decadent, and perfect for satisfying any chocolate craving. Whether you're in the mood for a sweet breakfast treat or a rich dessert, our chocolate rolls are sure to please. Plus, our chocolate premix is versatile and can be used in a variety of other desserts and drinks, making it a must-have ingredient in our kitchen.      ",
    desc2:
      "Chocolate premix is a popular ingredient used in making desserts and beverages. It is a convenient option for those who want to enjoy the rich and decadent taste of chocolate without the hassle of preparing it from scratch. The premix contains a combination of cocoa powder, sugar, and other ingredients that create a smooth and creamy texture when mixed with milk or water. ",
  },
  {
    id: 22,
    image: Kismis,
    namer: "Kismis",
    desc1:
      "Kishmish, also known as raisins, are a versatile ingredient in our restaurant. We use them to add a sweet and chewy texture to our rolls, as well as to top our exotic sundaes and garnish our cones. In addition, kishmish is a key component of our premix, which is used to make various desserts and shakes. We love incorporating this ingredient in creative ways to enhance the flavour and texture of our dishes.      ",
    desc2:
      "Kishmish raisins are made from dried grapes and are packed with nutrients like fiber and antioxidants. They can improve digestion and boost heart health.",
  },
  {
    id: 23,
    image: CoffeePowder,
    namer: "Coffee Powder",
    desc1:
      "Coffee powder is a key ingredient in our restaurant's cold coffee shakes. We use high-quality coffee powder that's been expertly roasted and ground to bring out its rich, deep flavour. It's the perfect addition to our creamy milkshakes, adding a bold and delicious kick that's sure to please any coffee lover. Our coffee shakes are made by blending the powder with ice cream and milk, resulting in a velvety smooth and indulgent drink that's perfect for any time of day. Whether you're looking for a quick pick-me-up or a decadent treat, our coffee shakes are the perfect choice.      ",
    desc2:
      "Coffee powder is made by grinding roasted coffee beans. It is rich in caffeine and antioxidants, and can improve focus and mood.",
  },
  {
    id: 24,
    image: PeanutButter,
    namer: "Peanut Butter",
    desc1:
      "At our restaurant, our rolls are stuffed with creamy and nutty peanut butter, providing a perfect balance of sweetness and saltiness. Made with fresh and natural ingredients, our peanut butter adds richness and depth of flavour to every bite, making our rolls a must-try menu item for peanut butter lovers.      ",
    desc2:
      "Peanut butter is a popular spread made from ground peanuts. It was first introduced in the United States in the late 1800s as a protein-rich food for people who couldn't chew meat. Today, it is a staple in many households and is used in a variety of dishes, from sandwiches to baked goods. Peanut butter is also a good source of healthy fats, protein, and fiber, making it a great addition to a balanced diet. Additionally, it contains vitamins and minerals such as vitamin E, magnesium, and potassium. ",
  },
  {
    id: 25,
    image: CherriesFruit,
    namer: "Cherries Fruit",
    desc1:
      "Our restaurant rolls are taken to the next level with the addition of juicy and sweet cherries. Whether baked into the dough or used as a topping, cherries bring a burst of flavor and color to our rolls. We carefully select the freshest cherries to ensure that every bite is filled with their natural sweetness. Come try our cherry rolls and taste the difference that high-quality ingredients make.      ",
    desc2:
      "Cherries are not only delicious but also packed with nutrients, making them a popular fruit around the world. They are a rich source of vitamins, minerals, and antioxidants that are essential for maintaining good health. Cherries are known to reduce inflammation, lower blood pressure, and improve sleep quality due to their melatonin content. Additionally, they are a good source of fiber and low in calories.",
  },
  {
    id: 26,
    image: ChilliPowder,
    namer: "Chilli Powder",
    desc1:
      "Chilli powder is a staple in our kitchen, adding heat and depth of flavour to a variety of dishes, including our famous rolls and crispy French fries. Made from ground, dried chilli peppers, this spicy seasoning adds a kick that elevates the taste of our rolls, which are filled with a variety of ingredients and spices, and adds an extra layer of flavour to our crispy fries. We sprinkle just the right amount of chilli powder to give our dishes the perfect balance of heat and flavour.      ",
    desc2:
      "Chilli powder is a popular ingredient in many dishes, including rolls and French fries. It adds a spicy kick and depth of flavor to these dishes. Made from dried and ground chili peppers, it is versatile and can be used in different cuisines. Chilli powder is also known to have health benefits, such as aiding digestion and boosting metabolism.",
  },
  {
    id: 27,
    image: Honey,
    namer: "Honey",
    desc1:
      "Honey is a versatile ingredient that adds a natural sweetness and unique flavour to many dishes, including exotic sundaes in our restaurant. Its thick texture and rich taste make it perfect for drizzling over ice cream or mixing into sauces and dressings. In addition to being delicious, honey also offers various health benefits, such as antioxidant and antibacterial properties. Its versatility and health benefits make it a popular choice for many cuisines and dishes around the world.      ",
    desc2:
      "Honey is a natural sweetener produced by bees from the nectar of flowers. It has been used for centuries as a food, a natural remedy for various ailments, and even as a cosmetic ingredient. Honey is a rich source of antioxidants, enzymes, and vitamins, and it also has antibacterial and anti-inflammatory properties. Raw, unfiltered honey is the most nutritious and has more health benefits than processed honey. Honey has been a part of human diets for thousands of years and continues to be a popular sweetener and ingredient in various cuisines around the world.",
  },
];
