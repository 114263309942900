import { useState } from "react";
//import './App.css';
import { FaStar } from "react-icons/fa";
import Navbar from "../Navbar";
import "./Feedback.css";
import emailjs from "@emailjs/browser";
import { useRef } from "react";
import Sidebar from "../Sidebar/Sidebar";
import Contact from "../contact/Contact";
import { Link } from "react-router-dom";

const colors = {
  orange: "#FFBA5A",
  grey: "#a9a9a9",
};

function Feedback() {
  const form = useRef();

  const myAlert = () => {
    alert("Thank you..!");
  };

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_zleknzbbn",
        "template_962ouwi",
        form.current,
        "JiNk4fjq66o6LTiKN"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };
  const [currentValue, setCurrentValue] = useState(0);
  const [hoverValue, setHoverValue] = useState(undefined);
  const stars = Array(5).fill(0);

  const handleClick = (value) => {
    setCurrentValue(value);
  };

  const handleMouseOver = (newHoverValue) => {
    setHoverValue(newHoverValue);
  };

  const handleMouseLeave = () => {
    setHoverValue(undefined);
  };

  return (
    <div>
      <Navbar />
      <Sidebar />

      <section
        class="Material-contact-section section-padding section-dark"
        id="fomm"
      >
        <div class="container">
          <div class="row">
            <div class="col-md-12 wow animated fadeInLeft" data-wow-delay=".2s">
              <h1 class="section-title">Love to Hear From You</h1>
            </div>
          </div>
          <div class="row">
            <div
              class="col-md-6 mt-3 contact-widget-section2 wow animated fadeInLeft"
              data-wow-delay=".2s"
            >
              <p>
                "Always take care of your food and it will take care of you"
                Rollover follows all rules and health measures to serve Hygiene
                food to our loved customers.
              </p>

              <div class="find-widget">
                Company: <Link to="#">Roll Over</Link>
              </div>
              <div class="find-widget">
                Address:{" "}
                <Link to="#">
                  Chaitanyapuri Colony, Hanamkonda, Telangana 506004
                </Link>
              </div>
              <div class="find-widget">
                Phone: <Link to="#">8688066805</Link>
              </div>

              <div class="find-widget">
                Website:{" "}
                <Link to="https://rolloverwarangal.com">
                  https://rolloverwarangal.com
                </Link>
              </div>
            </div>

            <div class="col-md-6 wow animated fadeInRight" data-wow-delay=".2s">
              <form
                class="shake"
                role="form"
                method="post"
                id="contactForm"
                name="contact-form"
                data-toggle="validator"
                ref={form}
                onSubmit={sendEmail}
              >
                <div class="form-group label-floating">
                  <label
                    class="control-label"
                    for="name"
                    style={{ fontSize: "13px", color: "black" }}
                  >
                    Name
                  </label>
                  <input
                    class="form-control"
                    id="name"
                    type="text"
                    name="name"
                    required
                    data-error="Please enter your name"
                  />
                  <div class="help-block with-errors"></div>
                </div>

                <div class="form-group label-floating">
                  <label
                    class="control-label"
                    for="email"
                    style={{ fontSize: "13px", color: "black" }}
                  >
                    Email
                  </label>
                  <input
                    class="form-control"
                    id="email"
                    type="email"
                    name="email"
                    required
                    data-error="Please enter your Email"
                  />
                  <div class="help-block with-errors"></div>
                </div>

                <div class="form-group label-floating">
                  <label
                    class="control-label"
                    style={{ fontSize: "13px", color: "black" }}
                  >
                    Subject
                  </label>
                  <input
                    class="form-control"
                    id="msg_subject"
                    type="text"
                    name="subject"
                    required
                    data-error="Please enter your message subject"
                  />
                  <div class="help-block with-errors"></div>
                </div>

                <div class="form-group label-floating">
                  <label
                    for="message"
                    class="control-label"
                    style={{ fontSize: "13px", color: "black" }}
                  >
                    Message
                  </label>
                  <textarea
                    class="form-control"
                    rows="3"
                    id="message"
                    name="message"
                    required
                    data-error="Write your message"
                  ></textarea>
                  <div class="help-block with-errors"></div>
                </div>
                <div class="form-group label-floating">
                  <label
                    class="control-label"
                    for="rating"
                    style={{ fontSize: "13px", color: "black" }}
                  >
                    Rate For Service
                  </label>

                  <div className="stars" name="rate">
                    {stars.map((_, index) => {
                      return (
                        <FaStar
                          key={index}
                          size={24}
                          onClick={() => handleClick(index + 1)}
                          onMouseOver={() => handleMouseOver(index + 1)}
                          onMouseLeave={handleMouseLeave}
                          color={
                            (hoverValue || currentValue) > index
                              ? colors.orange
                              : colors.grey
                          }
                          style={{
                            marginRight: 10,
                            cursor: "pointer",
                          }}
                        />
                      );
                    })}
                  </div>
                  <div class="help-block with-errors"></div>
                </div>

                <div class="form-submit mt-5">
                  <button class="custom-btn btn-3" type="submit">
                    <i class="material-icons mdi mdi-message-outline"></i>
                    <span>Send message</span>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Feedback;
