import React, { useEffect, useState } from "react";
import Products from "./components/Products";
import "./index.css";
import Slider from "./components/Slider/Slider";
import images from "./components/Slider/images";
import Whatsapp from "./components/Socialmedia/Whatsapp";
import VidComp from "./components/VideoComp/VidComp";
import Insta from "./components/insta/insta";
import Sidebar from "./components/Sidebar/Sidebar";
import Popup from "./components/Popup/Popup";
import Prepare from "./components/prepare/Prepare";
import Testimoni from "./components/Testimoni/Testimoni";
import Navbarr from "./components/Navbar";
import AnimatedCards from "./components/AnimatedCards/AnimatedCards";
import Navbar from "./components/Navbar";
import SliderNew from "./components/SliderNew/SliderNew";
import SpinningWheel from "./components/SpinningWheel/SpinningWheel";
import { useMediaQuery } from "react-responsive";
import wheel_main from "./../src/components/SpinningWheel/Wheel.png";
import ReactGA from "react-ga";

function Home() {
  const [isMobile, setIsMobile] = useState(useMediaQuery({ maxWidth: 767 }));
  const [closePopup, setClosePopup] = useState(true);

  const handleImageClick = () => {
    setIsMobile(true);
  };

  useEffect(() => {
    // Track the pageview for the Home component
    ReactGA.pageview("/"); // Use the appropriate path for your home page
  }, []);

  // const handleButtonClick = () => {
  //   // Track the button click event
  //   ReactGA.event({
  //     category: "Button",
  //     action: "Click",
  //     label: "My Button Label", // Optional
  //   });

  //   // Your button click logic
  //   // ...
  // };

  const handleClose = () => {
    setClosePopup(false);
  };
  return (
    <div className="Apppp">
      <Navbar />

      <Sidebar />
      {/* {isMobile && closePopup ? (
        <div class="popup">
          <button id="close" onClick={handleClose}>
            &times;
          </button>
          <Popup />
        </div>
      ) : closePopup ? (
        <img
          src={wheel_main}
          alt=""
          className="main-wheel"
          onClick={handleImageClick}
        />
      ) : null} */}

      <div class="popup">
        <button id="close" onClick={handleClose}>
          &times;
        </button>
        <Popup />

        {/*<img src={img1} class="blink-image" />
        <h2>Grab here</h2>
        <p>
          Pull up a chair. Take a taste. Come join us. Life is so endlessly
          delicious.There is no love sincerer than the love of food. we are
          offering great deals to our lovable customers.
        </p>
  <Link to="/offers">Let's Go</Link>*/}
      </div>

      {/*} <Notification /> */}
      <div className="bgclr">
        <VidComp />

        <div>
          <Products heading="Choose your favorite" />
        </div>
        <Slider />
        <Insta />

        <Prepare />
        <Testimoni />

        <Whatsapp />
      </div>
      {/* <SpinningWheel /> */}
    </div>
  );
}

export default Home;
