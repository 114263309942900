import React, { useState } from "react";
import "./Events.css";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { LightgalleryProvider, LightgalleryItem } from "react-lightgallery";
import img1 from "../Lightbox/media/img3.jpg";
import {
  EventsData,
  EventsTitles,
  InstoreEventsData,
  InstoreEventsTitles,
  OutdoorEventsData,
  OutdoorEventsTitles,
} from "./EventsData";

const PhotoItem = ({ image, thumb, group }) => (
  <div className="light-img image-123">
    <LightgalleryItem group={group} src={image} thumb={thumb}>
      <img src={image} style={{ width: "100%" }} className="image-12" />
    </LightgalleryItem>
  </div>
);

function Outdoor() {
  const [visible, setVisible] = useState(true);
  return (
    <div className="outdoor-part">
      <br />
      <div>
        <h1 class="store-heading">Outdoor events @Rollover</h1>
        <p className="store-content">
          Rollover is the perfect choice for your outdoor event needs, offering
          ice cream carts, music, and festive decorations to make your occasion
          a success. We provide custom packages to fit your budget and cater to
          events of any size. Contact us today to start planning your next
          memorable event!
        </p>
        <Tabs>
          <TabList>
            {OutdoorEventsTitles.map((product, index) => {
              return (
                <Tab>
                  <p>{product.namer}</p>
                </Tab>
              );
            })}
          </TabList>
          {OutdoorEventsData.map((product, index) => {
            return (
              <TabPanel>
                <div className="panel-content">
                  <h1 className="event-heading">{product.namer}</h1>
                  <div className="content contentfulll flex ">
                    {visible ? (
                      <LightgalleryProvider className="content">
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                          className="content"
                        >
                          <PhotoItem
                            image={product.image}
                            group="group2"
                            className="enent-image"
                          />
                        </div>
                      </LightgalleryProvider>
                    ) : null}
                    <h2 className="event-content">
                      {" "}
                      {product.desc1} <br /> <br />
                      {product.desc2}
                    </h2>
                    <h2 className="event-content"> </h2>
                  </div>
                  <h2 className="event-content">{product.desc3}</h2>
                </div>
              </TabPanel>
            );
          })}
        </Tabs>
      </div>
    </div>
  );
}

export default Outdoor;
