import burger5 from "./ProImages/Menu Pic_s for Website/Burger/veg cheeseburger.jpg";
import burger6 from "./ProImages/Menu Pic_s for Website/Burger/chicken cheese.jpg";

import momo1 from "./ProImages/Menu Pic_s for Website/Momos/chickenfried.jpg";
import momo2 from "./ProImages/Menu Pic_s for Website/Momos/momo2.png";
import momo3 from "./ProImages/Menu Pic_s for Website/Momos/peri peri chickenfried.jpg";
import momo4 from "./ProImages/Menu Pic_s for Website/Momos/peri peri chickensteamed.jpg";
import momo5 from "./ProImages/Menu Pic_s for Website/Momos/panner fried momos.jpg";
import momo6 from "./ProImages/Menu Pic_s for Website/Momos/momo6.png";
import momo7 from "./ProImages/Menu Pic_s for Website/Momos/mix Veg fried.jpg";
import momo8 from "./ProImages/Menu Pic_s for Website/Momos/mix veg steamed.jpg";
import momo9 from "./ProImages/Menu Pic_s for Website/Momos/chicken steamed.jpg";
import momo10 from "./ProImages/Menu Pic_s for Website/Momos/panner steamed.jpg";

import munchie1 from "./ProImages/Menu Pic_s for Website/Munchies/chickenpopcorn.jpg";
import munchie2 from "./ProImages/Menu Pic_s for Website/Munchies/chickennuggets.jpg";
import munchie5 from "./ProImages/Menu Pic_s for Website/Munchies/chickencheeseballs.jpg";
import munchie6 from "./ProImages/Menu Pic_s for Website/Munchies/onionrings.jpg";
import munchie7 from "./ProImages/Menu Pic_s for Website/Munchies/strips.jpg";
import munchie8 from "./ProImages/Menu Pic_s for Website/Munchies/wings.jpg";
import munchie9 from "./ProImages/Menu Pic_s for Website/Munchies/drumsticks.jpg";
import munchie10 from "./ProImages/Menu Pic_s for Website/Munchies/potato pops.jpg";

import quick1 from "./ProImages/Menu Pic_s for Website/Quickbites/frenchfries.jpg";
import quick3 from "./ProImages/Menu Pic_s for Website/Quickbites/quick3.png";
import quick5 from "./ProImages/Menu Pic_s for Website/Quickbites/veg nuggets.jpg";

import shake1 from "./ProImages/Menu Pic_s for Website/Milkshakes/oreo shake.jpg";
import shake4 from "./ProImages/Menu Pic_s for Website/Milkshakes/kit kat shake.jpg";
import shake6 from "./ProImages/Menu Pic_s for Website/Milkshakes/ferrero rocher shake.jpg";
import shake7 from "./ProImages/Menu Pic_s for Website/Milkshakes/blueberry shake.jpg";
import shake14 from "./ProImages/Menu Pic_s for Website/Milkshakes/caramel banana.jpg";
import shake15 from "./ProImages/Menu Pic_s for Website/Milkshakes/saffron cream.jpg";
import shake16 from "./ProImages/Menu Pic_s for Website/Milkshakes/cold coffee shake.jpg";
import shake17 from "./ProImages/Menu Pic_s for Website/Milkshakes/dairymilk shake.jpg";
import shake18 from "./ProImages/Menu Pic_s for Website/Milkshakes/belgium shake.jpg";
import shake19 from "./ProImages/Menu Pic_s for Website/Milkshakes/berry rush shake.jpg";

import Gourmet2 from "./ProImages/Menu Pic_s for Website/Gourmetplates/choco lava therapy.jpg";
import Gourmet3 from "./ProImages/Menu Pic_s for Website/Gourmetplates/deveils choco.jpg";
import Gourmet4 from "./ProImages/Menu Pic_s for Website/Gourmetplates/mango madness.jpg";
import Gourmet8 from "./ProImages/Menu Pic_s for Website/Gourmetplates/strawberry paradise.jpg";
import gourmet9 from "./ProImages/Menu Pic_s for Website/Gourmetplates/lychee rush.jpg";

import karma1 from "./ProImages/Menu Pic_s for Website/SignatureDesserts/choco karma.jpg";
import karma2 from "./ProImages/Menu Pic_s for Website/SignatureDesserts/skillet cake.jpg";
import karma6 from "./ProImages/Menu Pic_s for Website/SignatureDesserts/sizzling brownie.jpg";

import sundae1 from "./ProImages/Menu Pic_s for Website/Exoticsundaes/hot choco fuge.jpg";
import sundae2 from "./ProImages/Menu Pic_s for Website/Exoticsundaes/butterscotch sin.jpg";
import sundae10 from "./ProImages/Menu Pic_s for Website/Exoticsundaes/browine surprise.jpg";
import sundae11 from "./ProImages/Menu Pic_s for Website/Exoticsundaes/soft cake fudge.jpg";
import sundae4 from "./ProImages/Menu Pic_s for Website/Exoticsundaes/sundae strawberry.jpg";
import sundae5 from "./ProImages/Menu Pic_s for Website/Exoticsundaes/choco nut sundae.jpg";

import roll1 from "./ProImages/Menu Pic_s for Website/Icecreamrolls/roll1.png";
import roll2 from "./ProImages/Menu Pic_s for Website/Icecreamrolls/roll2.png";
import roll5 from "./ProImages/Menu Pic_s for Website/Icecreamrolls/roll5.png";
import roll10 from "./ProImages/Menu Pic_s for Website/Icecreamrolls/roll10.png";
import roll14 from "./ProImages/Menu Pic_s for Website/Icecreamrolls/roll14.png";
import roll16 from "./ProImages/Menu Pic_s for Website/Icecreamrolls/roll16.png";
import roll3 from "./ProImages/Menu Pic_s for Website/Icecreamrolls/Choco chip cookie dough.JPG";
import roll4 from "./ProImages/Menu Pic_s for Website/Icecreamrolls/bomb.png";
import roll6 from "./ProImages/Menu Pic_s for Website/Icecreamrolls/Sniker Roll.jpeg";
import roll7 from "./ProImages/Menu Pic_s for Website/Icecreamrolls/Butterscitch caramel .jpeg";
import roll8 from "./ProImages/Menu Pic_s for Website/Icecreamrolls/Pista nuts roll.jpeg";

import cone1 from "./ProImages/Menu Pic_s for Website/WaffleCone/cone1.jpg";
import cone2 from "./ProImages/Menu Pic_s for Website/WaffleCone/cone2.jpg";
import cone3 from "./ProImages/Menu Pic_s for Website/WaffleCone/cone3.jpg";
import cone4 from "./ProImages/Menu Pic_s for Website/WaffleCone/cone4.jpg";
import cone5 from "./ProImages/Menu Pic_s for Website/WaffleCone/cone5.jpg";
import cone6 from "./ProImages/Menu Pic_s for Website/WaffleCone/cone6.jpg";
import cone7 from "./ProImages/Menu Pic_s for Website/WaffleCone/cone7.jpg";
import cone8 from "./ProImages/Menu Pic_s for Website/WaffleCone/cone8.jpg";
import cone9 from "./ProImages/Menu Pic_s for Website/WaffleCone/cone9.jpg";
import cone10 from "./ProImages/Menu Pic_s for Website/WaffleCone/cone10.jpg";

import sand1 from "./ProImages/Menu Pic_s for Website/Sandwiches/smoked panner.jpg";
import sand2 from "./ProImages/Menu Pic_s for Website/Sandwiches/choco cheese.jpg";
import sand3 from "./ProImages/Menu Pic_s for Website/Sandwiches/smoked chicken tikka.jpg";
import sand5 from "./ProImages/Menu Pic_s for Website/Sandwiches/veg cheese sandwich.jpg";

import veg from "./ProImages/veg/veg.png";
import nonveg from "./ProImages/veg/nonveg.png";

export const CombinedData = [
  {
    id: 1,
    img: Gourmet4,
    alt: "Gourmet Plates",
    name: "Mango Madness",
    price: "₹150.00",
    price1: "₹170.00",
    with: "mango pulp",
    img1: veg,
    type: "Vegetarian",
    desc: "Mango mousse served with mango rolls and finished off with some mango pulp.",
  },
  {
    id: 2,
    img: gourmet9,
    alt: "Gourmet Plates",
    name: "Lychee Rush",
    price: "₹150.00",
    price1: "₹170.00",
    with: "cream sauce",
    img1: veg,
    type: "Vegetarian",
    desc: "Lychee fruits served with lychee rolls and drowned with special cream sauce.",
  },
  {
    id: 3,
    img: Gourmet2,
    alt: "Gourmet Plates",
    name: "Choco Lava Therapy",
    price: "₹170.00",
    price1: "₹190.00",
    with: "choco sauce",
    img1: veg,
    type: "Vegetarian",
    desc: "Chocolate lava cake topped with our choco sauce and served with vanilla rolls.",
  },
  {
    id: 4,
    img: Gourmet8,
    alt: "Gourmet Plates",
    name: "Strawberry Paradise",
    price: "₹170.00",
    price1: "₹190.00",
    with: "rice crisps & cream sauce",
    img1: veg,
    type: "Vegetarian",
    desc: "Strawberry mousse on sponge, served with rolls, rice crisps & cream sauce.",
  },
  {
    id: 5,
    img: Gourmet3,
    alt: "Gourmet Plates",
    name: "Devil's Chocolate",
    price: "₹180.00",
    price1: "₹200.00",
    with: "choco chips",
    img1: veg,
    type: "Vegetarian",
    desc: "Rich Chocolate truffle rolls served with choco sauce & topped with choco chips.",
  },
  {
    id: 6,
    img: sundae1,
    alt: "Exotic Sundaes",
    name: "Hot Chocolate Fudge",
    price: "₹125.00",
    price1: "₹145.00",
    with: "peanut & choco sauce",
    img1: veg,
    type: "Vegetarian",
    desc: "The classic sundae with vanilla ice cream, topped with peanut & choco sauce.",
  },
  {
    id: 7,
    img: sundae11,
    alt: "Exotic Sundaes",
    name: "Soft Cake Fudge",
    price: "₹150.00",
    price1: "₹170.00",
    with: "vanilla ice cream",
    img1: veg,
    type: "Vegetarian",
    desc: "Soft chocolate sponge topped onto vanilla ice cream and choco sauce.",
  },
  {
    id: 8,
    img: sundae4,
    alt: "Exotic Sundaes",
    name: "Strawberry Sundae",
    price: "₹150.00",
    price1: "₹170.00",
    with: "crackers and jellies",
    img1: veg,
    type: "Vegetarian",
    desc: "A yummy strawberry ice cream topped with Melba sauce, crackers and jellies.",
  },
  {
    id: 9,
    img: sundae5,
    alt: "Exotic Sundaes",
    name: "Chocolate Nut Sundae",
    price: "₹160.00",
    price1: "₹180.00",
    with: "roasted nuts",
    img1: veg,
    type: "Vegetarian",
    desc: "Indulge in double chocolate scoop with crunchy roasted nuts & heavenly sauce.",
  },
  {
    id: 10,
    img: sundae2,
    alt: "Exotic Sundaes",
    name: "Butterscotch Sin",
    price: "₹160.00",
    price1: "₹180.00",
    with: "almonds & cashews",
    img1: veg,
    type: "Vegetarian",
    desc: "This double scoop is topped with butterscotch nuts, sauce, almonds & cashews.",
  },

  {
    id: 11,
    img: sundae10,
    alt: "Exotic Sundaes",
    name: "Brownie Surprise",
    price: "₹160.00",
    price1: "₹180.00",
    with: "vanilla ice cream",
    img1: veg,
    type: "Vegetarian",
    desc: "Refreshing brownie twist with vanilla ice cream, choco sauce & choco chips.",
  },
  {
    id: 12,
    img: cone1,
    alt: "Waffle Cones",
    name: "American Nuts",
    price: "₹70.00",
    price1: "₹90.00",
    with: "choco chips",
    img1: veg,
    type: "Vegetarian",
    desc: "Taste the burst of flavors in waffle cone with creamy ice cream & choco chips.",
  },
  {
    id: 13,
    img: cone2,
    alt: "Waffle Cones",
    name: "Dry Fruit",
    price: "₹70.00",
    price1: "₹90.00",
    with: "nuts & mixed fruit jam",
    img1: veg,
    type: "Vegetarian",
    desc: "A classic vanilla ice cream, loaded with nuts & mixed fruit jam in a waffle cone.",
  },

  {
    id: 14,
    img: cone3,
    alt: "Waffle Cones",
    name: "Pista",
    price: "₹70.00",
    price1: "₹190.00",
    with: "Pista nuts",
    img1: veg,
    type: "Vegetarian",
    desc: "Pista ice cream, loaded with Pista nuts, served in a waffle cone with toppings.",
  },

  {
    id: 15,
    img: cone4,
    alt: "Waffle Cones",
    name: "Black Currant",
    price: "₹70.00",
    price1: "₹90.00",
    with: "black raisins",
    img1: veg,
    type: "Vegetarian",
    desc: "Creamy blackcurrant ice cream in waffle cone with black raisins topping.",
  },

  {
    id: 16,
    img: cone5,
    alt: "Waffle Cones",
    name: "Chocolate",
    price: "₹70.00",
    price1: "₹90.00",
    with: "chocolate sauce &  chips",
    img1: veg,
    type: "Vegetarian",
    desc: "Choco-loaded chocolate scoop in waffle cone with chocolate sauce &  chips. ",
  },

  {
    id: 17,
    img: cone6,
    alt: "Waffle Cones",
    name: "Vanilla",
    price: "₹70.00",
    price1: "₹90.00",
    with: "choc chips & sprinklers",
    img1: veg,
    type: "Vegetarian",
    desc: "A waffle cone overflowing with creamy vanilla, choc chips & colorful sprinklers.",
  },
  {
    id: 18,
    img: cone7,
    alt: "Waffle Cones",
    name: "Strawberry",
    price: "₹70.00",
    price1: "₹90.00",
    with: "choco chips",
    img1: veg,
    type: "Vegetarian",
    desc: "A cone of pure joy with strawberry ice cream, choco chips, sauce & sprinkles.",
  },

  {
    id: 19,
    img: cone8,
    alt: "Waffle Cones",
    name: "Belgium chocolate",
    price: "₹70.00",
    price1: "₹90.00",
    with: "choco sauce",
    img1: veg,
    type: "Vegetarian",
    desc: "A rich Premium dark choco ice cream with chips, crispy waffle cone, choco sauce.",
  },

  {
    id: 20,
    img: cone9,
    alt: "Waffle Cones",
    name: "Butterscotch",
    price: "₹70.00",
    price1: "₹90.00",
    with: "nuts & choco sauce",
    img1: veg,
    type: "Vegetarian",
    desc: "Yummy creamy butterscotch loaded in a waffle cone with nuts & choco sauce.",
  },

  {
    id: 21,
    img: cone10,
    alt: "Waffle Cones",
    name: "Mango",
    price: "₹70.00",
    price1: "₹90.00",
    with: "sprinklers",
    img1: veg,
    type: "Vegetarian",
    desc: "Take a scoop of creamy mango goodness in a crispy waffle cone with sprinklers.",
  },
  {
    id: 22,
    img: shake1,
    alt: "shake",
    name: "Oreo",
    price: "₹99.00",
    price1: "₹119.00",
    with: "dairy milk",
    img1: veg,
    type: "Vegetarian",
    desc: "Vanilla and chocolate ice cream with Oreo cookies, dairy milk, and truffle paste.",
  },
  {
    id: 23,
    img: shake4,
    alt: "shake",
    name: "Kit kat",
    price: "₹99.00",
    price1: "₹119.00",
    with: "mango pulp",
    img1: veg,
    type: "kit kat",
    desc: "A chocoholic fantasy shake of luscious silky texture with crunchy kit kat bits.",
  },
  {
    id: 24,
    img: shake6,
    alt: "shake",
    name: "Ferrero Rocher Shake",
    price: "₹99.00",
    price1: "₹119.00",
    with: "hazelnut & chocolate",
    img1: veg,
    type: "Vegetarian",
    desc: "Take a sip of rich & Creamy shake with gentle hints of hazelnut & chocolate.",
  },
  {
    id: 25,
    img: shake7,
    alt: "shake",
    name: "Granola blueberry",
    price: "₹99.00",
    price1: "₹119.00",
    with: "granola and blueberry",
    img1: veg,
    type: "Vegetarian",
    desc: "A combo of granola and blueberry gives a crunchy twist to your taste buds.",
  },
  {
    id: 26,
    img: shake14,
    alt: "shake",
    name: "Caramel banana",
    price: "₹99.00",
    price1: "₹119.00",
    with: "mango pulp",
    img1: veg,
    type: "fresh banana",
    desc: "A delicious quick burst of rich salted caramel ice cream and fresh banana.",
  },
  {
    id: 27,
    img: shake15,
    alt: "shake",
    name: "Saffron Cream",
    price: "₹99.00",
    price1: "₹119.00",
    with: "cashews & dairy milk",
    img1: veg,
    type: "Vegetarian",
    desc: "Fantasy shake with Vanilla, saffron cream, almonds, cashews & dairy milk.",
  },
  {
    id: 28,
    img: shake16,
    alt: "shake",
    name: "Cold coffee shake",
    price: "₹99.00",
    price1: "₹119.00",
    with: "mango pulp",
    img1: veg,
    type: "vanilla ice cream",
    desc: "Delicious & creamy mix of strong coffee and  vanilla ice cream, whole milk.",
  },
  {
    id: 29,
    img: shake17,
    alt: "shake",
    name: "Dairy Milk Shake",
    price: "₹99.00",
    price1: "₹119.00",
    with: "topping",
    img1: veg,
    type: "Vegetarian",
    desc: "Dairy milk & chocolate ice cream blend with richness of milk & sauce topping.",
  },
  {
    id: 30,
    img: shake18,
    alt: "shake",
    name: "Belgium Shake",
    price: "₹99.00",
    price1: "₹119.00",
    with: "choco chips",
    img1: veg,
    type: "Vegetarian",
    desc: "Pure bliss of belgium ice cream and chocolate milkshake with choco chips.",
  },
  {
    id: 31,
    img: shake19,
    alt: "shake",
    name: "Strawberry Rush Shake",
    price: "₹99.00",
    price1: "₹119.00",
    with: "strawberry ice cream",
    img1: veg,
    type: "Vegetarian",
    desc: "Delicious cold drink made with strawberry ice cream and flavoring berry fruit.",
  },
  {
    id: 32,
    img: momo1,
    alt: "momos",
    name: "Chicken Momos Fried",
    price: "₹99.00",
    price1: "₹119.00",
    with: "schezwan chutney",
    img1: nonveg,
    type: "Non-Vegetarian",
    desc: "6 fried momos and zesty schezwan chutney will tantalise your taste buds.",
  },
  {
    id: 33,
    img: momo9,
    alt: "momos",
    name: "Chicken Momos Steam",
    price: "₹99.00",
    price1: "₹119.00",
    with: "schezwan chutney",
    img1: nonveg,
    type: "Non-Vegetarian",
    desc: "6 Steamy chicken momos, delicate perfection with bold schezwan chutney.",
  },
  {
    id: 34,
    img: momo3,
    alt: "momos",
    name: "Peri Peri Chicken Fried",
    price: "₹99.00",
    price1: "₹119.00",
    with: "schezwan chutney",
    img1: nonveg,
    type: "Non-Vegetarian",
    desc: "Satisfy spicy cravings with 6 peri peri chicken momos with schezwan chutney.",
  },
  {
    id: 35,
    img: momo4,
    alt: "momos",
    name: "Peri Peri Chicken Steam ",
    price: "₹99.00",
    price1: "₹119.00",
    with: "schezwan chutney",
    img1: nonveg,
    type: "Non-Vegetarian",
    desc: "Perfect match with 6 peri peri chicken momos and schezwan chutney. ",
  },
  {
    id: 36,
    img: momo5,
    alt: "momos",
    name: "Paneer Momos Fried ",
    price: "₹99.00",
    price1: "₹119.00",
    with: "schezwan chutney",
    img1: veg,
    type: "Vegetarian",
    desc: "Enjoy the rich, creamy flavor of 6 paneer momos with schezwan chutney.",
  },
  {
    id: 37,
    img: momo10,
    alt: "momos",
    name: "Paneer Momos Steam",
    price: "₹99.00",
    price1: "₹119.00",
    with: "Schezwan chutney",
    img1: veg,
    type: "Vegetarian",
    desc: "6 Pieces paneer with other herbs steamed and served with Schezwan chutney.",
  },
  {
    id: 38,
    img: momo7,
    alt: "momos",
    name: "Mix Veg Momos Fried",
    price: "₹99.00",
    price1: "₹119.00",
    with: "schezwan chutney",
    img1: veg,
    type: "Vegetarian",
    desc: "Perfect balance of crisp and spice in 6 mixed veg momos with schezwan chutney.",
  },
  {
    id: 39,
    img: momo8,
    alt: "momos",
    name: "Mix Veg Momos Steam",
    price: "₹99.00",
    price1: "₹119.00",
    with: "Fresh Veggies",
    img1: veg,
    type: "Vegetarian",
    desc: "Out burst the flavors with 6 veggie-filled steamed momos and chutney.",
  },
  {
    id: 40,
    img: munchie10,
    alt: "Quickbites",
    name: "Chilli Garlic Potato Pops",
    price: "₹100.00",
    price1: "₹120.00",
    with: "chilli and garlic",
    img1: veg,
    type: "Vegetarian",
    desc: "16 pieces of Crunchy golden potato nuggets seasoned with chilli and garlic.",
  },
  {
    id: 41,
    img: munchie6,
    alt: "munchies",
    name: "Onion Rings",
    price: "₹100.00",
    price1: "₹120.00",
    with: "ketchup",
    img1: nonveg,
    type: "Non-Vegetarian",
    desc: "Enjoy the crispy outer layer and luscious softness of our 4 onion rings with ketchup.",
  },
  {
    id: 42,
    img: quick5,
    alt: "Quickbites",
    name: "Veg Nuggets",
    price: "₹100.00",
    price1: "₹120.00",
    with: "veggie and bold spices",
    img1: veg,
    type: "Vegetarian",
    desc: "10 pieces of Potato nuggets with veggie and bold spices mix, crispy and delicious.",
  },
  {
    id: 43,
    img: munchie2,
    alt: "munchies",
    name: "Chicken Nuggets",
    price: "₹120.00",
    price1: "₹140.00",
    with: "salt and pepper",
    img1: nonveg,
    type: "Non-Vegetarian",
    desc: "8 pieces of deep fried crispy chicken nuggets with hint of salt and pepper.",
  },

  {
    id: 44,
    img: quick1,
    alt: "Quickbites",
    name: "Mr.Fries ",
    price: "₹120.00",
    price1: "₹140.00",
    with: "Fresh Potatos",
    img1: veg,
    type: "Vegetarian",
    desc: "The ultimate snack indulgence, perfectly dusted in your favorite seasoning.",
  },
  {
    id: 45,
    img: munchie1,
    alt: "munchies",
    name: "Chicken Popcorn ",
    price: "₹125.00",
    price1: "₹145.00",
    with: "spices",
    img1: nonveg,
    type: "Non-Vegetarian",
    desc: "16-pieces of chicken popcorn, made with spices for a delicious twist.",
  },
  {
    id: 46,
    img: munchie5,
    alt: "munchies",
    name: "Chicken Cheese Balls",
    price: "₹140.00",
    price1: "₹160.00",
    with: "Chicken & Cheese",
    img1: nonveg,
    type: "Non-Vegetarian",
    desc: "8 Chicken spicy & cheesy balls, fried to perfection & tossed in rich spices.",
  },

  {
    id: 47,
    img: munchie7,
    alt: "Quickbites",
    name: "Chicken Strips ",
    price: "₹170.00",
    price1: "₹190.00",
    with: "Fresh Chicken",
    img1: veg,
    type: "Vegetarian",
    desc: "4 pieces of Crunchy fried chicken fingers taste awesome with ketchup.",
  },
  {
    id: 48,
    img: munchie8,
    alt: "Quickbites",
    name: "Chicken Wings",
    price: "₹180.00",
    price1: "₹200.00",
    with: "Fresh Chicken",
    img1: veg,
    type: "Vegetarian",
    desc: "5 pieces of Chicken wings, spicy and fried chicken appetizer made with tossing.",
  },
  {
    id: 49,
    img: munchie9,
    alt: "Quickbites",
    name: "Chicken Drumsticks Fried",
    price: "₹200.00",
    price1: "₹220.00",
    with: "Fresh chicken",
    img1: veg,
    type: "Vegetarian",
    desc: "4 pieces of our tasty, spicy and fried chicken drumettes, perfect for appetizers.",
  },
  {
    id: 50,
    img: karma1,
    alt: "Signatures",
    name: "Rollover's Chocolate Karma",
    price: "₹149.00",
    price1: "₹169.00",
    with: "chocolate sauce",
    img1: veg,
    type: "Vegetarian",
    desc: "Choco karma with churro crumbs, warm chocolate sauce, a teaser for taste.",
  },
  {
    id: 51,
    img: karma2,
    alt: "Signatures",
    name: "Rollover Skillet Cake",
    price: "₹149.00",
    price1: "₹169.00",
    with: "vanilla ice cream",
    img1: veg,
    type: "Vegetarian",
    desc: "Warm and gooey skillet cookie cake served with vanilla ice cream & sauce.",
  },
  {
    id: 52,
    img: karma6,
    alt: "Signatures",
    name: "Sizzling Brownie ",
    price: "₹149.00",
    price1: "₹169.00",
    with: "hot fudge sauce",
    img1: veg,
    type: "Vegetarian",
    desc: "Enjoy sinfully delicious brownie dessert with ice cream and hot fudge sauce.",
  },
  {
    id: 53,
    img: burger5,
    alt: "burger",
    name: "Veg Cheese Burger",
    price: "₹110.00",
    price1: "₹130.00",
    with: "cheese & mayo sauce",
    img1: veg,
    type: "Vegetarian",
    desc: "The ultimate veggie burger, with a seared patty, lettuce, cheese & mayo sauce.",
  },
  {
    id: 54,
    img: burger6,
    alt: "burger",
    name: "Chicken Cheese Burger",
    price: "₹140.00",
    price1: "₹160.00",
    with: "crispy chicken",
    img1: nonveg,
    type: "Non-Vegetarian",
    desc: "Savor the crunch with our crispy chicken patty burger topped with classic fixings.",
  },
  {
    id: 55,
    img: sand1,
    alt: "Sandwiches",
    name: "Smoked Paneer Sandwich",
    price: "₹110.00",
    price1: "₹130.00",
    with: "Paneer",
    img1: veg,
    type: "Vegetarian",
    desc: "Grilled paneer, marinated in tantalizing spice blend, smoky perfection.",
  },
  {
    id: 56,
    img: sand2,
    alt: "Sandwiches",
    name: "Choco Cheese Sandwich",
    price: "₹110.00",
    price1: "₹130.00",
    with: "chocolate",
    img1: veg,
    type: "Vegetarian",
    desc: "Relish the heavenly rich chocolate and savory cheese with our grilled sandwich.",
  },
  {
    id: 57,
    img: sand3,
    alt: "Sandwiches",
    name: "Smoked Chicken Tikka Sandwich",
    price: "₹110.00",
    price1: "₹130.00",
    with: "fresh veggies",
    img1: veg,
    type: "Vegetarian",
    desc: "The Classic combo of fresh veggies, bread & smoked chicken with perfect texture.",
  },
  {
    id: 58,
    img: sand5,
    alt: "Sandwiches",
    name: "Veg Cheese Sandwich",
    price: "₹110.00",
    price1: "₹130.00",
    with: "cilantro chutney",
    img1: veg,
    type: "Vegetarian",
    desc: "Perfectly Grilled veggies & cilantro chutney make for a taste sensation!",
  },
];
