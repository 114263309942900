import React from "react";
import Whatsapp from "../Socialmedia/Whatsapp";
import Width1 from "./Blogmedia/Gallery/Width1.png";
import Width2 from "./Blogmedia/Gallery/Width2.png";
import Width3 from "./Blogmedia/Gallery/Width3.png";
import "./Blog.css";
import Sidebar from "../Sidebar/Sidebar";
import Lightbox from "../Lightbox/Lightbox";
import { Link } from "react-router-dom";
import EventSub from "./EventSub";
import Navbar from "../Navbar";

function Blog() {
  return (
    <div>
      <Navbar />
      <div className="">
        <div
          id="myCarousel"
          class="carousel slide carousel-fade"
          data-ride="carousel"
        >
          <div class="carousel-inner">
            <div class="carousel-item active">
              <div class="mask flex-center">
                <div class="row align-items-center">
                  <div class="col-md-7 col-12 order-md-1 order-2">
                    <h4 class="font-['Open_Sans']">
                      {" "}
                      Memorable Celebrations <br />
                    </h4>
                    <p>
                      We will make your happiness into double dose with our
                      celebrations. Let's celebrate your events in the joyful
                      environment provided by Roll Over. <br />{" "}
                    </p>
                    <p style={{ fontSize: "26px", fontWeight: "bold" }}>
                      Book your event now and get our joyful celebrations.
                    </p>
                    <Link to="/Contactus" className="click-but">
                      <span>Book Now</span>
                      <div class="liquid"></div>
                    </Link>
                  </div>
                  <div class="col-md-5 col-12 order-md-2 order-1">
                    <img
                      src={Width1}
                      class="mx-auto"
                      alt="slide"
                      className="bordder"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <EventSub />
        <Lightbox />
        {/* <div className="forpadding">
          <div className="Events">
            <h1>MEMORIES ARE MADE HERE</h1>
          </div>
          <br />
          <div class="row">
            <div class="col-lg-4 col-md-12 mb-4 mb-lg-0">
              <img
                src={Width1}
                class="w-100 shadow-1-strong rounded mb-4"
                alt="Boat on Calm Water"
              />

              <img
                src={Height3}
                class="w-100 shadow-1-strong rounded mb-4"
                alt="Wintry Mountain Landscape"
              />
            </div>

            <div class="col-lg-4 mb-4 mb-lg-0">
              <img
                src={height1}
                class="w-100 shadow-1-strong rounded mb-4"
                alt="Mountains in the Clouds"
              />

              <img
                src={Width3}
                class="w-100 shadow-1-strong rounded mb-4"
                alt="Boat on Calm Water"
              />
            </div>

            <div class="col-lg-4 mb-4 mb-lg-0">
              <img
                src={Width2}
                class="w-100 shadow-1-strong rounded mb-4"
                alt="Waves at Sea"
              />

              <img
                src={height2}
                class="w-100 shadow-1-strong rounded mb-4"
                alt="Yosemite National Park"
              />
            </div>
          </div>
        </div> */}

        <Whatsapp />
      </div>
    </div>
  );
}

export default Blog;
