import React from "react";
import "./SliderNew.css";
import { MarqueeData } from "./SliderNewData";
import { MarqueeData2 } from "./SliderNewData";
import { MarqueeData3 } from "./SliderNewData";
import { MarqueeData4 } from "./SliderNewData";
import { VscFeedback } from "react-icons/vsc";

function SliderNew() {
  const duplicatedMarqueeData = [...MarqueeData, ...MarqueeData];
  const duplicatedMarqueeData2 = [...MarqueeData2, ...MarqueeData2];
  const duplicatedMarqueeData3 = [...MarqueeData3, ...MarqueeData3];
  const duplicatedMarqueeData4 = [...MarqueeData4, ...MarqueeData4];

  return (
    <div className="marquee-container">
      <div className="marquee-content">
        {duplicatedMarqueeData.map((category) => (
          <span
            key={category.id}
            className="marquee-item"
            style={{ color: category.color }}
          >
            {category.testimonial}
          </span>
        ))}
      </div>
      <div className="marquee-content2">
        {duplicatedMarqueeData2.map((category) => (
          <span
            key={category.id}
            className="marquee-item"
            style={{ color: category.color }}
          >
            {category.testimonial}
          </span>
        ))}
      </div>
      <div className="marquee-content3">
        {duplicatedMarqueeData3.map((category) => (
          <span
            key={category.id}
            className="marquee-item"
            style={{ color: category.color }}
          >
            {category.testimonial}
          </span>
        ))}
      </div>
      <div className="marquee-content4">
        {duplicatedMarqueeData4.map((category) => (
          <span
            key={category.id}
            className="marquee-item"
            style={{ color: category.color }}
          >
            {/* <VscFeedback /> */}
            {category.testimonial}
          </span>
        ))}
      </div>
      <div className="marquee-overlay"></div>
    </div>
  );
}

export default SliderNew;
