import React from "react";
import { FloatingWhatsApp } from "react-floating-whatsapp";
import img1 from "./wtsaplogo2.jpeg";

function whsatppp(props) {
  return (
    <div>
      <FloatingWhatsApp
        phoneNumber="+91 86880 66805"
        statusMessage="Typically replies within 1 hour"
        accountName="Rollover Warangal"
        avatar={img1}
        allowClickAway="true"
        styles={{ zIndex: "999" }}
      />
    </div>
  );
}

export default whsatppp;
