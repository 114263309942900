import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { CombinedData } from "../components/Menuall/CombinedData";
import Navbar from "../components/Navbar";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Rating from "@mui/material/Rating";
import Stack from "@mui/material/Stack";
import flag from "./../components/SpecificProduct/i-flag.png";

import { SiSwiggy, SiZomato } from "react-icons/si";
import Template from "../components/SpecificProduct/SpecificProduct";
import { Link } from "react-router-dom";

function SpecificProductPage() {
  const { productId } = useParams();
  const thisProduct = CombinedData.find(
    (prod) => prod.id === parseInt(productId)
  );

  const [currentSlide, setCurrentSlide] = useState(0);

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,
    customPaging: function (i) {
      return (
        <div
          style={{
            width: "25px",
            height: "4px",
            background: i === currentSlide ? "#eb3592" : "#94a4b4",
            borderRadius: "1px",
            margin: "0 5px",
          }}
        />
      );
    },
    beforeChange: (current, next) => {
      setCurrentSlide(next);
    },
  };

  return (
    <div>
      <Navbar />
      <div className="t-padding single-item">
        {/* <div class="box-wrapper-popup" key={thisProduct.id}>
          <img src={thisProduct.img} alt="rhcp" />
          <div class="box-content-popup">
            <div class="titlerr-popup">{thisProduct.name}</div>
            <div class="desc-popup">{thisProduct.desc}</div>
            <span class="price-popup">{thisProduct.price}</span>
          </div>
        </div> */}
        <div>
          <div className="website-template-div">
            <div className="website-template-image-div comp2-image">
              <Slider {...settings}>
                <div>
                  <img
                    src={thisProduct.img}
                    alt=""
                    className="specific-image"
                  />
                </div>
                {/* <div>
                  <img
                    src={thisProduct.img}
                    alt=""
                    className="specific-image"
                  />
                </div>
                <div>
                  <img
                    src={thisProduct.img}
                    alt=""
                    className="specific-image"
                  />
                </div> */}
              </Slider>
            </div>

            <div className="gold-product-component-content comp2-content">
              <div class="titlerr-popup">{thisProduct.name}</div>
              <p className="ml-[10px]">with {thisProduct.with}</p>
              <Stack spacing={1} className="ml-[10px]">
                <Rating
                  name="half-rating-read"
                  defaultValue={5}
                  precision={1}
                  readOnly
                />
              </Stack>
              <div class="desc-popup mt-[10px]">
                {thisProduct.desc}
                {/* {thisProduct.desc}
                {thisProduct.desc} */}
              </div>
              <div className="flex justify-start align-items-baseline">
                <span class="price-popup !w-fit !pr-[20px]">
                  {thisProduct.price}
                </span>
                <span class="line-through text-[25px] text-[grey]">
                  {thisProduct.price1}
                </span>
              </div>
              <div className="flex gap-[20px] mt-[20px] mb-[30px] specific-types">
                <div className="flex gap-[10px] specific-type-div">
                  <img
                    src={thisProduct.img1}
                    alt=""
                    className="specific-image1"
                  />
                  {thisProduct.type}
                </div>{" "}
                <div className="flex gap-[10px] specific-type-div">
                  <img src={flag} alt="" className="specific-image1" />
                  Indian
                </div>{" "}
                <div className="specific-type-div">{thisProduct.alt}</div>
              </div>
              <div className="flex gap-[20px] align-items-center">
                <span className="font-[Open_sans] text-[24px] font-bold">
                  Order Now
                </span>
                <button className="btn2 btn-color btn-l-r w-fit flex bg-[#fc8019] order-btn">
                  <a
                    href="https://www.swiggy.com/restaurants/roll-over-opp-tara-gardens-prashanthnagar-colony-warangal-75960?sld=false"
                    target="_blank"
                  >
                    {" "}
                    <span>
                      <SiSwiggy className="text-[32px] !text-white" />
                    </span>
                  </a>
                </button>
                <button className="btn2 btn-color btn-l-r w-fit flex bg-[#cb202d] order-btn">
                  <a
                    href="https://www.zomato.com/warangal/roll-over-hanamakonda/order"
                    target="_blank"
                  >
                    {" "}
                    <span>
                      <SiZomato className="text-[32px] !text-white" />
                    </span>
                  </a>
                </button>
              </div>
              <div className="!mt-[30px relative">
                <Link to="/Comp/" className="back-btn">
                  Back to Menu
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Template /> */}
    </div>
  );
}

export default SpecificProductPage;
