import React from "react";
import "./AboutUsCards.css";
import { FaFacebookF, FaInstagram, FaTwitter, FaYoutube } from "react-icons/fa";

function AboutUsCard2({
  image,
  title,
  heading,
  description1,
  description2,
  button,
  link,
}) {
  return (
    <div>
      <h1 className="prepare-main-title mt-[20px] text-center">
        Rockstar of the Month
      </h1>
      <div className="gold-product-component1">
        <div className="gold-product-component-image-div comp2-image">
          <img
            src={image}
            alt="service"
            className="gold-product-component-image1"
          />
        </div>
        <div className="gold-product-component-content comp2-content">
          <h1 className="font-['Open_Sans'] font-bold !text-[38px] main-title text-[#eb3592] !mb-[20px]">
            {title}
          </h1>

          <p className="about-us-paragraph">{description1}</p>

          <p className="about-us-paragraph">{description2}</p>
          <span className="flex !gap-[5px]">
            <a
              href="https://instagram.com/rolloverwarangal?igshid=YmMyMTA2M2Y="
              className="post-icon mr-[10px]"
            >
              <FaInstagram />
            </a>

            <a
              href="https://youtube.com/channel/UChySNfbzG3o5qI6tdlSasZA"
              className="post-icon  mr-[10px]"
            >
              <FaYoutube />
            </a>

            <a
              href="https://twitter.com/BeyondB98599580"
              className="post-icon  mr-[10px]"
            >
              <FaTwitter />
            </a>
            <a
              href="https://twitter.com/BeyondB98599580"
              className="post-icon  mr-[10px]"
            >
              <FaFacebookF />
            </a>
          </span>
        </div>
      </div>
    </div>
  );
}

export default AboutUsCard2;
