import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import { EffectFade, Navigation, Pagination } from "swiper";
import { IngredientItems } from "./IngredientsData";

function SampleNextArrow(props) {
  const { onClick } = props;
  return (
    <button
      onClick={onClick}
      className="next absolute top-[40%] right-[25px] z-10"
    >
      <svg
        width="66"
        height="66"
        viewBox="0 0 66 66"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M30 25.5L37.5 33L30 40.5"
          stroke="#0F0E0E"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </button>
  );
}

function SamplePrevArrow(props) {
  const { onClick } = props;
  return (
    <button onClick={onClick} className="prev absolute top-[40%] left-0 z-10">
      <svg
        width="66"
        height="66"
        viewBox="0 0 66 66"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M36 25.5L28.5 33L36 40.5"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </button>
  );
}

function Slide({ data, image, namer, setSelectedProduct }) {
  const [nav1, setNav1] = useState();
  const [nav2, setNav2] = useState();

  const handleAfterChange = (currentSlide) => {
    setSelectedProduct(data[currentSlide]);
  };

  return (
    <div className="hotel_details_slider">
      <Slider
        className="mainSlider border-none"
        asNavFor={nav2}
        ref={(slider1) => setNav1(slider1)}
        nextArrow={<SampleNextArrow />}
        prevArrow={<SamplePrevArrow />}
        effect={"fade"}
        navigation={true}
        speed={300}
        modules={[EffectFade, Navigation, Pagination]}
        afterChange={handleAfterChange}
      >
        {data.map((product, index) => {
          return (
            <div key={product.id}>
              <img
                className="larger-slide-image object-cover !bg-white"
                src={image}
                alt=""
              />
              <h1>{namer}</h1>
            </div>
          );
        })}
      </Slider>
    </div>
  );
}

export default Slide;
