/* eslint-disable import/no-anonymous-default-export */
import img1 from "./Media/pizza2.jpg";
import img2 from "./Media/icecream.jpg";
import img3 from "./Media/fries2.jpg";

import img4 from "./Media/MobileImg/pizza4.jpg"
import img5 from "./Media/MobileImg/cone4.jpg"
import img6 from "./Media/MobileImg/fries4.jpg"


export const Images = [
  {
    src: img1,

    text: "Relax with our refreshing and fruity sorbets, bursting with natural flavor.",
  },
  {
    src: img3,
    text: "From the crispy crust to the melted cheese, every bite of our pizza is pure bliss.",
  },

  {
    src: img2,
    text: "Spice up your life with our seasoned fries, coated in a blend of herbs and spices.",
  },
];
export const MobileImages = [
  {
    src: img4,
    text: "From the crispy crust to the melted cheese, every bite of our pizza is pure bliss.",
  },
  {
    src: img5,

    text: "Relax with our refreshing and fruity sorbets, bursting with natural flavor.",
  },
  {
    src: img6,
    text: "Spice up your life with our seasoned fries, coated in a blend of herbs and spices.",
  },
];
