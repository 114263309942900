import React from "react";
import AboutUsCard2 from "../components/Ambassador/AboutUs/AboutUsCard2";
import AElevate from "../components/Ambassador/AElevate";
import AHero from "../components/Ambassador/AHero";
import AJoin from "../components/Ambassador/AJoin";
import AShare from "../components/Ambassador/AShare";
import FAQ from "../components/Ambassador/FAQ";
import HearIt from "../components/Ambassador/HearIt";
import HowItWorks from "../components/Ambassador/HowItWorks/Vision";
import APost from "../components/Ambassador/Post";
import Vision from "../components/Ambassador/Vision/Vision";
import Navbar from "../components/Navbar";
import img2 from "./../components/Ambassador/AboutUs/Media/rockstar.png";

function Ambassador() {
  return (
    <div>
      <Navbar />
      <AHero />
      <AShare />
      <AElevate />
      <Vision />
      <APost />
      <AboutUsCard2
        title="Amita Yadav"
        description1="Explore the top-rated posts of this week shared by our Brand Ambassadors"
        description2="Amita Yadav is our Brand Ambassador of the Week for her impactful content and strong social media presence that helped us connect with a wider audience and drive sales."
        image={img2}
      />
      <HowItWorks />
      <AJoin />
      <HearIt />
      <FAQ />
    </div>
  );
}

export default Ambassador;
