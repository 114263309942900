import vision from "./Media/Cash.svg";
import mission from "./Media/Free.svg";
import values from "./Media/Learn.svg";
import insider from "./Media/Insider.svg";

export const VisionData = [
  {
    id: 1,
    image: mission,
    benefit_type: "Free Products",
    benefit_description: "Get our best-selling Indian products for free",
  },
  {
    id: 2,
    image: vision,
    benefit_type: "Cash Commissiont",
    benefit_description: "Earn 15% commission with no upper cap",
  },
  {
    id: 3,
    image: values,
    benefit_type: "Learn + Grow",
    benefit_description: "Discover ways to boost your earnings",
  },
  {
    id: 4,
    image: insider,
    benefit_type: "Insider Acess",
    benefit_description: "Get insights into exclusive offers",
  },
];
