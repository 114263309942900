import React from "react";
import Parallax from "../components/Image/Parallax";
import Navbar from "../components/Navbar";

function Offers() {
  return (
    <div>
      <Navbar />
      <Parallax />
    </div>
  );
}

export default Offers;
