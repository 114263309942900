import React from "react";
import { Link } from "react-router-dom";
import "./Footerr.css";
import { FaShareAlt } from "react-icons/fa";

const Footerr = () => {
  return (
    <>
      <section className="footer-section pad-t80 parallax">
        <div className="Footer-part">
          <div className="row">
            <div className="col-md-4">
              <div className="footer-title">
                <h3 className="footer-doodle wierd">About Us</h3>
              </div>
              <div className="footer-text">
                <p>
                  We are passionate about bringing the delicious and affordable
                  flavors of Thailand to the world of ice cream and fast food.
                  Our menu features a variety of mouth-watering ice cream rolls
                  and creative fast food dishes that are sure to satisfy your
                  cravings.
                </p>
                <p>
                  Our unique concept combines the creamy, delicious goodness of
                  ice cream with the fresh and vibrant fusion flavors.
                </p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="footer-title">
                <h3 className="footer-doodle wierd">Store Timings</h3>
              </div>
              <div className="footer-office-hour">
                <ul>
                  <li>
                    <a href="#">Opening Days</a>
                  </li>
                  <li>
                    <a href="#">Monday – Friday : 11am to 11pm</a>
                  </li>
                  <li>
                    <a href="#">Saturday & Sunday : 10am to 11pm</a>
                  </li>
                </ul>
                <ul>
                  <li>
                    <a href="#">Locate Us</a>
                  </li>
                  <li>
                    <a href="#">
                      Chaitanyapuri Colony, Hanamkonda, Telangana 506004
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fa fa-phone"></i> 8688066805
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-2">
              <div className="footer-title">
                <h3 className="footer-doodle wierd">Useful Links</h3>
              </div>
              <div className="footer-list">
                <ul>
                  <li>
                    <Link to="/Comp" className="footer-link12">
                      <i className="fa fa-long-arrow-right footer-arrow"></i>{" "}
                      Full Menu
                    </Link>
                  </li>
                  <li>
                    <Link to="/Events" className="footer-link12">
                      <i className="fa fa-long-arrow-right footer-arrow"></i>{" "}
                      Events
                    </Link>
                  </li>
                  <li>
                    <Link to="/offers" className="footer-link12">
                      <i className="fa fa-long-arrow-right footer-arrow"></i>{" "}
                      Offers
                    </Link>
                  </li>
                  <li>
                    <Link to="/image" className="footer-link12">
                      <i className="fa fa-long-arrow-right footer-arrow"></i>{" "}
                      Our Story
                    </Link>
                  </li>
                  <li>
                    <Link to="/Blogcont" className="footer-link12">
                      <i className="fa fa-long-arrow-right footer-arrow"></i>{" "}
                      Blogs
                    </Link>
                  </li>
                  <li>
                    <Link to="/ContactUs" className="footer-link12">
                      <i className="fa fa-long-arrow-right footer-arrow"></i>{" "}
                      Book Event
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-3">
              <div className="footer-title">
                <h3 className="footer-doodle wierd">Join Us</h3>
              </div>
              <p class="t1">Join with us for more new updates..</p>
              <form className="footer-subb">
                <div className="form-group footer-subscribe">
                  <input
                    type="email"
                    className="form-control"
                    id="Email1"
                    placeholder="Subscribe with us"
                  />
                  <button type="submit" className="btn btn-default">
                    Join
                  </button>
                </div>
              </form>

              <div className="top-header-social">
                <ul className="share">
                  <span>
                    <FaShareAlt /> &nbsp; Share
                  </span>
                  <li>
                    <a
                      href="https://www.facebook.com/Rollovernitw/"
                      target="_blank"
                    >
                      <i className="fa fa-facebook fb"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://instagram.com/rolloverwarangal?igshid=YmMyMTA2M2Y="
                      target="_blank"
                    >
                      <i className="fa fa-instagram insta"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://youtube.com/channel/UChySNfbzG3o5qI6tdlSasZA"
                      target="_blank"
                    >
                      <i className="fa fa-youtube yt"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://twitter.com/BeyondB98599580"
                      target="_blank"
                    >
                      <i className="fa fa-twitter tw"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Footerr;
