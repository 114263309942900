import React, { useRef, useState } from "react";
import { IngredientItems } from "./IngredientsData";
import Slider from "react-slide-out";
import "react-slide-out/lib/index.css";
import Slide from "./Slide";

function Ingredients() {
  const sliderRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const openSlider = (product) => {
    setSelectedProduct(product);
    setIsOpen(true);
  };

  const closeSlider = () => {
    setSelectedProduct(null);
    setIsOpen(false);
  };

  const handleOutsideClick = (e) => {
    if (sliderRef.current && !sliderRef.current.contains(e.target)) {
      closeSlider();
    }
  };

  const [selectedProduct, setSelectedProduct] = useState(null);

  return (
    <div className="ingredients-part">
      <div className="ingredients-hero">
        <h1 className="ingredients-head2">28 REAL INGREDIENTS</h1>
        <p className="store-content2">
          Our unwavering commitment to real, natural ingredients sets us apart.
          We proudly list every single one we use and encourage others to do the
          same. It's a simple yet powerful way to promote transparency and build
          trust with our customers. Join us in celebrating food made with
          integrity.
        </p>
      </div>
      <div className="ingredients-items-section">
        {IngredientItems.map((product, index) => {
          return (
            <div className="ingredient-item" key={product.id}>
              <img
                src={product.image}
                alt=""
                className="ingredient-image"
                onClick={() => openSlider(product)}
              />
              <h1
                className="ingredient-item-name"
                onClick={() => openSlider(product)}
              >
                {product.namer}
              </h1>
            </div>
          );
        })}
        {selectedProduct && (
          <Slider
            foldWidth="400px"
            header={
              <div className="flex justify-between">
                <div>
                  <button href="#" onClick={closeSlider} className="close-btn">
                    <span className=" main-button1 mt-[10px]">Close</span>
                  </button>
                </div>
                <div>
                  <h1 className="widget-heading">INGREDIENT</h1>
                </div>
                <div className="">
                  <button className="cursor-default">
                    <span className=" text-white bg-white"></span>
                  </button>
                </div>
              </div>
            }
            isOpen={isOpen}
            ref={sliderRef}
            className="cart-slider"
          >
            <div style={{ width: "100%" }}>
              <Slide
                data={IngredientItems}
                // namer={selectedProduct.namer}
                image={selectedProduct.image}
                setSelectedProduct={setSelectedProduct}
              />
              <div style={{ padding: "15px" }}>
                {selectedProduct.namer && (
                  <h1 className="slider-inner-heading">
                    {selectedProduct.namer}
                  </h1>
                )}
                {selectedProduct.desc1 && (
                  <p className="slider-inner-content">
                    {selectedProduct.desc1}
                  </p>
                )}
                {selectedProduct.desc2 && (
                  <p className="slider-inner-content">
                    {selectedProduct.desc2}
                  </p>
                )}
              </div>
            </div>
          </Slider>
        )}
        {isOpen && <div onClick={handleOutsideClick} className="overlay" />}
      </div>
    </div>
  );
}

export default Ingredients;
