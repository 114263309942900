import React from "react";
import vid1 from "./5.mp4";
import "./Video.css";
import img2 from "./lohbg.png";
import { Wave } from "react-animated-text";

function VidComp() {
  return (
    <div className="kungfuuu">
      <video loop muted autoPlay playsInline className="kung">
        <source src={vid1} type="video/mp4" />
      </video>
      <div class="bg">
        <div class="bgtext">
          <img src={img2} className="bb"></img>
          <h1 className="texttt wierd">
            <Wave
              text="ROLL OVER"
              effect="stretch"
              effectChange={1.1}
              effectDuration={0.9}
            />
          </h1>

          <h3 className="bord">
            <span className="colr col-lg-6">
              GOOD{" "}
              <span style={{ color: "white" }} className="wierd1">
                FOOD{" "}
              </span>
            </span>
            <span className="colrr col-lg-6">
              GOOD{" "}
              <span style={{ color: "white" }} className="wierd1">
                PEOPLE{" "}
              </span>{" "}
            </span>{" "}
            <span className="colrrr col-lg-6">
              {" "}
              GOOD
              <span style={{ color: "white" }} className="wierd1">
                {" "}
                TIMES
              </span>
            </span>
          </h3>
        </div>
      </div>
    </div>
  );
}

export default VidComp;
