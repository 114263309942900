export const MarqueeData = [
  {
    id: 1,
    testimonial: "Tasty food!",
    color: "#e56624",
  },
  {
    id: 2,
    testimonial: "Delicious flavors!",
    color: "#c93f38",
  },
  {
    id: 3,
    testimonial: "Yum!",
    color: "#225577",
  },
  {
    id: 4,
    testimonial: "So good!",
    color: "#990066",
  },
  {
    id: 5,
    testimonial: "Really enjoyed it",
    color: "#456789",
  },
  {
    id: 6,
    testimonial: "Great taste!",
    color: "#00b89f",
  },
  {
    id: 7,
    testimonial: "Loved the flavors",
    color: "#191970",
  },
  {
    id: 8,
    testimonial: "Satisfied my cravings",
    color: "#c0a98e",
  },
  {
    id: 9,
    testimonial: "Relaxed and enjoyable",
    color: "#05a3ad",
  },
  {
    id: 10,
    testimonial: "Simple and comfortable",
    color: "#3d1c02",
  },
  {
    id: 11,
    testimonial: "Nice restaurant setting",
    color: "#cd716b",
  },
  {
    id: 12,
    testimonial: "Easygoing atmosphere",
    color: "#eec400",
  },
  {
    id: 13,
    testimonial: "Chill dining experience",
    color: "#1b2632",
  },
];

export const MarqueeData2 = [
  {
    id: 1,
    testimonial: "Impressive food!",
    color: "#eb8a44",
  },
  {
    id: 2,
    testimonial: "Highly recommended!",
    color: "#5ba8ff",
  },
  {
    id: 3,
    testimonial: "Delicious food! A must-try",
    color: "#f0ck",
  },
  {
    id: 4,
    testimonial: "Great service! Highly recommended",
    color: "#48295b",
  },
  {
    id: 5,
    testimonial: "Best restaurant in town!",
    color: "#eb8a44",
  },
  {
    id: 6,
    testimonial: "Amazing flavors!",
    color: "#000033",
  },
  {
    id: 7,
    testimonial: "Incredible dining experience",
    color: "#eb8a44",
  },
  {
    id: 8,
    testimonial: "Fantastic atmosphere!",
    color: "#131313",
  },
  {
    id: 10,
    testimonial: "Casual and inviting",
    color: "#990066",
  },
  {
    id: 11,
    testimonial: "Positive vibes",
    color: "#00504b",
  },
  {
    id: 12,
    testimonial: "Good vibes",
    color: "#cb738b",
  },
  {
    id: 13,
    testimonial: "Really Delightful dining experience!",
    color: "#d3212d",
  },
];

export const MarqueeData3 = [
  {
    id: 1,
    testimonial: "Exceptional service.",
    color: "#0b4008",
  },
  {
    id: 2,
    testimonial: "Mouthwatering dishes!",
    color: "#00ccff",
  },
  {
    id: 3,
    testimonial: "Unforgettable taste",
    color: "#fec50c",
  },
  {
    id: 4,
    testimonial: "Perfect in every way",
    color: "#bb1133",
  },
  {
    id: 5,
    testimonial: "Yummy eats!",
    color: "#033500",
  },
  {
    id: 6,
    testimonial: "Flavorful dishes!",
    color: "#dc793e",
  },
  {
    id: 7,
    testimonial: "Absolutely delicious",
    color: "#3c0008",
  },
  {
    id: 8,
    testimonial: "Tasted amazing!",
    color: "#003740",
  },
  {
    id: 9,
    testimonial: "Lovely ambiance",
    color: "#a59344",
  },
  {
    id: 10,
    testimonial: "Welcoming space",
    color: "#6c403e",
  },
  {
    id: 11,
    testimonial: "Enjoyable atmosphere",
    color: "#1fb57a",
  },
  {
    id: 12,
    testimonial: "Relaxing environment",
    color: "#35063e",
  },
  {
    id: 13,
    testimonial: "Yummy food, friendly staff!",
    color: "#352d32",
  },
];

export const MarqueeData4 = [
  {
    id: 1,
    testimonial: "So flavorful!",
    color: "#5c899b",
  },
  {
    id: 2,
    testimonial: "Perfectly cooked",
    color: "#cb416b",
  },
  {
    id: 3,
    testimonial: "Real tasty treats",
    color: "#4fc172",
  },
  {
    id: 4,
    testimonial: "Savored every bite",
    color: "#005765",
  },
  {
    id: 5,
    testimonial: "Satisfied my hunger",
    color: "#11aa00",
  },
  {
    id: 6,
    testimonial: "Tasty goodness!",
    color: "#3d1c02",
  },
  {
    id: 7,
    testimonial: "Simply delicious!",
    color: "#9569a3",
  },
  {
    id: 8,
    testimonial: "Truly enjoyable meals",
    color: "#ffae52",
  },
  {
    id: 9,
    testimonial: "Foodie paradise!",
    color: "#7249d6",
  },
  {
    id: 10,
    testimonial: "Satisfying to the last bite",
    color: "#cc0001",
  },
  {
    id: 11,
    testimonial: "Nice atmosphere",
    color: "#8da98d",
  },
  {
    id: 12,
    testimonial: "Great restaurant feel",
    color: "#f87858",
  },
  {
    id: 13,
    testimonial: "Comfortable place",
    color: "#a2c348",
  },
];
