import React from "react";
import img1 from "./media/img1.jpg";
import img2 from "./media/img2.jpg";
import img3 from "./media/img3.jpg";
import img4 from "./media/img4.jpg";
import img5 from "./media/img5.jpg";
import img6 from "./media/img6.jpg";
import eat from "./media/eat.gif";
import "./insta.css";
import { FaInstagram } from "react-icons/fa";
import { Link } from "react-router-dom";
import { Tilt } from "react-tilt";

function Insta() {
  const defaultOptions = {
    reverse: false, // reverse the tilt direction
    max: 35, // max tilt rotation (degrees)
    perspective: 1000, // Transform perspective, the lower the more extreme the tilt gets.
    scale: 1.1, // 2 = 200%, 1.5 = 150%, etc..
    speed: 1000, // Speed of the enter/exit transition
    transition: true, // Set a transition on enter/exit.
    axis: null, // What axis should be disabled. Can be X or Y.
    reset: true, // If the tilt effect has to be reset on exit.
    easing: "cubic-bezier(.03,.98,.52,.99)", // Easing on enter/exit.
  };
  return (
    <div>
      <div className="Containerr">
        <div className="padding wierd">
          <h2 style={{ fontSize: "42px", fontWeight: "bold" }}>
            Follow us on{" "}
            <a
              href="https://instagram.com/rolloverwarangal?igshid=YmMyMTA2M2Y="
              target="_blank"
              className="insta-head-link"
            >
              {""}
              @instagram
            </a>
          </h2>
        </div>
        {/*<div className="eating">
          <img src={eat} />
  </div>*/}

        <br />
        <div class="row">
          <div class="col-lg-2 " id="imggg">
            <Tilt options={defaultOptions}>
              <a
                href="https://www.instagram.com/p/CoL5aXNvcog/"
                target="_blank"
              >
                <img
                  src={img1}
                  class="w-100 shadow-1-strong rounded mb-2"
                  alt="Boat on Calm Water"
                  className="imagee"
                />
                <div class="overlay">
                  <div class="text">
                    {" "}
                    <FaInstagram />
                  </div>
                </div>
              </a>
            </Tilt>
          </div>

          <div class="col-lg-2 " id="imggg">
            <Tilt options={defaultOptions}>
              <a
                href="https://www.instagram.com/p/Cieihbkpd1D/"
                target="_blank"
              >
                <img
                  src={img5}
                  class="w-100 shadow-1-strong rounded mb-2"
                  alt="Boat on Calm Water"
                  className="imagee"
                />
                <div class="overlay">
                  <div class="text">
                    {" "}
                    <FaInstagram />
                  </div>
                </div>
              </a>
            </Tilt>
          </div>
          <div class="col-lg-2 " id="imggg">
            <Tilt options={defaultOptions}>
              <a
                href="https://www.instagram.com/p/Co6oxkaoJO0/"
                target="_blank"
              >
                <img
                  src={img2}
                  class="w-100 shadow-1-strong rounded mb-2"
                  alt="Boat on Calm Water"
                  className="imagee"
                />
                <div class="overlay">
                  <div class="text">
                    {" "}
                    <FaInstagram />
                  </div>
                </div>
              </a>
            </Tilt>
          </div>
          <div class="col-lg-2 " id="imggg">
            <Tilt options={defaultOptions}>
              <a
                href="https://www.instagram.com/p/CpJw2v0Bd_2/"
                target="_blank"
              >
                <img
                  src={img3}
                  class="w-100 shadow-1-strong rounded mb-2"
                  alt="Boat on Calm Water"
                  className="imagee"
                />
                <div class="overlay">
                  <div class="text">
                    {" "}
                    <FaInstagram />
                  </div>
                </div>
              </a>
            </Tilt>
          </div>
          <div class="col-lg-2 " id="imggg">
            <Tilt options={defaultOptions}>
              <a
                href="https://www.instagram.com/p/Cmx_3M4oSHC/"
                target="_blank"
              >
                <img
                  src={img4}
                  class="w-100 shadow-1-strong rounded mb-2"
                  alt="Boat on Calm Water"
                  className="imagee"
                />
                <div class="overlay">
                  <div class="text">
                    {" "}
                    <FaInstagram />
                  </div>
                </div>
              </a>
            </Tilt>
          </div>

          <div class="col-lg-2 " id="imggg">
            <Tilt options={defaultOptions}>
              <a
                href="https://www.instagram.com/p/CV7LSSCqFeu/"
                target="_blank"
              >
                <img
                  src={img6}
                  class="w-100 shadow-1-strong rounded mb-2"
                  alt="Boat on Calm Water"
                  className="imagee"
                />
                <div class="overlay">
                  <div class="text">
                    {" "}
                    <FaInstagram />
                  </div>
                </div>
              </a>
            </Tilt>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Insta;
