import birthdayinstore from "./media/Instore/2.jpg";
import weddinganniversaryinstore from "./media/Instore/7.jpg";
import BachelorPartyinstore from "./media/Instore/1.jpg";
import GetTogetherinstore from "./media/Instore/5.jpg";
import FreshersPartyinstore from "./media/Instore/4.jpg";
import FarewellPartyinstore from "./media/Instore/3.jpg";
import ThanksGivinginstore from "./media/Instore/6.jpg";
import NamingCeremonyOutdoor from "./media/Outdoor/15.jpg";
import EngagementOutdoor from "./media/Outdoor/4.jpg";
import SareeCeremonyOutdoor from "./media/Outdoor/11.jpg";
import HouseWarmingsOutdoor from "./media/Outdoor/12.jpg";
import BabyShowerOutdoor from "./media/Outdoor/13.jpg";
import CradleCeremonyOutdoor from "./media/Outdoor/14.jpg";
import WeddingsOutdoor from "./media/Outdoor/5.jpg";
import ReceptionOutdoor from "./media/Outdoor/2.jpg";
import birthdayOutdoor from "./media/Outdoor/3.jpg";
import FarewellPartyOutdoor from "./media/Outdoor/6.jpg";
import FreshersPartyOutdoor from "./media/Outdoor/7.jpg";
import GetTogetherOutdoor from "./media/Outdoor/8.jpg";
import weddinganniversaryOutdoor from "./media/Outdoor/1.jpg";
import BachelorPartyOutdoor from "./media/Outdoor/9.jpg";
import ThanksGivingOutdoor from "./media/Outdoor/10.jpg";

export const InstoreEventsTitles = [
  {
    id: 0,
    namer: "Birthday",
  },
  {
    id: 1,
    namer: "Anniversary",
  },
  {
    id: 2,
    namer: "Bachelor Party",
  },
  {
    id: 3,
    namer: "Get Together",
  },
  {
    id: 4,
    namer: "Freshers Party",
  },
  {
    id: 5,
    namer: "Farewell Party",
  },
  {
    id: 6,
    namer: "Thanks Giving",
  },
];

export const InstoreEventsData = [
  {
    id: 0,
    image: birthdayinstore,
    namer: "Another year older, another year wiser!",
    desc1:
      "Happy Birthday! At our restaurant, we believe that birthdays should be celebrated in style, surrounded by family, friends, and delicious food. Our team of professionals is dedicated to making your special day unforgettable. From the moment you walk through our doors, you'll be greeted with a warm and welcoming atmosphere that will make you feel right  at home.",
    desc2:
      "We offer a wide range of mouth-watering dishes that are sure to please every palate, from savory appetizers to decadent desserts. And if you have any special dietary needs or preferences, our chefs will be more than happy to accommodate them. ",
    desc3:
      "Our event space is perfect for hosting birthday celebrations of any size, from intimate gatherings to grand parties. With its stylish decor and modern amenities, you and your guests will have everything you need to make lasting memories. So why not celebrate your birthday with us? Contact us today to start planning your special day at our restaurant. We can't wait to make your birthday dreams a reality!",
  },
  {
    id: 1,
    image: weddinganniversaryinstore,
    namer: "Celebrate love, commitment, and a lifetime of memories",
    desc1:
      "Congrats on attaining a new marital milestone! The day of your wedding anniversary is a wonderful occasion to honour the dedication and affection you have both shown to one another throughout the years. We think this event ought to be lavishly recognised. You won't want to miss the incredible incentives we've put together to make your anniversary extra memorable.",
  },
  {
    id: 2,
    image: BachelorPartyinstore,

    namer: "One night to go wild, one night to let loose - make it count",
    desc1:
      "A groom-to-be in celebration of his imminent marriage. These parties are frequently regarded as a rite of passage and a chance for the groom and his pals to let loose and have a good time before tying the knot. There are many of bachelor party activities to pick from, whether you want a crazy night out or something more low-key.",
    desc2:
      "This article suggests activities including bar crawls, golf excursions, and even skydiving. Including games in your bachelor party can provide an added layer of excitement and help visitors break the ice.",
  },
  {
    id: 3,
    image: GetTogetherinstore,
    namer: "Life is better when people are together - join us for a gathering!",
    desc1:
      "People gather at Get Together events to mingle, make new friends, and have fun. It may be used to describe any kind of event, including social gatherings like parties, dinners, picnics, sporting events, and concerts.",
    desc2:
      "A Get Together event's main goal is often to promote social relationships and a sense of community among attendees. People may use the occasion to reconnect with old friends, meet new individuals, or just unwind and have fun. The number of the group, the location, the activities or entertainment, and any food or beverages that will be offered are all things that should be taken into account when planning a successful get-together. adequate communication",
  },
  {
    id: 4,
    image: FreshersPartyinstore,
    namer:
      "Let's make the most of this evening and make memories we'll cherish forever!",
    desc1:
      "Freshmen parties are fun gatherings where incoming students may mingle and make friendships that will last a lifetime.The start of a new phase in life is Freshers Day. It serves as a casual introduction to the seniors, teachers, students, and organisation",
    desc2:
      "At colleges and institutions, this is typically held to welcome the newest group of students. This celebration, which is mostly for the new classes that enrol in the organisation or educational institution, is organised by the seniors and includes entertainment, music, and socialising.",
    desc3:
      "This party serves as a forum for the guides and tutors, as well as the seniors and juniors, to form bonds. It is planned to instill optimism in the freshmen because many may experience confusion and dread as they begin this new stage of life.",
  },
  {
    id: 5,
    image: FarewellPartyinstore,
    namer: "As we bid farewell, let's make sure it's a night to remember!",
    desc1:
      "Are you trying to organise the ideal farewell celebration for a friend or coworker? Look nowhere else! We recognise that saying goodbye can be challenging, but it doesn't have to be a depressing event.",
    desc2:
      "Our goodbye celebrations are intended to honour the departing individual's achievements and fond memories while also ensuring that they are sent off in style.",
  },
  {
    id: 6,
    image: ThanksGivinginstore,
    namer: "Feasting with loved ones is the greatest blessing of all ",
    desc1:
      "Thanksgiving is a celebratedon several dates during which people give thanks for their blessings and material success. It is crucial to express your gratitude to your employer, coworkers, and anybody else who helped you along the path if you have received a promotion.",
    desc2:
      "Thank your supervisor and your coworkers for their assistance and leadership. Share your achievement: Share the news of your promotion with others and give the people who supported you credit for it. Think about showing gratitude to people who assisted you in getting promoted. ",
    desc3:
      "Thanksgiving is a time to express your appreciation for your promotion and all of your other blessings. In general, thanking and appreciating someone for a promotion is a wonderful method to create a good rapport.",
  },
];

export const OutdoorEventsTitles = [
  {
    id: 0,
    namer: "Naming Ceremony",
  },
  {
    id: 1,
    namer: "Engagement",
  },
  {
    id: 2,
    namer: "Saree Ceremony",
  },
  {
    id: 3,
    namer: "House Warmings",
  },
  {
    id: 4,
    namer: "Baby Shower",
  },
  {
    id: 5,
    namer: "Cradle Ceremony",
  },
  {
    id: 6,
    namer: "Weddings",
  },

  {
    id: 7,
    namer: "Reception",
  },
  {
    id: 8,
    namer: "Birthday",
  },
  {
    id: 9,
    namer: "Farewell Party",
  },
  {
    id: 10,
    namer: "Freshers Party",
  },
  {
    id: 11,
    namer: "Get Together",
  },
  {
    id: 12,
    namer: "Anniversary",
  },
  {
    id: 13,
    namer: "Bachelor Party",
  },
  {
    id: 14,
    namer: "Thanks Giving ",
  },
];

export const OutdoorEventsData = [
  {
    id: 0,
    image: NamingCeremonyOutdoor,
    namer: "A new life, a new name, a new beginning",
    desc1:
      "Congratulations on your upcoming Naming Ceremony! This is an exciting milestone worth celebrating with family and friends. At our restaurant, we believe that every event should be a memorable experience, and we're committed to making your Naming Ceremony truly special. Our team of experienced professionals will work with you to create a customized menu that reflects your tastes and preferences.",
    desc2:
      "Whether you're planning an intimate gathering or a grand celebration, our beautiful event space is the perfect setting for your Naming Ceremony. With its elegant decor and modern amenities, you and your guests will feel right at home.",
    desc3:
      "We understand that this is a momentous occasion for you and your loved ones, and we're honored to be a part of it. Let us help you create memories that will last a lifetime. Contact us today to start planning your Naming Ceremony at our restaurant.",
  },
  {
    id: 1,
    image: EngagementOutdoor,
    namer: "Two hearts become one - it all starts with a ring!",
    desc1:
      "We want to help you celebrate in style as you set off on this thrilling path towards a lifetime of love and commitment. Our team of professionals is committed to putting together amazing wedding experiences that showcase your own personality and sense of style.",
    desc2:
      " We provide a variety of customised services to turn your vision for your wedding day into a reality, whether it be for small gatherings or lavish events. To make sure that every detail is taken care of, we'll work with you every step of the way.",
    desc3:
      "This will free you up to spend your big day with your loved ones. Whether you want a stylish urban wedding or a beautiful garden wedding, we have the experience and innovation to make your vision a reality.",
  },
  {
    id: 2,
    image: SareeCeremonyOutdoor,
    namer:
      "Let's celebrate the dhoti/saree ceremony with style, whether it be traditional or modern!",
    desc1:
      "The Dhoti/Saree Ceremony is a customary Hindu ritual that is frequently carried out to commemorate significant life occasions or milestones, like a promotion or a wedding. Close family members often conduct the ritual, which includes giving the person being awarded a new saree or dhoti to wear.",
    desc2:
      "The Dhoti/Saree Ceremony may be held to commemorate the person's dedication and hard work as well as to celebrate their professional accomplishments. The ritualistic celebration may take place at home or in a temple and may include actions like lighting a lamp, saying prayers, and giving presents. The recipient of the honour might have to don a brand-new dhoti or saree, and elders and family members might bless them.",
  },
  {
    id: 3,
    image: HouseWarmingsOutdoor,
    namer: "Where friends and family gather to make new memories.",
    desc1:
      "Housewarming is a celebration of moving into a new house. It is typically hosted by the homeowners as a means to introduce their loved ones to their new house. Housewarming celebrations can range from informal get-togethers with drinks and small bites to more formal affairs with a catered meal.",
    desc2:
      "The hosts can show off any improvements or decorations they have made while giving visitors a tour of the new house. In addition, visitors are welcome to bring gifts for the brand-new homeowners, including kitchen appliances, houseplants, and decorative items.",
    desc3:
      "In general, hosting a housewarming party is a wonderful opportunity to celebrate a new home and introduce friends to the area.",
  },
  {
    id: 4,
    image: BabyShowerOutdoor,
    namer: "A new baby to cherish and adore, with tiny fingers and toes!",
    desc1:
      "Looking for a fun and unique way to celebrate the upcoming arrival of a new baby while enjoying the beauty of nature? Look no further than our rollover ice cream store! We're excited to offer a one-of-a-kind experience for you and your family. We'll take care of all the details so you can unwind and enjoy your day, from venue selection to menu planning, flower arrangements to photography. ",
    desc2:
      "Our skilled staff will make sure that every detail of your Baby Shower runs smoothly and without worry, allowing you to concentrate on creating lasting memories with your loved ones. Moreover, we provide a selection of ice cream flavors and toppings available for anyone who stops by. ",
    desc3:
      "From classic favorites to unique and exotic flavors, there's something for everyone. So why not make your Baby shower a day to remember with our rollover ice cream store? Contact us today to book your event and let us handle the rest. We can't wait to help you celebrate!",
  },
  {
    id: 5,
    image: CradleCeremonyOutdoor,
    namer: "The beginning of a lifetime of love and joy.",
    desc1:
      "A Cradle Ceremony a joyous occasion that celebrates the birth of a new baby and welcomes them into the world  while enjoying the beauty of nature? our rollover ice cream store! We're excited to offer a one-of-a-kind experience. We'll take care of all the details so you can unwind and enjoy your day, from venue selection to menu planning, flower arrangements to photography. ",
    desc2:
      "Our skilled staff will make sure that every detail of your Cradle Ceremony runs smoothly and without worry, allowing you to concentrate on creating lasting memories with your loved ones. Moreover, we provide a selection of ice cream flavors and toppings available for anyone who stops by. ",
    desc3:
      "From classic favorites to unique and exotic flavors, there's something for everyone. So why not make your Cradle Ceremony a day to remember with our rollover ice cream store? Contact us today to book your event and let us handle the rest. We can't wait to help you celebrate!",
  },
  {
    id: 6,
    image: WeddingsOutdoor,
    namer:
      "Love is in the air, and your wedding day is the ultimate expression of your commitment and devotion to one another ",
    desc1:
      "At Rollover, we believe that your wedding celebration should be just as unique and extraordinary as your love story. Our stunning venue provides the perfect backdrop for your special day, with breathtaking views and unparalleled elegance that will leave you and your guests in awe. Our team of expert wedding planners will work tirelessly to bring your vision to life, with personalized attention to every detail and a commitment to exceeding your expectations.",
    desc2:
      "From the stunning floral arrangements to the exceptional cuisine and unparalleled service, every aspect of your wedding will be crafted with the utmost care and attention. Our customizable packages allow you to choose the elements that best suit your style and preferences, ensuring that your wedding celebration is a true reflection of your personality and love.",
    desc3:
      "At Rollover, we're passionate about creating unforgettable experiences that will be cherished for a lifetime. Contact us today to learn more about our wedding packages and services and let's start planning the wedding of your dreams. Get ready to say I do in a truly spectacular setting that will take your breath away!",
  },
  {
    id: 7,
    image: ReceptionOutdoor,
    namer: "Celebrate your Reception with joy and colors",
    desc1:
      "As you step into Rollover Restaurant, get ready to experience the most enchanting evening of your life. Our stunning venue, paired with impeccable service, top-of-the-line amenities, and customized event planning, ensures an unforgettable reception experience that will leave your guests in awe. Our venue offers a perfect balance of sophistication, style, and elegance. From the lush decor to the picturesque views of the city, every element of our venue has been carefully crafted to create a magical ambiance for your reception.",
    desc2:
      "Our team of seasoned professionals is passionate about delivering nothing short of exceptional service, from planning to execution. We believe in leaving no stone unturned to ensure that every detail of your reception is perfectly tailored to your preferences and style. Our exquisite culinary offerings, ranging from international delicacies to local favorites, will leave your guests raving about the food long after the event has ended. Our in-house bar also offers an impressive array of drinks, including signature cocktails, premium wines, and spirits.",
    desc3:
      "At Rollover, we believe in creating memories that will last a lifetime. Contact us today to learn more about our reception event packages and let's start planning an event that is as unique and unforgettable as you are. Come experience the magic that only Rollover can offer!",
  },
  {
    id: 8,
    image: birthdayOutdoor,
    namer: "Another year older, another year wiser",
    desc1:
      "Are you trying to come up with a fun and thrilling birthday celebration? Look nowhere else! The best way to make your special day unique is with one of our birthday party packages.",
    desc2:
      "You can select from a wide range of options and activities with our scalable packages to design the ideal birthday party. We have what you need, whether you want a day full of games and activities, an exciting adventure, or a more laid-back and comfortable celebration.",
    desc3:
      "Our talented party organisers will collaborate with you to design a celebration that fulfils all of your requirements and surpasses all of your expectations. We'll take care of everything, including the food, entertainment, and party favours, so you can rest and take pleasure in your special day.",
  },
  {
    id: 9,
    image: FarewellPartyOutdoor,
    namer: "As we bid farewell, let's make sure it's a night to remember!",
    desc1:
      "Saying goodbye is never easy, but a farewell party can be a great way to celebrate the time you've spent together and create lasting memories. We understand the importance of a meaningful send-off, and we're here to help you plan a farewell party that's lit and on-trend.",
    desc2:
      "Whether you're leaving a job, moving away, or simply embarking on a new adventure, a farewell party at Rollover can be totally customized to your vibe. Our expert event planners can help you choose the perfect menu, decor, and entertainment to make your farewell party an unforgettable experience. We can even work with you to create a custom cocktail or dessert that's Insta-worthy and sure to impress your guests.",
    desc3:
      "At Rollover, we know that saying goodbye can be an emotional rollercoaster. That's why we're here to make sure that your farewell party is stress-free and lit AF. From the moment you arrive, you'll be treated to exceptional service and attention to detail, so you can focus on living your best life and making memories with your squad. So why not celebrate your next chapter with a farewell party that's on fleek? Contact us today to start planning your lit farewell party.",
  },
  {
    id: 10,
    image: FreshersPartyOutdoor,
    namer:
      "Let's make the most of this evening and make memories we'll cherish forever!",
    desc1:
      "Are you ready to kick off the academic year with a bang? Join us at [venue name] for the ultimate Freshers Party! Get ready to meet new friends, dance the night away, and make memories that will last a lifetime. At Rollover, we know that starting college or university can be overwhelming, which is why we're here to help you break the ice and have some fun. Our Freshers Party packages include everything you need for an epic night, from delicious food and drinks to top-notch entertainment.",
    desc2:
      "Our expert event planners can work with you to create a customized Freshers Party experience that reflects your style and preferences. Whether you're into live music, DJ sets, or something a little more low-key, we've got you covered. Plus, our food and drink options are sure to keep you energized all night long.",
    desc3:
      "So why wait? Don't miss out on the biggest party of the academic year. Join us at Rollover for a Freshers Party that's sure to be a blast. So what are you waiting for? Get in touch with us now to learn more and kick-start the planning for the most unforgettable night of your college life!",
  },
  {
    id: 11,
    image: GetTogetherOutdoor,
    namer: "Life is better when we're together - join us for a gathering!",
    desc1:
      "Gathering together with friends, family, or colleagues is always a good time, and what better way to do it than with a Get Together at Rollover. Whether you're celebrating a special occasion or simply want to spend quality time with loved ones, our Get Together packages have got you covered.",
    desc2:
      "Our expert event planners will work with you to create a customized Get Together experience that fits your style and budget. We can help you choose the perfect menu, decor, and entertainment to make your event unforgettable. Whether you're looking for a relaxed brunch or a lively evening party, we can make it happen.",
    desc3:
      "At Rollover, we understand the importance of creating a warm and welcoming atmosphere for your Get Together. That's why we offer exceptional service and attention to detail, so you can sit back, relax, and enjoy the company of your loved ones. So why wait? Contact us now to start planning your unforgettable night of laughter, joy, and cherished memories. Let's create an experience that will leave a lasting impression!",
  },
  {
    id: 12,
    image: weddinganniversaryOutdoor,
    namer:
      "Celebrating love, commitment, and a lifetime of memories on our anniversary.",
    desc1:
      "Celebrate your wedding anniversary in style at Rollover. Our team of culinary experts can help you create a memorable experience with a customized menu featuring your favorite dishes. We can also work with you to add some romantic ambiance to your evening, from special décor and lighting to live music or a custom playlist. And for dessert, indulge in a personalized treat that celebrates your love and commitment.",
    desc2:
      "At Rollover, we understand the importance of creating lasting memories on your anniversary. That's why we go above and beyond to make your evening unforgettable. From the moment you step through our doors, you'll be treated to exceptional service and a warm, welcoming atmosphere. Whether you're celebrating your first anniversary or your fiftieth, we'll help you create a night that you and your partner will never forget.",
    desc3:
      "When you celebrate your wedding anniversary at Rollover, you can rest assured that every detail will be taken care of. We'll work with you to plan a personalized experience that caters to your unique tastes and preferences. From the menu to the décor to the music, everything will be tailored to create a romantic, unforgettable evening. So why not make your anniversary truly special and celebrate with us? Contact us today to start planning your special night.",
  },
  {
    id: 13,
    image: BachelorPartyOutdoor,
    namer: "One night to go wild, one night to let loose - make it count",
    desc1:
      "Looking to throw an epic bachelor party that's sure to be the talk of the town? Look no further than Rollover. We specialize in creating unforgettable experiences that will have you and your squad living your best life.",
    desc2:
      "At Rollover, we offer a range of bachelor party packages that can be customized to fit your style and budget. From a night of dancing and drinks to a private room for gaming and partying, we've got you covered. Our event planners will work with you to choose the perfect menu, decor, and entertainment to make your bachelor party a night to remember.",
    desc3:
      "And let's not forget the drinks! At Rollover, we take our cocktails seriously, with expert mixologists who can whip up custom drinks that reflect your personality and taste. Plus, we've got a wide selection of craft beers and wines to keep the party going all night long. So why settle for a boring bachelor party when you can have an epic one at Rollover? Contact us today to start planning your unforgettable bachelor party. Let's make some memories!",
  },
  {
    id: 14,
    image: ThanksGivingOutdoor,
    namer: "Feasting with loved ones is the greatest blessing of all ",
    desc1:
      "The season of giving thanks is upon us, and what better way to celebrate than with a memorable Thanksgiving event at Rollover. Our expert event planners are here to help you create an unforgettable experience that captures the essence of this cherished holiday.",
    desc2:
      "Indulge in our delectable Thanksgiving menu, which includes traditional favorites and modern twists on classic dishes. Our culinary team sources only the finest, freshest ingredients to create dishes that are sure to delight your taste buds. At Rollover, we take pride in our exceptional service and attention to detail. Our team of professionals is dedicated to providing you and your guests with a warm, welcoming, and festive atmosphere that embodies the spirit of Thanksgiving.",
    desc3:
      "So why wait? Bring your loved ones together and join us for a Thanksgiving event that's sure to be remembered for years to come. Contact us today to learn more about our customizable packages and start planning an event that truly gives thanks to the blessings in your life. Let's make this Thanksgiving one to remember!",
  },
];
