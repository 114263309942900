import React from "react";
import Fade from "react-reveal";
import Zoom from "react-reveal/Zoom";
import "./Animate.css";

function Animate() {
  return (
    <div>
      <section className="about-part">
        <div className="about-shape" />
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-heading">
                <h2 className="titl">Roll Over Specials</h2>
              </div>
            </div>
          </div>
        </div>

        <Zoom>
          <div class="container">
            <div class="row">
              <div class="col-sm-1"></div>

              <div class="col-sm-4">
                <h2>Our Story</h2>
                <p style={{ justifyContent: "center" }}>
                  Warangal's first exclusive hand-rolled ice cream Parlour.
                  Rollover's sole purpose is to serve memories encapsulated in
                  rolls of delight. Rollover ultimately provides a richer and
                  superior experience to our sophisticated consumers.
                </p>
              </div>

              <div class="col-sm-2"></div>
              <div style={{ paddingTop: "100px" }} class="col-sm-4">
                <h2>First Priority</h2>
                <p style={{ justifyContent: "center" }}>
                  "Always take care of your food and it will take care of you"
                  Rollover follows all rules and health measures to serve
                  Hygiene food to our loved customers.
                </p>
              </div>
            </div>
          </div>
        </Zoom>

        <br />
        <Zoom>
          <div classn="container">
            <div class="row">
              <div class="col-sm-2"></div>
              <div class="col-sm-4">
                <h2>Hangout Spot</h2>
                <p style={{ justifyContent: "center" }}>
                  Come here to chill with your friends and family by some of the
                  creamiest ice-cream rolls and good Appetizers. you will never
                  disappointed with our specially hand-crafted ice cream rolls.
                </p>
              </div>
              <div class="col-sm-1"></div>
              <div style={{ paddingTop: "100px" }} class="col-sm-4">
                <h2>Specials For you</h2>
                <p style={{ justifyContent: "center" }}>
                  Even ice cream becomes the main dessert dish, But why should
                  we stop with only it.. Our team Also thinks the same and we
                  are going to deliver you a fusion of ice cream flavors called
                  “Ice Cream rolls”.
                </p>
              </div>
            </div>
          </div>
        </Zoom>
      </section>
    </div>
  );
}

export default Animate;
