import React from "react";
import { Link } from "react-router-dom";
import "./Blog.css";
import styled from "styled-components";
import Instore from "./Blogmedia/Gallery/instore.jpg";
import Outdoor from "./Blogmedia/Gallery/outdoor.jpg";

function EventSub() {
  return (
    <div className="bg-[#fef2f3] min-[1910px]:pb-[40px] min-[1910px]:mt-[40px]">
      <Container>
        <LeftContent>
          <Title>Events at Rollover - Happy to Introduce our Selves</Title>
          <SubWraper>
            <Line />
            <Subtitle>The Food & Beverage Innovators</Subtitle>
          </SubWraper>
        </LeftContent>
        <RightContent>
          <HistoryDescription>
            Rollover is more than just an ice cream outlet; it's a place where
            families and friends can come together to enjoy a day of fun and
            excitement. With our instore and outdoor events, there's always
            something new and exciting to experience. Whether you're a fan of
            ice cream eating contests or prefer a relaxed outdoor picnic,
            Rollover has something for everyone.
          </HistoryDescription>
        </RightContent>
      </Container>
      <section className="resume  section" id="resume">
        <div className="resume__container">
          <div class="resume-box">
            <h2 className="section__title title-sub text-left font-[optima] mb-4 domain-name">
              <Link to="/Instore">
                <span className="event-head">Instore Events</span>
              </Link>
            </h2>
            <div className="timeline gird">
              <div>
                <img src={Instore} class="events-image"></img>
              </div>
              <p class="font-['Open_sans'] text-[14px] leading-[24px]">
                {" "}
                We offer in-store events with ice cream tastings and special
                promotions. Our staff can assist with planning and execution.
                Customized options are available to fit your needs and budget.
                We provide catering for parties and gatherings, featuring
                exclusive flavors and toppings.
              </p>
              <Link to="/instore">
                <a class="mt-3  underline font-semibold cursor-pointer inline-flex items-center Learn-more-hero learn-more-link font-[Optima]">
                  Learn More
                  <svg
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    class="w-4 h-4 ml-2"
                    viewBox="0 0 24 24"
                  >
                    <path d="M5 12h14M12 5l7 7-7 7"></path>
                  </svg>
                </a>
              </Link>
            </div>
          </div>
          <div class="resume-box">
            <h3 className="section__title2 title-sub text-left font-[optima] mb-4 domain-name">
              <Link to="/outdoor">
                <span className="event-head">Outdoor Events </span>
              </Link>
            </h3>
            <div className="timeline gird">
              <div>
                <img src={Outdoor} class="events-image"></img>
              </div>
              <p class="font-['Open_sans'] text-[14px] leading-[24px]">
                Rollover is the perfect choice for your outdoor event needs,
                offering ice cream carts, music, and festive decorations to make
                your occasion a success. We provide custom packages to fit your
                budget and cater to events of any size. Contact us today to
                start planning your next memorable event!
              </p>
              <Link to="/outdoor">
                <a class="mt-3 text-[#B99653] underline font-semibold cursor-pointer inline-flex items-center Learn-more-hero learn-more-link font-[Optima]">
                  Learn More
                  <svg
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    class="w-4 h-4 ml-2"
                    viewBox="0 0 24 24"
                  >
                    <path d="M5 12h14M12 5l7 7-7 7"></path>
                  </svg>
                </a>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default EventSub;
const Container = styled.section`
  display: flex;
  align-items: start;
  padding: 3rem 6rem;

  @media (min-width: 1910px) {
    padding: 2rem 18rem 0rem;
  }

  @media (max-width: 1200px) {
    padding: 5rem 5rem;
  }
  @media (max-width: 900px) {
    padding: 1rem 6rem;
    flex-direction: column;
    margin: 20px 0rem;
  }
  @media (max-width: 800px) {
    padding: 1rem 6rem;
    flex-direction: column;
  }
  @media (max-width: 700px) {
    padding: 1rem 1rem;
  }
`;
const LeftContent = styled.div`
  width: 50%;

  @media (max-width: 900px) {
    width: 100%;
  }
`;
const Description = styled.p`
  font-size: 18px;
  font-weight: 400;
  color: var(--font-description);
  width: 100%;
  margin: 30px 0px;

  @media (max-width: 1200px) {
    margin: 15px 0px;
  }
`;
export const Title = styled.h2`
  font-size: 38px;
  line-height: 45px;
  font-family: "Open Sans";
  font-weight: bold;
  color: #eb3592;

  @media (max-width: 1200px) {
    font-size: 32px;
    line-height: 45px;
  }
  @media (max-width: 700px) {
    font-size: 26px;
    line-height: 36px;
  }
`;
const SubWraper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 25px;
`;
const Subtitle = styled.p`
  color: black;
  font-weight: bold;
  font-family: "Open Sans";
`;
const HistoryDescription = styled(Description)`
  margin: 0;
  font-family: "Open Sans";
  font-size: 15px;
  line-height: 28px;
`;
const Line = styled.p`
  width: 75px;
  height: 2px;
  background: #eb3592;
  margin-right: 30px;
`;
const RightContent = styled.div`
  width: 50%;

  @media (max-width: 900px) {
    width: 100%;
    margin-top: 2rem;
  }
`;
