import React, { useState } from "react";
import vid1 from "./Media/One.mp4";
import vid2 from "./Media/Two.mp4";
import vid3 from "./Media/Three.mp4";
import vid4 from "./Media/Four.mp4";
import vid5 from "./Media/Five.mp4";
import vid6 from "./Media/Six.mp4";
import "./Ambassador.css";
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

const videos = [
  {
    id: 1,
    video: vid1,
  },

  {
    id: 2,
    video: vid2,
  },
  {
    id: 3,
    video: vid3,
  },
  {
    id: 4,
    video: vid4,
  },
  {
    id: 5,
    video: vid5,
  },
  {
    id: 6,
    video: vid6,
  },
];

function AHero() {
  const [isMobile, setIsMobile] = useState(useMediaQuery({ maxWidth: 767 }));
  return (
    <div className="a-hero-part">
      {isMobile ? (
        // Display only the first two videos when on mobile
        <>
          {videos.slice(0, 2).map((item) => (
            <div key={item.id} className="a-hero-video">
              <video loop muted autoPlay>
                <source src={item.video} type="video/mp4" />
              </video>
            </div>
          ))}
        </>
      ) : (
        // Display all videos when not on mobile
        <>
          {videos.map((item) => (
            <div key={item.id} className="a-hero-video">
              <video loop muted autoPlay>
                <source src={item.video} type="video/mp4" />
              </video>
            </div>
          ))}
        </>
      )}

      <div className="join-div">
        <h1 className="prepare-main-title">Rollover Ambassador</h1>
        <p className="card-text mb-[60px]">
          Share your love for Rollover food and earn lifetime rewards!
        </p>
        <Link to="/Subscription" className="click-but join-btn">
          <span>Join Community</span>
          {/* <div className="liquid"></div> */}
        </Link>
      </div>
    </div>
  );
}

export default AHero;
