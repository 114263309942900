import React from "react";
import img1 from "./images/burger1.jpg";
import img2 from "./images/icecreams.jpg";
import img3 from "./images/momos1.jpg";
import img4 from "./images/roll1.jpg";
import img5 from "./images/sandwich1.jpg";
import "./Blogcont.css";
import img6 from "./images/fries1.jpg";
import img7 from "./images/shakes1.jpg";
import Whatsapp from "../Socialmedia/Whatsapp";
import Sidebar from "../Sidebar/Sidebar";
import Navbar from "../Navbar";
import Fade from "react-reveal/Fade";

function Blogcont() {
  return (
    <div>
      <Navbar />
      <Sidebar />
      <div
        class="biro-component-listing-image "
        style={{ backgroundColor: "#f8f7f5" }}
      >
        <ul class="list container pt-12">
          <Fade bottom>
            <li class="item-blog row">
              <a class="link">
                <div class="row blog-roll">
                  <div class="col-image col-lg-5">
                    <div class="">
                      <img
                        width="600"
                        height="400"
                        src={img3}
                        class="wp-post-image"
                        loading="lazy"
                        sizes="(max-width: 600px) 100vw, 600px"
                      />
                    </div>
                  </div>
                  <div class="col-text col-lg-7">
                    <div class="text-wrapper">
                      <div class="preheading">Momos</div>
                      <div class="title">
                        Everything's better when there are momos around.
                      </div>
                      <div class="teaser">
                        Momos are a traditional Tibetan and Nepalese dumpling,
                        filled with a variety of tasty fillings and steamed to
                        perfection. Our menu features a range of momos to choose
                        from, including classic meat and vegetable options, as
                        well as more unique and adventurous flavors like cheese
                        and garlic, and even chocolate and banana.
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </li>
          </Fade>
          <Fade bottom>
            <li class="item-blog row">
              <div class="col-12">
                <a class="link">
                  <div class="row blog-roll">
                    <div class="col-image col-lg-5">
                      <div class="">
                        <img
                          width="600"
                          height="400"
                          src={img1}
                          class="wp-post-image"
                          loading="lazy"
                          sizes="(max-width: 600px) 100vw, 600px"
                        />
                      </div>
                    </div>
                    <div class="col-text col-lg-7">
                      <div class="text-wrapper">
                        <div class="preheading">Burgers</div>
                        <div class="title">
                          Life is too short to miss out on burgers.
                        </div>
                        <div class="teaser">
                          Introducing the burger of your dreams! Our burgers are
                          made with 100% premium, sourced from local farms and
                          carefully hand-crafted into perfectly sized and shaped
                          patties. We grill each patty to order, using only the
                          finest and freshest ingredients, to create the perfect
                          balance of flavor and texture.
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </li>
          </Fade>
          <Fade bottom>
            <li class="item-blog row">
              <div class="col-12">
                <a class="link">
                  <div class="row blog-roll">
                    <div class="col-image col-lg-5">
                      <div class="">
                        <img
                          width="600"
                          height="400"
                          src={img6}
                          class="wp-post-image"
                          loading="lazy"
                          sizes="(max-width: 600px) 100vw, 600px"
                        />
                      </div>
                    </div>
                    <div class="col-text col-lg-7">
                      <div class="text-wrapper">
                        <div class="preheading">Quick Bites</div>
                        <div class="title">
                          Keep your friends and your fries closer.
                        </div>
                        <div class="teaser">
                          If you have a hankering for crispy on the outside,
                          creamy on the inside, perfectly salted French Fries,
                          then look no further. I have a homemade Finger Chips
                          recipe that will rival anything the fast food chains
                          can offer, all from the comfort of your own home.
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </li>
          </Fade>
          <Fade bottom>
            <li class="item-blog row">
              <div class="col-12">
                <a class="link">
                  <div class="row blog-roll">
                    <div class="col-image col-lg-5">
                      <div class="">
                        <img
                          width="600"
                          height="400"
                          src={img5}
                          class="wp-post-image"
                          loading="lazy"
                          sizes="(max-width: 600px) 100vw, 600px"
                        />
                      </div>
                    </div>
                    <div class="col-text col-lg-7">
                      <div class="text-wrapper">
                        <div class="preheading">Sandwiches</div>
                        <div class="title">
                          A sandwich a day keeps the sadness away.
                        </div>
                        <div class="teaser">
                          A sandwich is only as good as the ingredients that go
                          into it. That's our philosophy at our sandwich shop,
                          where we are dedicated to creating delicious and
                          satisfying sandwiches that are made with the freshest
                          and highest-quality ingredients.
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </li>
          </Fade>
          <Fade bottom>
            <li class="item-blog row">
              <div class="col-12">
                <a class="link">
                  <div class="row blog-roll">
                    <div class="col-image col-lg-5">
                      <div class="">
                        <img
                          src={img2}
                          class="wp-post-image"
                          width="600"
                          height="400"
                        />
                      </div>
                    </div>
                    <div class="col-text col-lg-7">
                      <div class="text-wrapper">
                        <div class="preheading">Ice Creams</div>
                        <div class="title">
                          Don’t be sad you deserve an ice cream.
                        </div>
                        <div class="teaser">
                          The joy of entering an ice cream shop and choosing
                          your favorite flavor is just second to none. Because
                          your life deserves sweetness. It’s cold, it’s
                          refreshing, and most importantly, it’s delicious! Ever
                          since my first lick of ice cream, it has been an
                          endless love story.
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </li>
          </Fade>
          <Fade bottom>
            <li class="item-blog row">
              <div class="col-12">
                <a class="link">
                  <div class="row blog-roll">
                    <div class="col-image col-lg-5">
                      <div class="">
                        <img
                          width="600"
                          height="400"
                          src={img4}
                          class="wp-post-image"
                          loading="lazy"
                          sizes="(max-width: 600px) 100vw, 600px"
                        />
                      </div>
                    </div>
                    <div class="col-text col-lg-7">
                      <div class="text-wrapper">
                        <div class="preheading">Ice Cream Rolls</div>
                        <div class="title">
                          Ice cream makes everything better.
                        </div>
                        <div class="teaser">
                          Oh! You thought both ice cream and ice cream rolls
                          were the same? Well, no! Both are not the same even
                          though they both taste…. Commit your work to the time
                          and taste to the ice cream roll. A balanced diet is
                          the ice cream rolls in each hand. Grab the ice cream
                          roll and make your day special.
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </li>
          </Fade>
          <Fade bottom>
            <li class="item-blog row">
              <div class="col-12">
                <a class="link">
                  <div class="row blog-roll">
                    <div class="col-image col-lg-5">
                      <div class="">
                        <img
                          width="600"
                          height="400"
                          src={img7}
                          class="wp-post-image"
                          loading="lazy"
                          sizes="(max-width: 600px) 100vw, 600px"
                        />
                      </div>
                    </div>
                    <div class="col-text col-lg-7">
                      <div class="text-wrapper">
                        <div class="preheading">Shake Factory</div>
                        <div class="title">When life gets tough, shake it.</div>
                        <div class="teaser">
                          A cold drink made of milk, a sweet flavouring such as
                          fruit or chocolate, and typically ice cream, whisked
                          until it is frothy..
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </li>
          </Fade>
        </ul>
      </div>
      <Whatsapp />
    </div>
  );
}

export default Blogcont;
