import React from "react";
import "./AnimatedCards.css";
import img1 from "./Media/pizza1.jpg";
import img3 from "./Media/mocktails1.jpg";
import img2 from "./Media/kids1.jpg";
import { Link } from "react-router-dom";

import styled from "styled-components";

function AnimatedCards() {
  return (
    <div className="Animate-Cards-Part">
      <br />
      <br />
      {/* <center>
        <h1 className="rollover-events-head">Newly Arrived in Rollover</h1>
      </center> */}
      <Container>
        <LeftContent>
          <Title>
            Newly arrived items at Rollover - We keep ourselves trendy.
          </Title>
          <SubWraper>
            <Line />
            <Subtitle>The Food & Beverage Innovators</Subtitle>
          </SubWraper>
        </LeftContent>
        <RightContent>
          <HistoryDescription>
            At Rollover, we're always on the lookout for the latest trends and
            newest flavors to keep our customers coming back for more. That's
            why we're excited to announce the arrival of our newest items that
            are sure to satisfy your sweet tooth.
            <br />
            So come on down to Rollover and experience the latest and greatest
            in sweet treats. We can't wait to see you!
          </HistoryDescription>
        </RightContent>
      </Container>
      <br />
      <div className="row large-collapse">
        <div className="columns !m-0 !p-0  col-md-6">
          <Link to="/Comp" className="item">
            <img src={img1} alt="boat dock" />
            {/* <div className="item-content flex-container">
              <div className="item-title flex-item">
                <p>Claim your space in the digital world</p>
              </div>
            </div> */}
          </Link>
        </div>
        <div className="columns !m-0 !p-0 col-md-6">
          <Link to="/Comp" className="item">
            <img src={img2} alt="boat dock" />
            {/* <div className="item-content flex-container">
              <div className="item-title flex-item">
                <p>Sell it your way</p>
              </div>
            </div> */}
          </Link>
        </div>
        <div className="columns !m-0 !p-0 col-md-12">
          <Link to="/Comp" className="item">
            <img src={img3} alt="boat dock" />
            {/* <div className="item-content flex-container">
              <div className="item-title flex-item">
                <p>Sell it your way</p>
              </div>
            </div> */}
          </Link>
        </div>
      </div>
    </div>
  );
}

export default AnimatedCards;

const Container = styled.section`
  display: flex;
  align-items: start;
  padding: 3rem 6rem;

  @media (min-width: 1910px) {
    padding: 3rem 17rem 1rem 17rem;
  }

  @media (max-width: 1200px) {
    padding: 5rem 5rem;
  }
  @media (max-width: 900px) {
    padding: 1rem 6rem;
    flex-direction: column;
    margin: 20px 0rem;
  }
  @media (max-width: 800px) {
    padding: 1rem 6rem;
    flex-direction: column;
  }
  @media (max-width: 700px) {
    padding: 1rem 1rem;
  }
`;
const LeftContent = styled.div`
  width: 50%;

  @media (max-width: 900px) {
    width: 100%;
  }
`;
const Description = styled.p`
  font-size: 18px;
  font-weight: 400;
  color: var(--font-description);
  width: 100%;
  margin: 30px 0px;

  @media (max-width: 1200px) {
    margin: 15px 0px;
  }
`;
export const Title = styled.h2`
  font-size: 38px;
  line-height: 45px;
  font-family: "Open Sans";
  font-weight: bold;
  color: #eb3592;

  @media (max-width: 1200px) {
    font-size: 32px;
    line-height: 45px;
  }
  @media (max-width: 700px) {
    font-size: 26px;
    line-height: 36px;
  }
`;
const SubWraper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 25px;
`;
const Subtitle = styled.p`
  color: black;
  font-weight: bold;
  font-family: "Open Sans";
`;
const HistoryDescription = styled(Description)`
  margin: 0;
  font-family: "Open Sans";
  font-size: 15px;
  line-height: 28px;
`;
const Line = styled.p`
  width: 75px;
  height: 2px;
  background: #eb3592;
  margin-right: 30px;
`;
const RightContent = styled.div`
  width: 40%;
  margin-left: 20px;
  @media (max-width: 900px) {
    width: 100%;
    margin-top: 2rem;
    margin-left: 0px;
  }
`;
