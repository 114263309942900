import React from "react";
import "./Product.css";
import img1 from "./Gallery2/burger.jpg";
import img2 from "./Gallery2/brownie.jpg";
import img3 from "./Gallery2/chickenwings.jpg";
import img4 from "./Gallery2/gourmetplates.jpg";
import img5 from "./Gallery2/icecream.jpg";
import img6 from "./Gallery2/momos.jpg";
import img7 from "./Gallery2/rolls.jpg";
import img8 from "./Gallery2/sandwich.jpg";
import img9 from "./Gallery2/milkshake.jpg";
import img10 from "./Gallery2/sundaes.jpg";
import { Link } from "react-router-dom";

import { MdFastfood } from "react-icons/md";
import { FaIceCream, FaHamburger, FaCheese } from "react-icons/fa";
import { BiDrink } from "react-icons/bi";
import {
  GiFrenchFries,
  GiMasonJar,
  GiCupcake,
  GiSandwich,
  GiRolledCloth,
  GiBerriesBowl,
  GiBowlOfRice,
  GiChocolateBar,
  GiChickenOven,
  GiHotMeal,
} from "react-icons/gi";
import { CiBurger, CiPillsBottle1 } from "react-icons/ci";
import { IoMdIceCream } from "react-icons/io";
import { SiJusteat } from "react-icons/si";
import { TypeAnimation } from "react-type-animation";

const Products = () => {
  return (
    <section class="pt-5 pb-5 specials-section">
      <div class="row">
        <div class=" roll-special">
          <h2 class="mb-3 Special-head text-[1.7rem] wierd !text-left">
            ROLL OVER SPECIALS - &nbsp;
            <TypeAnimation
              sequence={[
                "Hand crafted specially for you...", // Types 'One'
                1000, // Waits 1s
                "Make your loved ones happy @Rollover...", // Deletes 'One' and types 'Two'
                1000, // Waits 2s
                "Surprise her with a treat @Rollover...",
                1000,
                "What are you waiting for???",
                1000, // Types 'Three' without deleting 'Two',
                "Don't stop yourself from deliciousness...",
                1000,
                "Visit our store for fun filled time...",
                1000,
                "Feeling lazy? You can order from home too...",
                1000,
              ]}
              wrapper="span"
              cursor={true}
              repeat={Infinity}
              style={{
                display: "inline-block",
                padding: "",
              }}
            />
          </h2>
        </div>
        <div class=" text-right roll-special1 sm:hidden md:block">
          <a
            class=" btn-primary battuu  md:mr-1"
            href="#carouselExampleIndicators2"
            role="button"
            data-slide="prev"
          >
            <i class="fa fa-arrow-left"></i>
          </a>
          <a
            class=" btn-primary battuu  "
            href="#carouselExampleIndicators2"
            role="button"
            data-slide="next"
          >
            <i class="fa fa-arrow-right"></i>
          </a>
        </div>
        <div class="col-12">
          <div
            id="carouselExampleIndicators2"
            class="carousel slide"
            data-ride="carousel"
          >
            <div class="carousel-inner">
              <div class="carousel-item active">
                <div class="row">
                  <div class="col-md-3 card-part blog-item">
                    <Link to="/Comp">
                      <div class="card card1">
                        <Link to="/Comp" className="blog-item-image-div">
                          <img
                            class="img-fluid blog-image"
                            alt="100%x280"
                            src={img1}
                          />
                        </Link>

                        <div class="card-body card-body1">
                          <h4 class="card-title">Burgers</h4>

                          <p class="card-text">
                            Laughter is loudest where there are burgers. It's
                            happiness between two buns.
                          </p>
                        </div>
                      </div>
                    </Link>

                    <Link to="/Comp/#kkkkk" class="see-more-btn">
                      <CiBurger class="see-more-icon" /> &nbsp; See more
                    </Link>
                  </div>
                  <div class="col-md-3 card-part blog-item">
                    <Link to="/Comp">
                      <div class="card card1">
                        <Link to="/Comp" className="blog-item-image-div">
                          <img
                            class="img-fluid blog-image"
                            alt="100%x280"
                            src={img2}
                          />
                        </Link>
                        <div class="card-body card-body1">
                          <h4 class="card-title"> Signatures</h4>
                          <p class="card-text">
                            Our sizzling brownies are the perfect pick-me-up for
                            any occasion, like a warm hug on a cold day!
                          </p>
                        </div>
                      </div>
                    </Link>
                    <Link to="/Comp/#kkkkk" class="see-more-btn">
                      <BiDrink class="see-more-icon" /> &nbsp;See more
                    </Link>
                  </div>
                  <div class="col-md-3 card-part blog-item">
                    <Link to="/Comp">
                      <div class="card card1">
                        <Link to="/Comp" className="blog-item-image-div">
                          <img
                            class="img-fluid blog-image"
                            alt="100%x280"
                            src={img3}
                          />
                        </Link>
                        <div class="card-body card-body1">
                          <h4 class="card-title">Munchies</h4>
                          <p class="card-text">
                            If we're not meant to have midnight snacks, why is
                            there a light in the fridge?
                          </p>
                        </div>
                      </div>
                    </Link>
                    <Link to="/Comp/#kkkkk" class="see-more-btn">
                      <GiChickenOven class="see-more-icon" /> &nbsp; See more
                    </Link>
                  </div>
                  <div class="col-md-3 card-part blog-item">
                    <Link to="/Comp">
                      <div class="card card1">
                        <Link to="/Comp" className="blog-item-image-div">
                          <img
                            class="img-fluid blog-image"
                            alt="100%x280"
                            src={img4}
                          />
                        </Link>
                        <div class="card-body card-body1">
                          <h4 class="card-title">Gourmet Plates</h4>
                          <p class="card-text">
                            With our gourmet plates, you can enjoy the finest
                            ingredients and culinary expertise!
                          </p>
                        </div>
                      </div>
                    </Link>
                    <Link to="/Comp/#kkkkk" class="see-more-btn">
                      <SiJusteat class="see-more-icon" /> &nbsp; See more
                    </Link>
                  </div>
                </div>
              </div>
              <div class="carousel-item">
                <div class="row">
                  <div class="col-md-3  card-part blog-item">
                    <Link to="/Comp">
                      <div class="card card1">
                        <Link to="/Comp" className="blog-item-image-div">
                          <img
                            class="img-fluid blog-image"
                            alt="100%x280"
                            src={img5}
                          />
                        </Link>
                        <div class="card-body card-body1">
                          <h4 class="card-title">Waffle Cones</h4>
                          <p class="card-text">
                            Life is short, Make every day a cone-ful day with
                            our delecatable treats!
                          </p>
                        </div>
                      </div>
                    </Link>
                    <Link to="/Comp/#kkkkk" class="see-more-btn">
                      <IoMdIceCream class="see-more-icon" /> &nbsp; See more
                    </Link>
                  </div>
                  <div class="col-md-3 card-part blog-item">
                    <Link to="/Comp">
                      <div class="card card1">
                        <Link to="/Comp" className="blog-item-image-div">
                          <img
                            class="img-fluid blog-image "
                            alt="100%x280"
                            src={img6}
                          />
                        </Link>
                        <div class="card-body card-body1">
                          <h4 class="card-title">Momo paradise</h4>
                          <p class="card-text">
                            Missing out on momos? You're missing out most
                            delicious snack enjoyed worldwide.
                          </p>
                        </div>
                      </div>
                    </Link>
                    <Link to="/Comp/#kkkkk" class="see-more-btn">
                      <GiBowlOfRice class="see-more-icon" /> &nbsp;See more
                    </Link>
                  </div>
                  <div class="col-md-3 card-part blog-item">
                    <Link to="/Comp">
                      <div class="card card1">
                        <Link to="/Comp" className="blog-item-image-div">
                          <img
                            class="img-fluid blog-image"
                            alt="100%x280"
                            src={img7}
                          />
                        </Link>
                        <div class="card-body card-body1">
                          <h4 class="card-title">Ice Cream rolls</h4>
                          <p class="card-text">
                            If ice cream is the answer, then who cares what the
                            question is, if it is at Rollover!
                          </p>
                        </div>
                      </div>
                    </Link>
                    <Link to="/Comp/#kkkkk" class="see-more-btn">
                      <GiRolledCloth class="see-more-icon" /> &nbsp; See more
                    </Link>
                  </div>
                  <div class="col-md-3 card-part blog-item">
                    <Link to="/Comp">
                      <div class="card card1">
                        <Link to="/Comp" className="blog-item-image-div">
                          <img
                            class="img-fluid blog-image"
                            alt="100%x280"
                            src={img8}
                          />
                        </Link>
                        <div class="card-body card-body1">
                          <h4 class="card-title">Sandwiches</h4>
                          <p class="card-text">
                            Fresh ingredients, bold flavors, and a whole lot of
                            love. That's what makes our sandwiches so memorable!
                          </p>
                        </div>
                      </div>
                    </Link>
                    <Link to="/Comp/#kkkkk" class="see-more-btn">
                      <GiSandwich class="see-more-icon" /> &nbsp; See more
                    </Link>
                  </div>
                </div>
              </div>
              <div class="carousel-item">
                <div class="row">
                  <div class="col-md-3 card-part blog-item">
                    <Link to="/Comp">
                      <div class="card card1">
                        <Link to="/Comp" className="blog-item-image-div">
                          <img
                            class="img-fluid blog-image"
                            alt="100%x280"
                            src={img9}
                          />
                        </Link>
                        <div class="card-body card-body1">
                          <h4 class="card-title">Shakes</h4>
                          <p class="card-text">
                            In this era of headshakes and handshakes, We prefer
                            milkshakes
                          </p>
                        </div>
                      </div>
                    </Link>
                    <Link to="/Comp/#kkkkk" class="see-more-btn">
                      <CiPillsBottle1 class="see-more-icon" /> &nbsp; See more
                    </Link>
                  </div>
                  <div class="col-md-3 card-part blog-item">
                    <Link to="/Comp">
                      <div class="card card1">
                        <Link to="/Comp" className="blog-item-image-div">
                          <img
                            class="img-fluid blog-image"
                            alt="100%x280"
                            src={img10}
                          />
                        </Link>
                        <div class="card-body card-body1">
                          <h4 class="card-title">Exotic Sundaes</h4>
                          <p class="card-text">
                            Dive into the deliciousness of our exotic sundaes,
                            where every bite is a taste of heaven!
                          </p>
                        </div>
                      </div>
                    </Link>
                    <Link to="/Comp/#kkkkk" class="see-more-btn">
                      <GiChocolateBar class="see-more-icon" /> &nbsp; See more
                    </Link>
                  </div>
                  <div class="col-md-3  card-part blog-item">
                    <Link to="/Comp">
                      <div class="card card1">
                        <Link to="/Comp" className="blog-item-image-div">
                          <img
                            class="img-fluid blog-image"
                            alt="100%x280"
                            src={img5}
                          />
                        </Link>
                        <div class="card-body card-body1">
                          <h4 class="card-title">Waffle Cones</h4>
                          <p class="card-text">
                            Life is short, Make every day a cone-ful day with
                            our delecatable treats!
                          </p>
                        </div>
                      </div>
                    </Link>
                    <Link to="/Comp/#kkkkk" class="see-more-btn">
                      <IoMdIceCream class="see-more-icon" /> &nbsp; See more
                    </Link>
                  </div>
                  <div class="col-md-3 card-part blog-item">
                    <Link to="/Comp">
                      <div class="card card1">
                        <Link to="/Comp" className="blog-item-image-div">
                          <img
                            class="img-fluid blog-image"
                            alt="100%x280"
                            src={img3}
                          />
                        </Link>
                        <div class="card-body card-body1">
                          <h4 class="card-title">Munchies</h4>
                          <p class="card-text">
                            If we're not meant to have midnight snacks, why is
                            there a light in the fridge?
                          </p>
                        </div>
                      </div>
                    </Link>
                    <Link to="/Comp/#kkkkk" class="see-more-btn">
                      <GiChickenOven class="see-more-icon" /> &nbsp; See more
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Products;
