import React from "react";
import "./Vision.css";
import { VisionData } from "./VisionData";

function Vision() {
  return (
    <div className="vision-part">
      <h1 className="prepare-main-title mb-[30px] text-center">The Perks</h1>
      <div>
        <ul className="vision-items">
          {VisionData.map((vision) => (
            <li key={vision.id} className="vision-item">
              <img src={vision.image} alt="" className="vision-image" />
              <div className="vision-content">
                <h3 className="vision-heading  text-[#ed3592] text-[22px]">
                  {vision.benefit_type}
                </h3>
                <p className="vision-paragraph">{vision.benefit_description}</p>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default Vision;
