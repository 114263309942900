import React from "react";
import "./Ambassador.css";

import vid7 from "./Media/seven.mp4";
import vid8 from "./Media/eight.mp4";
import vid9 from "./Media/nine.mp4";
import share1 from "./Media/share1.jpg";
import share2 from "./Media/share2.jpg";

const videos = [
  {
    id: 1,
    video: vid7,
    margin: "30px",
    height: "50%", // Add the height property here
  },
  {
    id: 2,
    video: vid8,
    margin: "-20px",
    height: "50%", // Add the height property here
  },
  {
    id: 3,
    video: vid9,
    margin: "50px",
    height: "50%", // Add the height property here
  },
];

const images = [
  {
    id: 1,
    image: share1,
    margin: "-80px",
  },
  {
    id: 2,
    image: share2,
    margin: "-120px",
  },
];

function AShare() {
  return (
    <div className="ambassador-part">
      <h1 className="prepare-main-title min-[7678px]:mt-[80px]">
        Share your Love for Authentic Rollover Food & Get benefits.
      </h1>
      <p className="card-text">100+ community of Rollover Food Lovers</p>
      <div className="share-videos">
        {videos.map((item) => (
          <div
            key={item.id}
            className={`a-share-video`}
            style={{ marginTop: item.margin, height: item.height }}
          >
            <div className="video-div">
              <video loop muted autoPlay className="post-video">
                <source src={item.video} type="video/mp4" />
              </video>
            </div>
          </div>
        ))}
      </div>
      <div className="share-images">
        {images.map((item) => (
          <div
            key={item.id}
            className={`a-share-image`}
            style={{ marginTop: item.margin, height: item.height }}
          >
            <img src={item.image} alt="" />
          </div>
        ))}
      </div>
    </div>
  );
}

export default AShare;
