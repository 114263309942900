import React, { useState } from "react";
import "./Events.css";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { LightgalleryProvider, LightgalleryItem } from "react-lightgallery";
import img1 from "../Lightbox/media/img3.jpg";
import {
  EventsData,
  EventsTitles,
  InstoreEventsData,
  InstoreEventsTitles,
} from "./EventsData";

const PhotoItem = ({ image, thumb, group }) => (
  <div className="light-img image-123">
    <LightgalleryItem group={group} src={image} thumb={thumb}>
      <img src={image} style={{ width: "100%" }} className="image-12" />
    </LightgalleryItem>
  </div>
);

function Instore() {
  const [visible, setVisible] = useState(true);
  return (
    <div className="outdoor-part">
      <br />
      <div>
        <h1 class="store-heading">Instore events @Rollover</h1>
        <h1 className="store-content">
          We offer in-store events with ice cream tastings and special
          promotions. Our staff can assist with planning and execution.
          Customized options are available to fit your needs and budget. We
          provide catering for parties and gatherings, featuring exclusive
          flavors and toppings. We accommodate groups of any size and recommend
          booking in advance.
        </h1>
        <Tabs>
          <TabList>
            {InstoreEventsTitles.map((product, index) => {
              return (
                <Tab>
                  <p>{product.namer}</p>
                </Tab>
              );
            })}
          </TabList>
          {InstoreEventsData.map((product, index) => {
            return (
              <TabPanel>
                <div className="panel-content">
                  <h1 className="event-heading">{product.namer}</h1>
                  <div className="content contentfulll flex ">
                    {visible ? (
                      <LightgalleryProvider className="content">
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                          className="content"
                        >
                          <PhotoItem
                            image={product.image}
                            group="group2"
                            className="enent-image "
                          />
                        </div>
                      </LightgalleryProvider>
                    ) : null}
                    <h2 className="event-content">
                      {" "}
                      {product.desc1} <br /> <br />
                      {product.desc2}
                    </h2>
                    <h2 className="event-content"> </h2>
                  </div>
                  <h2 className="event-content">{product.desc3}</h2>
                </div>
              </TabPanel>
            );
          })}
        </Tabs>
      </div>
    </div>
  );
}

export default Instore;
