import React, { useRef } from "react";
import { Images, MobileImages } from "./images";
import "./index.css";
import Carousel from "./useSlider";
import { useMediaQuery } from "react-responsive";

const Slider = () => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  return (
    <div>
      {isMobile ? (
        <>
          <Carousel>
            {MobileImages.map((product) => {
              return (
                <div>
                  <img
                    src={product.src}
                    alt="img1"
                    className="slide-part-image"
                  />
                </div>
              );
            })}
          </Carousel>
        </>
      ) : (
        <>
          <Carousel>
            {Images.map((product) => {
              return (
                <div>
                  <img
                    src={product.src}
                    alt="img1"
                    className="slide-part-image"
                  />
                </div>
              );
            })}
          </Carousel>
        </>
      )}
    </div>
  );
};

export default Slider;
