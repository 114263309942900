import Fade from "react-reveal";
import React, { useRef, useState } from "react";
import "./Login.css";
import emailjs from "@emailjs/browser";
import Whatsapp from "../Socialmedia/Whatsapp";
import Sidebar from "../Sidebar/Sidebar";
import AccordFAQ from "./AccordFAQ";
import { useForm } from "react-hook-form";
import ErrorText from "../contact/ErrorText";
import ModalMessageSend from "../contact/modalMessageSend/ModalMessageSend";
import Navbar from "../Navbar";

export const ContactUs = () => {
  const [sendMessage, setSendMessage] = useState(false);
  setTimeout(setSendMessage, 3000);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const myAlert = () => {
    alert(
      "Booking recieved and Our team will contact you soon..! Thank you..!"
    );
  };

  const sendEmail = (formData) => {
    emailjs
      .send(
        "service_zleknzbbn",
        "template_7xxueuh",
        formData,
        "JiNk4fjq66o6LTiKN"
      )
      .then(
        (result) => {},
        (error) => {}
      );
    reset();
    setSendMessage(true);
    alert(
      "Booking recieved and Our team will contact you soon..! Thank you..!"
    );
  };

  return (
    <div>
      <Navbar />
      <Sidebar />
      <div className="gapp"></div>
      <div class="container" id="cont">
        <div class="row">
          <div class="booking-form">
            <div class="form-header">
              <h3 className="headings">
                Host your Party at Roll Over - Amaze your Guests!
              </h3>
            </div>
            <form onSubmit={handleSubmit(sendEmail)}>
              <div class="row">
                <div className="col-md-6">
                  <label data-aos="fade-up">
                    {" "}
                    <span className="label-head"> Full Name</span>
                    <br />
                    <input
                      className="login-input1"
                      placeholder="Your Full Name"
                      {...register("name", {
                        required: {
                          value: true,
                          message: `${"This field is required."}`,
                        },
                      })}
                    />
                    <div>
                      {errors?.name && <ErrorText text={errors.name.message} />}
                    </div>
                  </label>
                </div>
                <div className="col-md-6">
                  <label data-aos="fade-up">
                    {" "}
                    <span className="label-head">Phone Number</span>
                    <br />
                    <input
                      className="login-input1"
                      placeholder="Your Phone Number"
                      {...register("number", {
                        required: {
                          value: true,
                          message: `${"This field is required."}`,
                        },
                      })}
                    />
                    <div>
                      {errors?.number && (
                        <ErrorText text={errors.number.message} />
                      )}
                    </div>
                  </label>
                </div>
                <div className="col-md-6">
                  <label data-aos="fade-up" data-aos-delay="150">
                    <span className="label-head">E-mail</span>
                    <br />
                    <input
                      className="login-input1"
                      placeholder="Your E-mail"
                      {...register("email", {
                        required: {
                          value: true,
                          message: "This field is required.",
                        },
                        pattern: {
                          value:
                            /^(|(([A-Za-z0-9]+_+)|([A-Za-z0-9]+\-+)|([A-Za-z0-9]+\.+)|([A-Za-z0-9]+\++))*[A-Za-z0-9]+@((\w+\-+)|(\w+\.))*\w{1,63}\.[a-zA-Z]{2,6})$/i,
                          message: "Invalid E-mail",
                        },
                      })}
                    />
                    <div>
                      {errors?.email && (
                        <ErrorText text={errors.email.message} />
                      )}
                    </div>
                  </label>
                </div>
                <div className="col-md-6">
                  <label data-aos="fade-up">
                    <span className="label-head">Address</span>
                    <br />
                    <input
                      className="login-input1"
                      placeholder="Your Address"
                      {...register("location", {
                        required: {
                          value: true,
                          message: `${"This field is required."}`,
                        },
                      })}
                    />
                    <div>
                      {errors?.location && (
                        <ErrorText text={errors.location.message} />
                      )}
                    </div>
                  </label>
                </div>
                <div className="col-md-6">
                  <label data-aos="fade-up">
                    <span className="label-head">Event Date</span>
                    <br />
                    <input
                      class="input-transparent-bg login-input1"
                      placeholder="ss"
                      type="date"
                      {...register("date", {
                        required: {
                          value: true,
                          message: `${"This field is required."}`,
                        },
                      })}
                    />
                    <div>
                      {errors?.date && <ErrorText text={errors.date.message} />}
                    </div>
                  </label>
                </div>

                <div className="col-md-6">
                  <label data-aos="fade-up">
                    <span className="label-head">Event Time</span>
                    <br />
                    <input
                      class="input-transparent-bg login-input1"
                      type="time"
                      placeholder="date"
                      {...register("time", {
                        required: {
                          value: true,
                          message: `${"This field is required."}`,
                        },
                      })}
                    />
                    <div>
                      {errors?.time && <ErrorText text={errors.time.message} />}
                    </div>
                  </label>
                </div>
                <div className="col-md-6">
                  <label data-aos="fade-up">
                    <span className="label-head">How did you find us</span>
                    <br />
                    <select
                      name="why"
                      {...register("why", {
                        required: {
                          value: true,
                          message: `${"This field is required."}`,
                        },
                      })}
                    >
                      <option value="" selected hidden></option>
                      <option>Instagram</option>
                      <option>Facebook</option>
                      <option>Bill desk</option>
                      <option>Youtube</option>
                      <option>Others</option>
                    </select>
                    <div>
                      {errors?.why && <ErrorText text={errors.why.message} />}
                    </div>
                  </label>
                </div>
                <div className="col-md-6">
                  <label data-aos="fade-up">
                    <span className="label-head">Event Mode</span>
                    <br />
                    <select
                      name="mode"
                      {...register("mode", {
                        required: {
                          value: true,
                          message: `${"This field is required."}`,
                        },
                      })}
                    >
                      <option value="" selected hidden></option>
                      <option>In-store Event</option>
                      <option>Outdoor Event</option>
                    </select>
                    <div>
                      {errors?.mode && <ErrorText text={errors.mode.message} />}
                    </div>
                  </label>
                </div>
                <div className="col-md-6">
                  <label data-aos="fade-up">
                    <span className="label-head">Event Type</span>
                    <br />
                    <select
                      name="type"
                      {...register("type", {
                        required: {
                          value: true,
                          message: `${"This field is required."}`,
                        },
                      })}
                    >
                      <option value="" selected hidden></option>
                      <option>Birthday Event</option>
                      <option>Get together Event</option>
                      <option>Marriage Event</option>
                      <option>Anniversary Event</option>
                      <option> kitty party</option>
                      <option>Others</option>
                    </select>
                    <div>
                      {errors?.type && <ErrorText text={errors.type.message} />}
                    </div>
                  </label>
                </div>
                <div className="col-md-6">
                  <label data-aos="fade-up">
                    <span className="label-head">Guest Count</span>
                    <br />
                    <input
                      class="input-transparent-bg login-input1"
                      name="count"
                      type="number"
                      placeholder="Guest Count"
                      {...register("count", {
                        required: {
                          value: true,
                          message: `${"This field is required."}`,
                        },
                      })}
                    />
                    <div>
                      {errors?.count && (
                        <ErrorText text={errors.count.message} />
                      )}
                    </div>
                  </label>
                </div>
                <div className="col-md-12">
                  <label
                    data-aos="fade-up"
                    data-aos-delay="300"
                    className="label-width"
                  >
                    <span className="label-head">Your Message</span>
                    <br />
                    <textarea
                      class="textarea-width login-input1"
                      placeholder="Your Message"
                      {...register("message", {
                        required: {
                          value: true,
                          message: "This field is required.",
                        },
                        minLength: {
                          value: 10,
                          message: "No less than 10 characters.",
                        },
                      })}
                    />
                    <div>
                      {errors?.message && (
                        <ErrorText text={errors.message.message} />
                      )}
                    </div>
                  </label>
                </div>
              </div>
              <input
                type="submit"
                value="Book Event"
                className="btn btn-primary event-submit font-['Open_sans']"
                data-aos="fade-up"
                data-aos-delay="450"
              />
              {sendMessage && <myAlert />}
            </form>
          </div>
        </div>
      </div>

      <Whatsapp />
    </div>
  );
};
