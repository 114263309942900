import React from "react";

import rock1 from "./Media/rock1.png";
import rock2 from "./Media/rock2.png";
import rock3 from "./Media/rock3.png";

const content = [
  {
    id: 1,
    video: rock1,
    status: "Cherita",
    eligibility:
      "I am Cherita, I have been in this program from few months and earning the side income along with my job. With Quicklly brand ambassador program, you'll earn money every time you share the products reviews and provide exclusive discounts to your friends, family, and social media followers.",
  },
  {
    id: 2,
    video: rock2,
    status: "Malini",
    eligibility:
      "Hello, I'm Malini. My experience being a Quicklly Brand Ambassador has not only earned me great cash rewards but satisfied my cravings for delicious Indian food. Are you missing the home-cooked taste of authentic Indian food in the US? Become a Quicklly Brand Ambassador and earn money.",
  },
  {
    id: 3,
    video: rock3,
    status: "Sucheta Singh",
    eligibility:
      "Hello, I am Sucheta. I have been a part of the Quicklly Brand Ambassador Program for the past few months, and I love how I can turn my passion for Indian cuisine into a paying side hustle!. You can earn money by sharing product reviews and exclusive discounts. Indulge in authentic flavors.",
  },
];

function HearIt() {
  return (
    <div className="ambassador-part">
      <h1 className="prepare-main-title mb-[20px]">
        Hear it from our Rockstars
      </h1>

      <div className="post-images mb-[30px]">
        {content.map((item, index) => (
          <div
            key={item.id}
            className={`a-share-image`}
            style={{
              marginTop: item.margin,
              height: item.height,
            }}
          >
            <img src={item.video} alt="" className="h-[50%] w-full" />

            <div className="post-content-part font-['Open_Sans'] pb-[20px] bg-[#fde6e7] p-[10px] mb-[50px]">
              <h1 className="text-[#ed3592] text-[22px] py-[20px] px-[5px]">
                {item.status}
              </h1>
              <p className="text-[13px]">{item.eligibility}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default HearIt;
