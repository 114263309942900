/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import "./Comp.css";
import img1 from "./media/zomato.png";
import img2 from "./media/swiggy.png";
import { Link } from "react-router-dom";

function Order() {
  return (
    <div className="orders mt-4">
      <h2 className="headingit">LET US ORDER FOOD..</h2>
      <div className="ordert">
        <a
          href="https://www.zomato.com/warangal/roll-over-hanamakonda/order"
          target="_blank"
        >
          <img src={img1} className="imgggg" />
        </a>
        <a
          href="https://www.swiggy.com/restaurants/roll-over-opp-tara-gardens-prashanthnagar-colony-warangal-75960?sld=false"
          target="_blank"
        >
          <img src={img2} className="imgggg" />
        </a>
      </div>
    </div>
  );
}

export default Order;
