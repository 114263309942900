import React from "react";
import "./Sidebar.css";
import fb from "./media/fb.png";
import you from "./media/you.png";
import twt from "./media/twt.png";
import inst from "./media/inst.png";
import comt from "./media/comt.png";
import { Link } from "react-router-dom";

function Sidebar() {
  return (
    <div>
      <div class="sticky-container">
        <ul class="sticky">
          {/* <li>
            <img src={comt} width="32" height="32" />

            <p>
              <Link to="/Feedback">
                Let us know
                <br />

              </Link>
            </p>
          </li> */}
          <li>
            <img src={inst} width="32" height="32" />
            <p>
              <a
                href="https://instagram.com/rolloverwarangal?igshid=YmMyMTA2M2Y="
                target="_blank"
              >
                Follow Us
                <br />
         
              </a>
            </p>
          </li>
          <li>
            <img src={fb} width="32" height="32" />

            <p>
              <a href="https://www.facebook.com/Rollovernitw/" target="_blank">
                Like Us
                <br />

              </a>
            </p>
          </li>
          <li>
            <img src={twt} width="32" height="32" />
            <p>
              <a href="https://twitter.com/BeyondB98599580" target="_blank">
                Follow Us
                <br />

              </a>
            </p>
          </li>
          <li>
            <img src={you} width="32" height="32" />
            <p>
              <a
                href="https://youtube.com/channel/UChySNfbzG3o5qI6tdlSasZA"
                target="_blank"
              >
                Subscribe us
                <br />

              </a>
            </p>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Sidebar;
