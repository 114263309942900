import styled from "styled-components";

export const ProductsContainer = styled.div`
  background-color: #f8f7f5;
`;

export const ProductWrapper = styled.div`
  flex-wrap: wrap;
  align-items: left;
`;

export const ProductCard = styled.div`
  flex-wrap: nowrap;
  flex-direction: row;
`;

export const ProductImg = styled.img`
  height: 150px;
  width: 150px;
  box-shadow: -4px -4px #fdc500;
  border-radius: 1.5rem;
  margin-top: 2rem;
`;

export const ProductsHeading = styled.h1`
 font-size: clamp(2.1rem, 0.5vw, 3rem);
 font-family:georgia;
  text-align:center;
  font-weight: bold;
`;

export const ProductTitle = styled.h2`
  font-weight: 200;
  font-size: 0.7rem;
  float: right;
`;

export const ProductInfo = styled.div`
  padding: 1rem;
  float: right;
  margin-top: 2rem;
`;

export const ProductPrice = styled.p`
  font-size: 0.7rem;
`;

export const ProductButton = styled.button`
  font-size: 1rem;
  padding: 0rem 1rem;
  margin-right: 16px;
  border: none;
  border-radius: 30rem;
  background: #9f7a80;
  color: #fff;
  transition: 0.2 ease-out;
  margin-bottom: 3rem;

  &:hover {
    background: #ffc500;
    transition: 0.2s ease-out;
    cursor: pointer;
    color: #000;
  }
`;
export const ProductButtonn = styled.button`
  font-size: 1rem;
  padding: 0rem 1rem;
  border: none;
  border-radius: 30rem;
  background: #9f7a80;
  margin-right: 16px;
  color: #fff;
  transition: 0.2 ease-out;
  margin-bottom: 3rem;

  &:hover {
    background: #ffc500;
    transition: 0.2s ease-out;
    cursor: pointer;
    color: #000;
  }
`;
