import React, { createContext, useLayoutEffect, useState } from "react";
import Home from "./Home";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import Blog from "./components/Blog/Blog";
import About from "./components/About/About";
import { ContactUs } from "./components/Login/Login";
import Blogcont from "./components/Blogcont/Blogcont";
import Feedback from "./components/Login/Feedback";
import TabPanel from "./components/Comp/Comp";
import Subscription from "./components/Subscription/Subscription";
import Footerr from "./components/Footerr/Footerr";
import Offers from "./Pages/Offers";
import OurStory from "./Pages/OurStory";
import OutdoorEvents from "./Pages/OutdoorEvents";
import InstoreEvents from "./Pages/InstoreEvents";
import NewArrivals from "./Pages/NewArrivals";
import "./index.css";
import NutritionPage from "./Pages/NutritionPage";
import IngredientsPage from "./Pages/IngredientsPage";
import SpecificProductPage from "./Pages/ProductMain";
import Ambassador from "./Pages/Ambassador";
import ReactGA from "react-ga";

export const LangContext = createContext();

ReactGA.initialize("UA-230074267-1");
ReactGA.pageview(window.location.pathname + window.location.search);

function App() {
  const Wrapper = ({ children }) => {
    const location = useLocation();
    useLayoutEffect(() => {
      document.documentElement.scrollTo(0, 0);
    }, [location.pathname]);
    return children;
  };

  const [langEng, setLangEng] = useState(true);
  return (
    <LangContext.Provider value={{ langEng, setLangEng }}>
      <BrowserRouter>
        <Wrapper>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/Comp" element={<TabPanel />} />
            <Route path="/Events" element={<Blog />} />
            <Route path="/Outdoor" element={<OutdoorEvents />} />
            <Route path="/Instore" element={<InstoreEvents />} />
            <Route path="/About" element={<About />} />
            <Route path="/ContactUs" element={<ContactUs />} />
            <Route path="/image" element={<OurStory />} />
            <Route path="/Blogcont" element={<Blogcont />} />
            <Route path="/NewArrivals" element={<NewArrivals />} />
            <Route path="/offers" element={<Offers />} />
            {/* <Route path="/Resource" element={<Resource />} /> */}
            <Route path="/Feedback" element={<Feedback />} />
            <Route path="/Subscription" element={<Subscription />} />
            <Route path="/Comp/:productId" element={<SpecificProductPage />} />
            <Route path="/Nutrition" element={<NutritionPage />} />
            <Route path="/Ingredients" element={<IngredientsPage />} />
            <Route path="/Ambassador" element={<Ambassador />} />
          </Routes>
          <Footerr />
        </Wrapper>
      </BrowserRouter>
    </LangContext.Provider>
  );
}

export default App;
