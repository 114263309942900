import React from "react";
import Contact from "../contact/Contact";
import Navbar from "../Navbar";
import Sidebar from "../Sidebar/Sidebar";
import img1 from "./1.png";
import "./Subscription.css";

function Subscription() {
  return (
    <div>
      <Navbar />
      <Sidebar />
      <br />
      <br />
      <div className="container subscribe-parttt">
        <div className="row ">
          <div className="col-md-6">
            <div>
              <img className="sub-image" src={img1} alt="" />
            </div>
          </div>

          <div className="col-md-6 flex flex-column justify-center">
            <div className="contact-formm">
              <Contact />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Subscription;
