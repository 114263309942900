import React from "react";
import Instore from "../components/Events/Instore";
import Navbar from "../components/Navbar";

function InstoreEvents() {
  return (
    <div>
      <Navbar />
      <Instore />
    </div>
  );
}

export default InstoreEvents;
