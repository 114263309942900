import React from "react";
import { Tabs, Tab } from "react-tabs-scrollable";
import "react-tabs-scrollable/dist/rts.css";
import "./MainMenu.css";
import Products from "../Menuall";
import { copyCatData, foodCat } from "./MainMenuData";

export default function MainMenu() {
  const [activeTab, setActiveTab] = React.useState(0);

  // define an onClick function to bind the value on tab click
  const onTabClick = (e, index) => {
    setActiveTab(index);
  };
  const TabScreen = ({ activeTab, idx, namer, datavar, ...props }) => {
    return (
      <div
        className="animate__animated animate__fadeInLeft"
        role="tabpanel"
        {...props}
      >
        {activeTab === idx && (
          <div className="mx-4">
            {" "}
            <Products data={datavar} />
          </div>
        )}
      </div>
    );
  };
  return (
    <div className="MainMenu-Part">
      <div className="TabsList">
        <Tabs
          activeTab={activeTab}
          onTabClick={onTabClick}
          hideNavBtnsOnMobile={false}
        >
          {foodCat.map((food) => (
            <Tab key={food.name} className="menu-container">
              <div className="menu-icon">{food.icon}</div>
              <div className="menu-name">{food.name}</div>
            </Tab>
          ))}
        </Tabs>
      </div>
      {copyCatData.map((item) => (
        <TabScreen
          activeTab={activeTab}
          idx={item.id}
          key={item.id}
          namer={item.namer}
          datavar={item.data}
        >
          Page {item}
        </TabScreen>
      ))}
    </div>
  );
}
