import "./Comp.css";
import React from "react";

import Order from "./Order";
import Whatsapp from "../Socialmedia/Whatsapp";
import MainMenu from "../MainMenu/MainMenu";
import Navbar from "../Navbar";

function TabPanel() {
  return (
    <div>
      <Navbar />
      <div className="backbox">
        <div className="frezee">
          <Order />
        </div>

        <div className="scrollerr">
          <MainMenu />
          <Whatsapp />
        </div>
      </div>
    </div>
  );
}

export default TabPanel;
