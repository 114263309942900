import React from "react";

import post1 from "./Media/post.mp4";
import post2 from "./Media/post2.mp4";
import post3 from "./Media/post3.mp4";
import post4 from "./Media/post4.mp4";

import gold from "./Media/gold.png";
import Platinum from "./Media/platinum.png";
import diamond from "./Media/diamond.png";

import { FaFacebookF, FaInstagram, FaTwitter, FaYoutube } from "react-icons/fa";

const content = [
  {
    id: 1,
    video: post1,
    status: "Gold",
    eligibility: "5-15 orders or $500 in sales",
  },
  {
    id: 2,
    video: post2,
    status: "Platinum",
    eligibility: "Greater 25 or $1,500 in sales",
  },
  {
    id: 3,
    video: post3,
    status: "Diamond",
    eligibility: "Between 15-25 order or $1,000 in sales",
  },
  {
    id: 4,
    video: post4,
    status: "Diamond",
    eligibility: "Between 15-25 order or $1,000 in sales",
  },
  {
    id: 5,
    video: post3,
    status: "Diamond",
    eligibility: "Between 15-25 order or $1,000 in sales",
  },
  {
    id: 6,
    video: post4,
    status: "Diamond",
    eligibility: "Between 15-25 order or $1,000 in sales",
  },
];

function AJoin() {
  return (
    <div className="ambassador-part">
      <h1 className="prepare-main-title mb-[20px]">Join the Community</h1>
      {/* <p className="card-text mb-[30px]">
        Exceptional Content by Quicklly Brand Ambassadors: Top Picks
      </p> */}
      <div className="join-videos">
        {content.map((item, index) => (
          <div
            key={item.id}
            className={`a-share-image`}
            style={{
              marginTop: item.margin,
              height: item.height,
            }}
          >
            <div className="video-div">
              <video loop muted autoPlay className="post-video">
                <source src={item.video} type="video/mp4" />
              </video>
            </div>

            {/* <div
              className="post-content-part font-['Open_Sans']"
              style={{
                boxShadow:
                  index === 1 ? "0px 0px 4px #ed3592" : "0px 0px 4px grey",
              }}
            >
              <h1 className=" text-[#ed3592] text-[22px] py-[20px] px-[5px]">
                <span className=" !inline-block">Share on :</span>
                <span className="flex !gap-[5px] justify-center mt-[20px]">
                  <a
                    href="https://instagram.com/rolloverwarangal?igshid=YmMyMTA2M2Y="
                    className="post-icon mr-[10px]"
                  >
                    <FaInstagram />
                  </a>

                  <a
                    href="https://youtube.com/channel/UChySNfbzG3o5qI6tdlSasZA"
                    className="post-icon  mr-[10px]"
                  >
                    <FaYoutube />
                  </a>

                  <a
                    href="https://twitter.com/BeyondB98599580"
                    className="post-icon  mr-[10px]"
                  >
                    <FaTwitter />
                  </a>
                  <a
                    href="https://twitter.com/BeyondB98599580"
                    className="post-icon  mr-[10px]"
                  >
                    <FaFacebookF />
                  </a>
                </span>
              </h1>
            </div> */}
          </div>
        ))}
      </div>
    </div>
  );
}

export default AJoin;
