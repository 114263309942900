import React, { useEffect, useState } from "react";
import WheelComponent from "react-wheel-of-prizes";
import Confetti from "react-confetti";
import "./SpinningWheel.css";
import "reactjs-popup/dist/index.css";
import ReactDOM from "react-dom";
import congrats from "./congrats2.jpg";
import next from "./next.png";
import { Link } from "react-router-dom";
import discount from "./Media/DiscountNew.gif";
import fries from "./fries1.gif";
import cone from "./Scoop.gif";
import drink from "./drink1.gif";

function SpinningWheel() {
  const segments = [
    {
      id: 1,
      text: "🎁 5% Discount",
      displayText: "You have won 5% discount*",
      condition: "*Min. order: Rs 399/-",
      noteText: "Please take a screenshot and show it at the counter.",
      color: "#F44336", // Red
      hasDiscount: true,
      spinAgain: false,
      image: discount,
    },
    {
      id: 2,
      text: "🍹 Free Mocktail",
      displayText: "You have won a free mocktail*",
      condition: "*Min. order: Rs 349/-",
      noteText: "Please take a screenshot and show it at the counter.",
      color: "#FFC107", // Yellow
      hasDiscount: true,
      spinAgain: false,
      image: drink,
    },
    {
      id: 3,
      text: "🎲 Better luck next time",
      displayText: "Better luck next time",
      color: "#9C27B0", // Purple
      hasDiscount: false,
      spinAgain: false,
    },
    {
      id: 4,
      text: "🍨 Free ice cream scoop",
      displayText: "You have won a scoop of ice cream*",
      condition: "*Min. order: Rs 249/-",
      noteText: "Please take a screenshot and show it at the counter.",
      color: "#4CAF50", // Green
      hasDiscount: true,
      spinAgain: false,
      image: cone,
    },
    {
      id: 5,
      text: "🍟 Fee fries - small",
      displayText: "You have won small fries*",
      condition: "*Min. order: Rs 399/-",
      noteText: "Please take a screenshot and show it at the counter.",
      color: "#FF9800", // Orange
      hasDiscount: true,
      spinAgain: false,
      image: fries,
    },
    {
      id: 6,
      text: "🎁 10% discount",
      displayText: "You have won 10% discount*",
      condition: "*Min. order: Rs 749/-",
      noteText: "Please take a screenshot and show it at the counter.",
      color: "#E91E63", // Pink
      hasDiscount: true,
      spinAgain: false,
      image: discount,
    },
    {
      id: 7,
      text: "🎉 100 Rs cashback",
      displayText: "You have won 100 Rs cashback*",
      condition: "*Min. order: Rs 999/-",
      noteText: "Please take a screenshot and show it at the counter.",
      color: "#2196F3", // Blue
      hasDiscount: true,
      spinAgain: false,
    },
    {
      id: 8,
      text: "🎯 Spin Again",
      displayText: "Spin the wheel again & Check your luck",
      color: "#607D8B", // Gray
      hasDiscount: false,
      spinAgain: true,
    },
  ];

  const [winner, setWinner] = useState(null);
  const [showConfetti, setShowConfetti] = useState(false);
  const [winnerData, setWinnerData] = useState(null);
  const [showWheel, setShowWheel] = useState(true); // New state for showing/hiding the wheel

  const formatDate = (timestamp) => {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    };
    return new Date(timestamp).toLocaleString("en-US", options);
  };

  useEffect(() => {
    const storedWinnerData = localStorage.getItem("winnerData");
    const parsedWinnerData = JSON.parse(storedWinnerData);

    if (parsedWinnerData && !isWinnerDataExpired(parsedWinnerData)) {
      setWinnerData(parsedWinnerData);
      setShowWheel(false); // Hide the wheel if winner data is available
    }
  }, []);

  const isWinnerDataExpired = (data) => {
    const TWO_HOURS = 2 * 60 * 60 * 1000; // 2 hours in milliseconds
    const currentTime = new Date().getTime();
    const winnerDataTime = new Date(data.timestamp).getTime();
    return currentTime - winnerDataTime >= TWO_HOURS;
  };

  const onFinished = (winner) => {
    if (winner.spinAgain) {
      return null;
    } else {
      setWinner(winner);
      setShowConfetti(winner.hasDiscount);
      const winnerData = {
        ...winner,
        timestamp: new Date().toISOString(),
      };
      localStorage.setItem("winnerData", JSON.stringify(winnerData));
      setWinnerData(winnerData);
      setShowWheel(false); // Hide the wheel after a winner is selected
      setTimeout(() => {
        setShowWheel(true); // Show the wheel again after 2 hours
      }, 2 * 60 * 60 * 1000); // 2 hours in milliseconds
    }
  };

  return (
    <div className="spinning-wheel-container">
      {winnerData ? (
        <div className="winner-element">
          {winnerData.hasDiscount ? (
            <div>
              <div>
                {/* <img src={congrats} alt="" className="!w-full congrats-img" /> */}
                <lottie-player
                  src="https://lottie.host/8ee1a66d-eff5-444f-a27b-deb38518fe03/CH7B1CCW1g.json"
                  background="transparent"
                  speed="1"
                  loop
                  autoplay
                  className="lotter !z-[-1]"
                ></lottie-player>

                <div className="spinner-content-div">
                  <img
                    src={winnerData.image}
                    alt=""
                    className={`w-[150px] h-[150px] m-auto scale-${winnerData.scale}`}
                  />

                  <h2 className="spinner-text">{winnerData.displayText}</h2>
                  <p className="!text-[18px] font-bold">
                    {winnerData.condition}
                  </p>
                  <p>
                    <span className="font-bold">Note:</span>{" "}
                    {winnerData.noteText}
                  </p>
                  <p className="text-[grey]">
                    {formatDate(winnerData.timestamp)}
                  </p>
                </div>
              </div>
            </div>
          ) : (
            <div>
              <img src={next} alt="" className="!w-full rounded-[8px]" />
              <div className="spinner-content-div">
                <h1 className="font-['Open_Sans']">No worries...</h1>
                <h2 className="spinner-text">Explore our</h2>
                <h1 className="offers-btn">
                  <Link to="/Offers" className="offers-btn-link">
                    NEW OFFERS
                  </Link>
                </h1>
              </div>
            </div>
          )}
        </div>
      ) : (
        showWheel && (
          <div>
            <WheelComponent
              segments={segments.map((segment) => segment.text)}
              segColors={segments.map((segment) => segment.color)}
              onFinished={(winner) =>
                onFinished(segments.find((segment) => segment.text === winner))
              }
              primaryColor="black"
              buttonText="Spin"
              isOnlyOnce={false}
              upDuration={50}
              downDuration={600}
              fontFamily="Open Sans"
              className="wheel-of-pain"
            />
            <h1 className="spin-text">Spin & Win</h1>
          </div>
        )
      )}

      {showConfetti &&
        ReactDOM.createPortal(
          <Confetti
            width={window.innerWidth * 0.95}
            height={window.innerHeight}
            className="!z-[9999]"
          />,
          document.body
        )}
    </div>
  );
}

export default SpinningWheel;
