import React, { useRef, useState } from "react";
import "./contact.css";
import emailjs from "emailjs-com";
import { useContext } from "react";
import { LangContext } from "../../App";

import { useForm } from "react-hook-form";
import ErrorText from "./ErrorText";
import ModalMessageSend from "./modalMessageSend/ModalMessageSend";

const Contact = () => {
  const [sendMessage, setSendMessage] = useState(false);

  const [inputType, setInputType] = useState("text");

  const handleFocus = () => {
    setInputType("date");
  };

  const handleBlur = () => {
    setInputType("text");
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const formRef = useRef(null);
  const scriptUrl =
    "https://script.google.com/macros/s/AKfycbx3MQeRqUtOnIsuS6V-0tQSIO8nNzU6h9hNPf3ZC2vQ57M_KoInxJlIXIvWazfYjFQI/exec";
  const [loading, setLoading] = useState(false);

  const handleSubmitSheet = (e) => {
    e.preventDefault();
    setLoading(true);

    fetch(scriptUrl, {
      method: "POST",
      body: new FormData(formRef.current),
    })
      .then((res) => {
        console.log("SUCCESSFULLY SUBMITTED TO SHEET");
        setSendMessage(true);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  };

  const sendEmail = (formData) => {
    emailjs
      .sendForm(
        "service_zleknzbbn",
        "template_962ouwi",
        formRef.current,
        "JiNk4fjq66o6LTiKN"
      )
      .then(
        (result) => {
          console.log("SUCCESSFULLY SENT EMAIL");
          reset();
          setSendMessage(true);
        },
        (error) => {
          console.log(error);
        }
      );
  };

  const onSubmit = (data) => {
    handleSubmitSheet(data); // Execute the sheet submission
    sendEmail(data); // Execute the email sending
  };

  return (
    <section id="contact">
      <div className="container contact__container">
        <h1 className="title text-[#eb3592]">Subscribe Now</h1>
        <br />
        <form onSubmit={onSubmit} ref={formRef}>
          <label data-aos="fade-up">
            <input
              placeholder="Full Name*"
              {...register("name", {
                required: {
                  value: true,
                  message: `${"The field is required."}`,
                },
              })}
            />
            <div>
              {errors?.name && <ErrorText text={errors.name.message} />}
            </div>
          </label>
          <label data-aos="fade-up">
            <input
              placeholder="Phone Number*"
              {...register("subject", {
                required: {
                  value: true,
                  message: `${"The field is required."}`,
                },
              })}
            />
            <div>
              {errors?.subject && <ErrorText text={errors.subject.message} />}
            </div>
          </label>
          <label data-aos="fade-up" data-aos-delay="150">
            <input
              placeholder="E-mail*"
              {...register("email", {
                required: {
                  value: true,
                  message: "The field is required.",
                },
                pattern: {
                  value:
                    /^(|(([A-Za-z0-9]+_+)|([A-Za-z0-9]+\-+)|([A-Za-z0-9]+\.+)|([A-Za-z0-9]+\++))*[A-Za-z0-9]+@((\w+\-+)|(\w+\.))*\w{1,63}\.[a-zA-Z]{2,6})$/i,
                  message: "Invalid E-mail",
                },
              })}
            />
            <div>
              {errors?.email && <ErrorText text={errors.email.message} />}
            </div>
          </label>
          <label data-aos="fade-up" className="dob-label">
            <input
              type={inputType}
              placeholder="Date of Birth*"
              {...register("date", {
                required: {
                  value: true,
                  message: `${"The field is required."}`,
                },
              })}
              className="bg-transparent"
              onFocus={handleFocus}
              onBlur={handleBlur}
            />

            <div>
              {errors?.subject && <ErrorText text={errors.subject.message} />}
            </div>
          </label>

          <input
            type="submit"
            value={loading ? "SUBMITTING..." : "SUBMIT"}
            className="btn btn-primary"
            data-aos="fade-up"
            data-aos-delay="450"
          />
          {sendMessage && <ModalMessageSend />}
        </form>
      </div>
    </section>
  );
};

export default Contact;
