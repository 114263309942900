import vision from "./Media/SignUp.svg";
import mission from "./Media/Grab.svg";
import values from "./Media/Cash.svg";

export const VisionData = [
  {
    id: 1,
    image: vision,
    benefit_type: "Sign up",
    benefit_description:
      "Quickly sign up on Quicklly! It is as easy as it sounds",
  },
  {
    id: 2,
    image: mission,
    benefit_type: "Grab & Post",
    benefit_description:
      "Simply just grab visuals from dashboard and upload on your.",
  },
  {
    id: 3,
    image: values,
    benefit_type: "Cash Commissions",
    benefit_description: "Earn amazing cash rewards everytime someone shops.",
  },
];
