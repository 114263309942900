import React, { useState } from "react";
import PT from "prop-types";
import img1 from "./media/img1.jpeg";
import img2 from "./media/img2.jpg";
import img3 from "./media/img3.jpg";
import img4 from "./media/img4.jpg";
import img5 from "./media/img5.jpg";
import img6 from "./media/img6.jpg";
import img7 from "./media/img7.jpg";
import img8 from "./media/img8.jpg";
import img9 from "./media/img9.jpg";
import img10 from "./media/img10.jpg";
import img11 from "./media/img11.jpg";
import img12 from "./media/img.1.JPEG";
import img13 from "./media/img.2.jpg";
import img14 from "./media/img.3.jpg";
import img15 from "./media/img.4.jpg";
import img16 from "./media/img12.jpg";

import { LightgalleryProvider, LightgalleryItem } from "react-lightgallery";
import "./Lightbox.css";
import "lightgallery.js/dist/css/lightgallery.css";

const GROUP2 = [
  img1,
  img2,
  img3,
  img4,
  img5,
  img6,
  img7,
  img8,
  img16,
  img9,
  img10,
  img11,
  img13,
  img14,
  img15,
];

const PhotoItem = ({ image, thumb, group }) => (
  <div className="light-img">
    <LightgalleryItem group={group} src={image} thumb={thumb}>
      <img src={image} style={{ width: "100%" }} />
    </LightgalleryItem>
  </div>
);
PhotoItem.propTypes = {
  image: PT.string.isRequired,
  thumb: PT.string,
  group: PT.string.isRequired,
};

function Lightbox() {
  const [visible, setVisible] = useState(true);
  return (
    <div>
      <div className="lightbox-cont">
        <center>
          <br />
          <h1 className="titleee">
            Memories are made here - Will last forever.
          </h1>
        </center>
        <div className="content">
          {
            //For visibility
            /* <button
        className="button is-light"
        style={{
          position: "absolute"
        }}
        onClick={() => setVisible(!visible)}
      >
        {visible ? (
          <i className="fas fa-eye-slash" />
        ) : (
          <i className="fas fa-eye" />
        )}
      </button> */
          }

          <div className="content">
            {visible ? (
              <LightgalleryProvider
                // onBeforeOpen={() => console.info("onBeforeOpen")}
                // onAfterOpen={() => console.info("onAfterOpen")}
                // onSlideItemLoad={() => console.info("onSlideItemLoad")}
                // onBeforeSlide={() => console.info("onBeforeSlide")}
                // onAfterSlide={() => console.info("onAfterSlide")}
                // onBeforePrevSlide={() => console.info("onBeforePrevSlide")}
                // onBeforeNextSlide={() => console.info("onBeforeNextSlide")}
                // onDragstart={() => console.info("onDragstart")}
                // onDragmove={() => console.info("onDragmove")}
                // onDragend={() => console.info("onDragend")}
                // onSlideClick={() => console.info("onSlideClick")}
                // onBeforeClose={() => console.info("onBeforeClose")}
                // onCloseAfter={() => console.info("onCloseAfter")}
                className="content"
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  className="content"
                >
                  {GROUP2.map((p, idx) => (
                    <div>
                      <PhotoItem key={idx} image={p} group="group2" />
                    </div>
                  ))}
                </div>
              </LightgalleryProvider>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}
export default Lightbox;
