import React from "react";
import Animate from "../Blog/Animate/Animate";
import "./About.css";
import vid1 from "../Blog/Blogmedia/background.mp4";
import Whatsapp from "../Socialmedia/Whatsapp";
import Navbar from "../Navbar";

function About() {
  return (
    <div>
      <Navbar />
      <div className="forbgclr">
        <Animate />
        <div className="kungfu">
          <h2 className="kungfu">ICE CREAM IS ALWAYS AN OPTION..</h2>
          <br />
          <video width="60%" height="60%" loop muted autoPlay>
            <source src={vid1} type="video/mp4" />
          </video>
        </div>
        <Whatsapp />
      </div>
    </div>
  );
}

export default About;
