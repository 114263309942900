import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

const faqData = [
  {
    panel: "panel1",
    title: "What is Rollover Brand Ambassador?",
    content:
      "So to speak, these incredible people help us celebrate the rich, smoky Indian flavors, expressing their love for the brand while scoring ultimate swag and great rewards along the way! Rollover Ambassadors are creatively active in their communities and motivated to infuse harmony, spreading good vibes among all.",
  },
  {
    panel: "panel2",
    title: "What do you get when you become a Brand Ambassador?",
    content:
      "Once you become an Ambassador, you'll be decked with great benefits, plus an exclusive referral code that your family, friends, and followers can use to shop at www.rolloverwarangal.com to get 10% off. Also, you shall get a 10% commission on each sale you make. Wowza, that's big!.",
  },
  {
    panel: "panel3",
    title: "Who can become a Rollover Brand Ambassador?",
    content:
      "Every U.S. resident holding an active social media account and willing to be Rollover's Brand Ambassador is eligible to make our community a delicious dwelling place! All are invited to join.",
  },
  {
    panel: "panel4",
    title: "Can I refer a friend?",
    content:
      "Currently, we are in the process of organizing a referral program. Stay tuned as we bring you some cool referral rewards for your influencer friends to earn.",
  },
  {
    panel: "panel5",
    title: "When and where do I get my commissions paid?",
    content:
      "The commission amount you have earned will get credited to your Paypal account every two weeks.",
  },
];

function FAQ() {
  const [expanded, setExpanded] = useState("panel1");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div className="ambassador-part pt-[0px]">
      <h1 className="prepare-main-title mb-[40px]">
        Frequently asked questions
      </h1>
      {faqData.map((item) => (
        <Accordion
          key={item.panel}
          expanded={expanded === item.panel}
          onChange={handleChange(item.panel)}
        >
          <AccordionSummary
            aria-controls={`${item.panel}d-content`}
            id={`${item.panel}d-header`}
          >
            <Typography className="!font-['Open_sans']">
              {item.title}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography className="!font-['Open_sans'] !text-left">
              {item.content}
            </Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
}

export default FAQ;
