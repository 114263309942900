import {
  FaPizzaSlice,
  FaFish,
  FaHamburger,
  FaBirthdayCake,
  FaCoffee,
  FaHotdog,
} from "react-icons/fa";
import { MdRestaurantMenu } from "react-icons/md";
import { SiCodechef } from "react-icons/si";
import {
  GiWrappedSweet,
  GiChickenOven,
  GiSandwich,
  GiForkKnifeSpoon,
  GiIceCreamCone,
  GiCupcake,
  GiRiceCooker,
  GiChocolateBar,
} from "react-icons/gi";
import { TbCup, TbBottle } from "react-icons/tb";
import {
  productDataBev,
  productDataburger,
  productDataCones,
  productDatagourmet,
  productDatamomo,
  productDatamunchie,
  productDataRolls,
  productDataSand,
  productDatashake,
  productDataSignatures,
  productDataSundaes,
} from "../Menuall/data";

export const foodCat = [
  // {
  //   id: 0,
  //   name: "Ice Cream Rolls",
  //   icon: <GiChocolateBar />,
  // },
  {
    id: 1,
    name: "Gourmet Plates",
    icon: <MdRestaurantMenu />,
  },
  {
    id: 2,
    name: "Exotic Sundaes ",
    icon: <GiCupcake />,
  },
  {
    id: 3,
    name: "Waffle Cones",
    icon: <GiIceCreamCone />,
  },
  {
    id: 4,
    name: "Shakes",
    icon: <TbCup />,
  },
  {
    id: 5,
    name: "Momos",
    icon: <GiRiceCooker />,
  },
  {
    id: 6,
    name: "Munchies",
    icon: <GiForkKnifeSpoon />,
  },
  {
    id: 7,
    name: "Signatures",
    icon: <SiCodechef />,
  },
  {
    id: 8,
    name: "Burgers",
    icon: <FaHamburger />,
  },
  {
    id: 9,
    name: "Sandwiches",
    icon: <GiSandwich />,
  },
];
export const copyCatData = [
  // {
  //   id: 0,
  //   namer: "Ice Cream Rolls",
  //   data: productDataRolls,
  // },
  {
    id: 0,
    namer: "Gourmet Plates",
    data: productDatagourmet,
  },
  {
    id: 1,
    namer: "Exotic Sundaes",
    data: productDataSundaes,
  },
  {
    id: 2,
    namer: "Waffle Cones",
    data: productDataCones,
  },
  {
    id: 3,
    namer: "Shakes",
    data: productDatashake,
  },
  {
    id: 4,
    namer: "Momos",
    data: productDatamomo,
  },
  {
    id: 5,
    namer: "Munchies",
    data: productDatamunchie,
  },
  {
    id: 6,
    namer: "Signatures",
    data: productDataSignatures,
  },
  {
    id: 7,
    namer: "Burgers",
    data: productDataburger,
  },
  {
    id: 8,
    namer: "Sandwiches",
    data: productDataSand,
  },
];
