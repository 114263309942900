import React, { Component } from "react";
import Slider from "react-slick";
import "./Testimoni.css";
import TestimoniCard from "./TestimoniCard";
import Avatar from "./media/avatar.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SliderNew from "../SliderNew/SliderNew";

export default class Testimoni extends Component {
  render() {
    var settings = {
      dots: true,
      infinite: true,
      speed: 500,
      autoplay: true,
      slidesToShow: 3,
      slidesToScroll: 3,
      arrows: false,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 640,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    return (
      <div className="Testimoni">
        <div className="titletesti">
          <div className="nametesti">
            <h1 className="wierd">What Our Customers Say</h1>
          </div>
        </div>
        <SliderNew />
        {/* <Slider {...settings}>
          <div>
            <TestimoniCard
              testi="This cozy restaurant has left the best impressions! Hospitable hosts, delicious dishes, beautiful presentation, wide wine list and wonderful dessert. "
              name="Sampath Alakanti"
              rating="4.8"
            />
          </div>
          <div>
            <TestimoniCard
              testi="Fresh handcrafted ice cream rolls is to die for. It was Delicious..!! The service we received was so amazing and we will definitely be back again. "
              name="Laxman Kasarla"
              rating="4.3"
            />
          </div>
          <div>
            <TestimoniCard
              testi="From the munchies to Creamy rolls, the quality and tastes of everything was outstanding,  Service was delightful and very professional."
              name="Raja Karmuri"
              rating="4.1"
            />
          </div>
          <div>
            <TestimoniCard
              testi="We are so fortunate to have this place just a few minutes drive away from home. Food is stunning, Ice creams are Delicious and mouthwatering."
              name="Sunil Marapelli"
              rating="4"
            />
          </div>
          <div>
            <TestimoniCard
              testi="Do yourself a favor and visit this lovely rollover restaurant in . The service is unmatched. The staff truly cares about your experience.  "
              name="Anvesh Thammu"
              rating="3.9"
            />
          </div>
        </Slider> */}
      </div>
    );
  }
}
