import React from "react";
import AnimatedCards from "../components/AnimatedCards/AnimatedCards";
import Navbar from "../components/Navbar";

function NewArrivals() {
  return (
    <div>
      <Navbar />
      <AnimatedCards />
    </div>
  );
}

export default NewArrivals;
