import React from "react";
import Ingredients from "../components/Ingredients/Ingredients";
import Navbar from "../components/Navbar";

import Whatsapp from "../components/Socialmedia/Whatsapp";

function IngredientsPage() {
  return (
    <div>
      {" "}
      <Navbar />
      <Ingredients />
      <Whatsapp />
    </div>
  );
}

export default IngredientsPage;
