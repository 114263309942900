import React from "react";
import Navbar from "../components/Navbar";
import Nutrition from "../components/Nutrition/Nutrition";
import Whatsapp from "../components/Socialmedia/Whatsapp";
import IngredientsComponent from "../components/Ingredients/IngredientsComponent";

function NutritionPage() {
  return (
    <div>
      {" "}
      <Navbar />
      <IngredientsComponent />
      <Whatsapp />
    </div>
  );
}

export default NutritionPage;
